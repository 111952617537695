import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "./Acessos.css";
 

class Acessos extends React.Component {
  constructor(props) {
    super(props);

    this.onCheck = this.onCheck.bind(this);
    this.onExpand = this.onExpand.bind(this);
    this.state = this.props.dados;
  }

  onCheck(checked) {
    this.setState({ checked });
  }

  onExpand(expanded) {
    this.setState({ expanded });
  }

  render() {
    const { checked, expanded ,nodes} = this.state;   


    return (
      <>
        <CheckboxTree        
          checked={checked}
          expanded={expanded}
          iconsClass="fa5"
          nodes={nodes}
          onCheck={this.onCheck}
          onExpand={this.onExpand}
        />
        {this.props.parentToChild(checked)}       
        
      </>
    );
  }
}

export default Acessos;
