import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import classNames from "classnames";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";

import { OperadoraService } from "../../../service/OperadoraService";
import { CidadesService } from "../../../service/CidadesService";
import { EstadoService } from "../../../service/EstadoService";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";
import { BancoService } from "../../../service/BancoService";
import { CorreiosService } from "../../../service/CorreiosService";
import { ConvenioService } from "../../../service/ConvenioService";
import { InputNumber } from "primereact/inputnumber";

const _Form = () => {


  const [atualizarCombo, setAtualizarCombo] = useState(false);
  const [idCidade, setIdCidade] = useState(null);
  const [cidades, setCidades] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [orgao, setOrgao] = useState(null);
  const [estabelecimento, setEstabelecimento] = useState(null);
  const cidadesService = new CidadesService();
  const correiosService = new CorreiosService();
  const [estado, setEstado] = useState(null);
  const [correios, setCorreios] = useState(null);
  const [operadora, setOperadora] = useState(null);
  const [convenio, setConvenio] = useState(null);
  const [estadoCivil, setEstadoCivil] = useState(null);
  const [banco, setBanco] = useState(null);
  const [tipoDocPessoa, setTipoDocPessoa] = useState(null);
  const estadoService = new EstadoService();
  const [status_sexo, setStatusSexo] = useState(['M', 'F']);
  const [endereco_tipo, setEnderecoTipo] = useState(['COMERCIAL', 'RESIDENCIAL']);
  const [lista_uf, setListaUf] = useState(['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']);
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);



  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/servidor";
  const link_LancamentosServidor = "/lancamentosIndex/" + id;
  const link_AssinaturasServidor = "/assinaturasIndex/" + id;
  const link_PlanosServidor = "/planosEmprestimoIndex/" + id;
  const link_R = "/resgate_form/" + id;
  const link_E = "/resgateemprestimo_form/" + id;
  const link_resgateCancelarServidor = "/resgate_cancelar_form/" + id;

  let _acesso_R = "adm/servidor/averbarcartao";
  let _acesso_E = "adm/servidor/averbaremprestimo";


  const cabecalho_form = "Administrativo - Servidor";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar </h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar </h5>
    );

  let emptyDados = {
    cav_id: '',
    cav_cpf: '',
    cav_codigo_convenio: '',
    cav_ope_id: '',
    cav_matricula: "",
    cav_nome: "",
    cav_nome_mae: "",
    cav_nome_pai: "",
    cav_sexo: "",
    cav_data_nascimento_: "",
    cav_ecv_id: "",
    cav_nacionalidade: "",
    cav_tdc_id: "",
    cav_rg_numero: "",
    cav_documento_uf: "",
    cav_rg_orgao_emissor: "",
    cav_rg_data_emissao_: "",
    cav_celular: "",
    cav_telefone: "",
    cav_email: "",
    cav_endereco: "",
    cav_bairroendereco: "",
    cav_cep: "",
    cav_cidadeendereco: "",
    cav_complementoendereco: "",
    cav_endereconumero: "",
    cav_estadoendereco: "",
    cav_valor_renda: null,
    cav_endereco_tipo: "RESIDENCIAL",
    cav_convenio: "",
    cav_cancelado: false,
    cav_motivo: "",
    cav_bloqueado: false,
    cav_motivo_bloqueio: "",
    cav_compras_cartao_bloqueio: "",
    cav_compras_cartao_motivo: "",
    cav_compras_historico: "",
    cav_ban_id: "",
    cav_ban_agencia: "",
    cav_ban_conta: "",
    cav_ban_digito: "",
    cav_ctg_id: "",
    cav_zee_id: "",
    cav_zeo_id: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [opeId, SetOpeID] = useState(null);
  const [cidId, SetCidId] = useState(null);
  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const cartaoVirtualService = new CartaoVirtualService();
  const operadoraService = new OperadoraService();
  const bancoService = new BancoService();
  const convenioService = new ConvenioService();



  useEffect(() => {

    bancoService
      .getAll()
      .then((data) => setBanco(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });


    cartaoVirtualService
      .getCategoria()
      .then((data) => setCategoria(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });

    cartaoVirtualService
      .getOrgao()
      .then((data) => setOrgao(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });

    cartaoVirtualService
      .getEstabelecimento()
      .then((data) => setEstabelecimento(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });


    cartaoVirtualService
      .getEstadoCivil()
      .then((data) => setEstadoCivil(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });



    cartaoVirtualService
      .getTipoDocPessoa()
      .then((data) => setTipoDocPessoa(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });



    operadoraService
      .getAll()
      .then((data) =>
        setOperadora(data.dados),
        SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });




    estadoService
      .getAll()
      .then((data) => setEstado(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });


    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const cartaoVirtualService = new CartaoVirtualService();
      cartaoVirtualService
        .getById(id)
        .then((data) =>
          setModelDado(data.dados[0]),
          SetLoadingSpinner(false),
        );
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
    formik_outros.setValues(modelDado);
    formik_servidor.setValues(modelDado);
    formik_app.setValues(modelDado);
    SetOpeID(modelDado.cav_ope_id);
    SetCidId(modelDado.cav_estadoendereco);
  }, [modelDado]);

  useEffect(() => {
    buscaConvenios();
  }, [opeId]);

  useEffect(() => {
    buscaCidades();
  }, [cidId]);






  const BuscarCep = () => {
    SetLoadingSpinner(true);
    correiosService
      .getCep(formik.values.cav_cep)
      .then((data) => setCorreios(data.dados),
      );
  };


  useEffect(() => {

    if (correios != null) {
      formik.setFieldValue('cav_endereco', correios.logradouro);
      formik.setFieldValue('cav_bairroendereco', correios.bairro);
      formik.setFieldValue('cav_estadoendereco', parseInt(correios.uf_id, 10));
      const cidId = parseInt(correios.cid_id, 10);
      formik.setFieldValue('cav_cidadeendereco', cidId);
      setIdCidade(cidId);
    } else {
      formik.setFieldValue('cav_endereco', '');
      formik.setFieldValue('cav_bairroendereco', '');
      formik.setFieldValue('cav_estadoendereco', '');
      formik.setFieldValue('cav_cidadeendereco', '');
      SetLoadingSpinner(false);
    }
    SetLoadingSpinner(false);
  }, [correios]);


  useEffect(() => {
    buscaCidades();
    SetLoadingSpinner(false);
  }, [idCidade]);


  function buscaCidades() {
    cidadesService
      .getByUf(formik.values.cav_estadoendereco)
      .then((data) => setCidades(data.dados),
      );
  }

  function buscaConvenios() {
    convenioService
      .getByOperadora(formik.values.cav_ope_id)
      .then((data) =>
        setConvenio(data.dados),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }

  useEffect(() => {
    if (retornoDado.status !== null) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push("/servidor");
        }, 3000);
      }
      SetLoadingSpinner(false)
    }
  }, [retornoDado]);


  const formik_outros = useFormik({
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_compras_cartao_bloqueio: modelDado.cav_compras_cartao_bloqueio,
      cav_compras_cartao_motivo: modelDado.cav_compras_cartao_motivo,
      cav_compras_historico: modelDado.cav_compras_historico,
    },
    validationSchema: yup.object({
      cav_compras_cartao_motivo: yup
        .string()
        .nullable().required("O campo é obrigatório.")
        .max(
          500,
          ({ max }) => `Motivo do bloqueio deve ter no máximo ${max} caracteres`
        )
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            cartaoVirtualService
              .postBloqueiaCartaoDigital(
                values.cav_id,
                values.cav_compras_cartao_bloqueio,
                values.cav_compras_cartao_motivo,
                token
              )
              .then((data) => setRetornoDado(data));
          });
      });
    },
  });


  const formik_app = useFormik({
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_bloqueado: modelDado.cav_bloqueado,
      cav_motivo_bloqueio: modelDado.cav_motivo_bloqueio,
    },
    validationSchema: yup.object({
      cav_motivo_bloqueio: yup
        .string()
        .nullable().required("O campo é obrigatório.")
        .max(
          500,
          ({ max }) => `Motivo do bloqueio deve ter no máximo ${max} caracteres`
        )
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            cartaoVirtualService
              .postBloqueiaApp(
                values.cav_id,
                values.cav_bloqueado,
                values.cav_motivo_bloqueio,
                token
              )
              .then((data) => setRetornoDado(data));
          });
      });
    },
  });


  const formik_servidor = useFormik({
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_cancelado: modelDado.cav_cancelado,
      cav_motivo: modelDado.cav_motivo,
    },
    validationSchema: yup.object({
      cav_motivo_bloqueio: yup
        .string()
        .transform((v) => (v === null ? "" : v))
        .max(
          500,
          ({ max }) => `Motivo do cancelamento deve ter no máximo ${max} caracteres`
        ),

    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            cartaoVirtualService
              .postCancelaServidor(
                values.cav_id,
                values.cav_cancelado,
                values.cav_motivo,
                token
              )
              .then((data) => setRetornoDado(data));
          });
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_codigo_convenio: modelDado.cav_codigo_convenio,
      cav_ope_id: modelDado.cav_ope_id,
      cav_cpf: modelDado.cav_cpf,
      cav_matricula: modelDado.cav_matricula,
      cav_nome: modelDado.cav_nome,
      cav_nome_mae: modelDado.cav_nome_mae,
      cav_nome_pai: modelDado.cav_nome_pai,
      cav_sexo: modelDado.cav_sexo,
      cav_data_nascimento_: modelDado.cav_data_nascimento_,
      cav_ecv_id: modelDado.cav_ecv_id,
      cav_nacionalidade: modelDado.cav_nacionalidade,
      cav_rg_numero: modelDado.cav_rg_numero,
      cav_tdc_id: modelDado.cav_tdc_id,
      cav_documento_uf: modelDado.cav_documento_uf,
      cav_rg_orgao_emissor: modelDado.cav_rg_orgao_emissor,
      cav_rg_data_emissao_: modelDado.cav_rg_data_emissao_,
      cav_celular: modelDado.cav_celular,
      cav_telefone: modelDado.cav_telefone,
      cav_email: modelDado.cav_email,
      cav_endereco: modelDado.cav_endereco,
      cav_bairroendereco: modelDado.cav_bairroendereco,
      cav_cep: modelDado.cav_cep,
      cav_cidadeendereco: modelDado.cav_cidadeendereco,
      cav_complementoendereco: modelDado.cav_complementoendereco,
      cav_endereconumero: modelDado.cav_endereconumero,
      cav_estadoendereco: modelDado.cav_estadoendereco,
      cav_valor_renda: modelDado.cav_valor_renda,
      cav_endereco_tipo: modelDado.cav_endereco_tipo,
      cav_cancelado: modelDado.cav_cancelado,
      cav_motivo: modelDado.cav_motivo,
      cav_bloqueado: modelDado.cav_bloqueado,
      cav_motivo_bloqueio: modelDado.cav_motivo_bloqueio,
      cav_ban_id: modelDado.cav_ban_id,
      cav_ban_agencia: modelDado.cav_ban_agencia,
      cav_ban_conta: modelDado.cav_ban_conta,
      cav_ban_digito: modelDado.cav_ban_digito,
      cav_ctg_id: modelDado.cav_ctg_id,
      cav_zee_id: modelDado.cav_zee_id,
      cav_zeo_id: modelDado.cav_zeo_id,
    },
    validationSchema: yup.object({
      cav_ope_id: yup.number().required("O campo é obrigatório.").nullable(),
      cav_codigo_convenio: yup.string().required("O campo é obrigatório.").nullable(),
      cav_cpf: yup.string().required("O campo é obrigatório.").nullable(),
      cav_matricula: yup.string().required("O campo é obrigatório.").nullable(),
      cav_nome: yup.string().required("O campo é obrigatório.").nullable(),

      cav_nome_mae: yup
        .string()
        .nullable()
        .when(["cav_ope_id"], {
          is: cav_ope_id => cav_ope_id != '1',
          then: yup.string().required("O campo é obrigatório.")
            .nullable(),
        }),


      cav_nome_pai: yup
        .string()
        .nullable()
        .when(["cav_ope_id"], {
          is: cav_ope_id => cav_ope_id != '1',
          then: yup.string().required("O campo é obrigatório.")
            .nullable(),
        }),
      cav_sexo: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_data_nascimento_: yup.string().required("O campo é obrigatório.").nullable(),
      cav_rg_data_emissao_: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_ecv_id: yup
        .string()
        .nullable()
        .when(["cav_ope_id"], {
          is: cav_ope_id => cav_ope_id != '1',
          then: yup.string().required("O campo é obrigatório.")
            .nullable(),
        }),
      cav_rg_numero: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_tdc_id: yup
        .string()
        .nullable()
        .when(["cav_ope_id"], {
          is: cav_ope_id => cav_ope_id != '1',
          then: yup.string().required("O campo é obrigatório.")
            .nullable(),
        }),
      cav_documento_uf: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_rg_orgao_emissor: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_celular: yup.string().required("O campo é obrigatório.").nullable(),
      cav_email: yup.string().required("O campo é obrigatório.").nullable().email('E-mail inválido'),
      cav_endereco: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_bairroendereco: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_cep: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_cidadeendereco: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => cav_ope_id != '1',
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_endereconumero: yup.string().nullable() .when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_estadoendereco: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_valor_renda: yup.number().required("O campo é obrigatório.").nullable(),
      cav_endereco_tipo: yup
        .string()
        .nullable()
        .when(["cav_ope_id"], {
          is: cav_ope_id => (cav_ope_id != '1'),
          then: yup.string().required("O campo é obrigatório.")
            .nullable(),
        }),

      cav_ban_id: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_ban_agencia: yup.number().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.number().required("O campo é obrigatório.")
          .nullable(),
      }),

      cav_ban_conta: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      cav_ban_digito: yup.string().nullable().when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id != '1'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),
      
      cav_ctg_id: yup.string().required("O campo é obrigatório.").nullable(),

      cav_zee_id: yup
      .string()
      .nullable()
      .when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id == '2'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),

      cav_zeo_id: yup
      .string()
      .nullable()
      .when(["cav_ope_id"], {
        is: cav_ope_id => (cav_ope_id == '2'),
        then: yup.string().required("O campo é obrigatório.")
          .nullable(),
      }),


    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {

            cartaoVirtualService
              .postAtualizar(
                values.cav_id,
                values.cav_ope_id,
                values.cav_cpf,
                values.cav_matricula,
                values.cav_nome,
                values.cav_nome_mae,
                values.cav_nome_pai,
                values.cav_sexo,
                values.cav_data_nascimento_,
                values.cav_ecv_id,
                values.cav_nacionalidade,
                values.cav_rg_numero,
                values.cav_tdc_id,
                values.cav_documento_uf,
                values.cav_rg_orgao_emissor,
                values.cav_rg_data_emissao_,
                values.cav_celular,
                values.cav_email,
                values.cav_endereco,
                values.cav_bairroendereco,
                values.cav_cep,
                values.cav_cidadeendereco,
                values.cav_complementoendereco,
                values.cav_endereconumero,
                values.cav_estadoendereco,
                values.cav_valor_renda,
                values.cav_endereco_tipo,                
                values.cav_ban_id,
                values.cav_ban_agencia,
                values.cav_ban_conta,
                values.cav_ban_digito,
                values.cav_ctg_id,
                values.cav_zee_id,
                values.cav_zeo_id,
                token
              )
              .then((data) => setRetornoDado(data));
          });
      });
    },
  });

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };




  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>

      {
        tipo === "V" && (
          <div className="p-button-raised mr-2 mb-2">
            <Link to={link_PlanosServidor}>
              <Button type="submit" label="Empréstimos" className="p-button-raised" />
            </Link>
          </div>
        )
      }
      {
        tipo === "V" && (
          <div className="p-button-raised mr-2 mb-2">
            <Link to={link_LancamentosServidor}>
              <Button type="submit" label="Lançamentos" className="p-button-raised" />
            </Link>
          </div>
        )
      }
      {
        tipo === "V" && (
          <div className="p-button-raised mr-2 mb-2">
            <Link to={link_AssinaturasServidor}>
              <Button type="submit" label="Assinaturas" className="p-button-raised" />
            </Link>
          </div>
        )
      }

      {tipo === "V" && formik.values.cav_ope_id != 1 ?
        <div className="p-button-raised mr-2 mb-2">
          <Link to={permissaoAcesso(_acesso_R) && link_R}>
            <Button
              label="Averbar cartão Benefício"
              icon={permissaoAcesso(_acesso_R) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-raised p-button-success "
              tooltip={"Averbar Cartão Benefício"}
            />
          </Link>
        </div>
        : null}
      {tipo === "V" && formik.values.cav_ope_id != 1 ?
        <div className="p-button-raised mr-2 mb-2">
          <Link to={permissaoAcesso(_acesso_E) && link_E}>
            <Button
              label="Averbar Saque"
              icon={permissaoAcesso(_acesso_R) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-raised p-button-success "
              tooltip={"Averbar Saque"}
            />
          </Link>
        </div>
        : null}


      {/* {tipo === "V" && formik.values.cav_ope_id != 1 ?
        <div className="p-button-raised mr-2 mb-2">
          <Link to={permissaoAcesso(_acesso_AVERBAR_CANCELAR) && link_resgateCancelarServidor}>
            <Button
              label="Cancelar Averbação"
              icon={permissaoAcesso(_acesso_AVERBAR_CANCELAR) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-raised p-button-warning"
              tooltip={"Cancelar Averbação"}
            />
          </Link>
        </div>
        : null} */}




    </div>



  );

  function checkValue(event) {
    formik.values.cav_valor_renda = handleDecimalsOnValue(event.target.value);

  }

  function handleDecimalsOnValue(value) {
    // Remove todos os caracteres que não são dígitos
    const sanitizedValue = value.replace(/[^0-9]/g, '');


    // Verifica se o valor está vazio ou não é um número válido
    if (!sanitizedValue) {
      return '';
    }

    // Divide o valor em parte inteira e parte decimal
    const integerPart = sanitizedValue.slice(0, -2);
    const decimalPart = sanitizedValue.slice(-2);

    // Combine a parte inteira e a parte decimal com uma vírgula
    const resultValue = `${integerPart},${decimalPart}`;

    return resultValue;
  }








  return (
    <div className="card p-fluid"  >
      <Toast ref={toast} />
      {header}
      {buttonHeader}
      <TabView>

        <TabPanel header="Dados Pessoais">
          <form>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_ope_id"
                      value={formik.values.cav_ope_id}
                      disabled={tipo !== "I" ? true : false}
                      onChange={formik.handleChange}
                      options={operadora}
                      optionLabel="ope_nome"
                      optionValue="ope_id"
                      onHide={buscaConvenios}
                      placeholder={formik.values.cav_ope_id}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ope_id &&
                          formik.errors.cav_ope_id,
                      })}
                    />
                    {formik.touched.cav_ope_id &&
                      formik.errors.cav_ope_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ope_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ope_id">Processadora</label>
                  </span>
                </div>
              </div>


              <div className={formik.values.cav_ope_id == '2' ? "col-12 md:col-2" : "col-12 md:col-6"}>
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_codigo_convenio"
                      disabled={tipo !== "I" ? true : false}
                      value={formik.values.cav_codigo_convenio}
                      onChange={formik.handleChange}
                      options={convenio}
                      optionLabel="con_nome_fantasia"
                      optionValue="con_id"
                      placeholder={
                        (formik.values.cav_codigo_convenio === ''
                          ? ""
                          : formik.values.cav_codigo_convenio)
                      }
                      emptyMessage={"Convênio deve ser selecionado"}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_codigo_convenio && formik.errors.cav_codigo_convenio,
                      })}
                    />


                    {formik.touched.cav_codigo_convenio && formik.errors.cav_codigo_convenio ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_codigo_convenio}
                      </div>
                    ) : null}
                    <label htmlFor="cav_codigo_convenio">Convênio *</label>
                  </span>
                </div>
              </div>

              {formik.values.cav_ope_id == '2' ?
                <div className={"col-12 md:col-2"}>
                  <div className="field">
                    <span className="p-float-label  mt-2">
                      <Dropdown
                        id="cav_zee_id"
                        value={formik.values.cav_zee_id}
                        onChange={formik.handleChange}
                        options={estabelecimento}
                        optionLabel="zee_nome"
                        optionValue="zee_id"
                        placeholder={
                          (formik.values.cav_zee_id === ''
                            ? ""
                            : formik.values.cav_zee_id)
                        }
                        emptyMessage={"Estabelecimento deve ser selecionado"}
                        className={classNames({
                          "p-invalid":
                            formik.touched.cav_zee_id && formik.errors.cav_zee_id,
                        })}
                      />
                      {formik.touched.cav_zee_id && formik.errors.cav_zee_id ? (
                        <div style={{ fontSize: 10, color: "red" }}>
                          {formik.errors.cav_zee_id}
                        </div>
                      ) : null}
                      <label htmlFor="cav_zee_id">Estabelecimento *</label>
                    </span>
                  </div>
                </div>
                : null}

              {formik.values.cav_ope_id == '2' ?
                <div className="col-12 md:col-4">
                  <div className="field">
                    <span className="p-float-label  mt-2">
                      <Dropdown
                        id="cav_zeo_id"
                        value={formik.values.cav_zeo_id}
                        onChange={formik.handleChange}
                        options={orgao}
                        optionLabel="zeo_nome"
                        optionValue="zeo_id"
                        placeholder={
                          (formik.values.cav_zeo_id === ''
                            ? ""
                            : formik.values.cav_zeo_id)
                        }
                        emptyMessage={"Orgão deve ser selecionado"}
                        className={classNames({
                          "p-invalid":
                            formik.touched.cav_zeo_id && formik.errors.cav_zeo_id,
                        })}
                      />
                      {formik.touched.cav_zeo_id && formik.errors.cav_zeo_id ? (
                        <div style={{ fontSize: 10, color: "red" }}>
                          {formik.errors.cav_zeo_id}
                        </div>
                      ) : null}
                      <label htmlFor="cav_zeo_id">Orgão *</label>
                    </span>
                  </div>
                </div>
                : null}




              <div className={formik.values.cav_ope_id == '2' ? "col-12 md:col-2" : "col-12 md:col-2"}>
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_ctg_id"
                      value={formik.values.cav_ctg_id}
                      onChange={formik.handleChange}
                      options={categoria}
                      optionLabel="ctg_descricao"
                      optionValue="ctg_id"
                      placeholder={
                        (formik.values.cav_ctg_id === ''
                          ? ""
                          : formik.values.cav_ctg_id)
                      }
                      emptyMessage={"Categoria deve ser selecionada"}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ctg_id && formik.errors.cav_ctg_id,
                      })}
                    />
                    {formik.touched.cav_ctg_id && formik.errors.cav_ctg_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ctg_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ctg_id">Categoria *</label>
                  </span>
                </div>
              </div>


              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      mask="999.999.999-99"
                      unmask={true}
                      disabled={formik.values.cav_ope_id === 1 ? true : campoHabilitado}
                      type="text"
                      id="cav_cpf"
                      name="cav_cpf"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_cpf}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_cpf && formik.errors.cav_cpf,
                      })}
                    />
                    {formik.touched.cav_cpf && formik.errors.cav_cpf ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_cpf}
                      </div>
                    ) : null}
                    <label htmlFor="cav_cpf">CPF *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={20}
                      disabled={formik.values.cav_ope_id === 1 ? true : campoHabilitado}
                      type="text"
                      id="cav_matricula"
                      name="cav_matricula"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_matricula}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_matricula &&
                          formik.errors.cav_matricula,
                      })}
                    />
                    {formik.touched.cav_matricula &&
                      formik.errors.cav_matricula ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_matricula}
                      </div>
                    ) : null}
                    <label htmlFor="cav_matricula">Matrícula *</label>
                  </span>
                </div>
              </div>

              <div className={formik.values.cav_ope_id === 1 ?"col-12 md:col-8" : "col-12 md:col-6"}>
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={formik.values.cav_ope_id === 1 ? true : campoHabilitado}
                      type="text"
                      id="cav_nome"
                      name="cav_nome"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_nome}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_nome &&
                          formik.errors.cav_nome,
                      })}
                    />
                    {formik.touched.cav_nome &&
                      formik.errors.cav_nome ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_nome}
                      </div>
                    ) : null}
                    <label htmlFor="cav_nome">Nome *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      disabled={campoHabilitado}
                      mask="99/99/9999"
                      unmask={true}
                      type="text"
                      id="cav_data_nascimento_"
                      name="cav_data_nascimento_"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_data_nascimento_}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_data_nascimento_ && formik.errors.cav_data_nascimento_,
                      })}
                    />
                    {formik.touched.cav_data_nascimento_ && formik.errors.cav_data_nascimento_ ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_data_nascimento_}
                      </div>
                    ) : null}
                    <label htmlFor="cav_data_nascimento_">Data Nascimento *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_sexo"
                      value={formik.values.cav_sexo}
                      onChange={formik.handleChange}
                      options={status_sexo}
                      placeholder={formik.values.cav_sexo}
                      onBlur={formik.handleBlur}
                      disabled={campoHabilitado}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_sexo && formik.errors.cav_sexo,
                      })}
                    />
                    {formik.touched.cav_sexo && formik.errors.cav_sexo ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_sexo}
                      </div>
                    ) : null}
                    <label htmlFor="cav_sexo">Sexo *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      mask="(99) 99999-9999"
                      unmask={true}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_celular"
                      name="cav_celular"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_celular}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_celular && formik.errors.cav_celular,
                      })}
                    />
                    {formik.touched.cav_celular && formik.errors.cav_celular ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_celular}
                      </div>
                    ) : null}
                    <label htmlFor="cav_celular">Celular *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      mask="(99) 9999-9999"
                      unmask={true}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_telefone"
                      name="cav_telefone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_telefone}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_telefone && formik.errors.cav_telefone,
                      })}
                    />
                    {formik.touched.cav_telefone && formik.errors.cav_telefone ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_telefone}
                      </div>
                    ) : null}
                    <label htmlFor="cav_telefone">Telefone contato</label>
                  </span>
                </div>
              </div>


              <div className="col-12 md:col-6">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_email"
                      name="cav_email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_email}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_email &&
                          formik.errors.cav_email,
                      })}
                    />
                    {formik.touched.cav_email &&
                      formik.errors.cav_email ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_email}
                      </div>
                    ) : null}
                    <label htmlFor="cav_email">E-Mail *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_nome_mae"
                      name="cav_nome_mae"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_nome_mae}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_nome_mae &&
                          formik.errors.cav_nome_mae,
                      })}
                    />
                    {formik.touched.cav_nome_mae &&
                      formik.errors.cav_nome_mae ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_nome_mae}
                      </div>
                    ) : null}
                    <label htmlFor="cav_nome_mae">Nome Mãe *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_nome_pai"
                      name="cav_nome_pai"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_nome_pai}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_nome_pai &&
                          formik.errors.cav_nome_pai,
                      })}
                    />
                    {formik.touched.cav_nome_pai &&
                      formik.errors.cav_nome_pai ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_nome_pai}
                      </div>
                    ) : null}
                    <label htmlFor="cav_nome_pai">Nome Pai *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_ecv_id"
                      value={formik.values.cav_ecv_id}
                      disabled={campoHabilitado}
                      onChange={formik.handleChange}
                      options={estadoCivil}
                      optionLabel="ecv_descricao"
                      optionValue="ecv_id"
                      placeholder={formik.values.cav_ecv_id}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ecv_id &&
                          formik.errors.cav_ecv_id,
                      })}
                    />
                    {formik.touched.cav_ecv_id &&
                      formik.errors.cav_ecv_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ecv_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ecv_id">Estado Civil *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={15}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_nacionalidade"
                      name="cav_nacionalidade"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_nacionalidade}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_nacionalidade &&
                          formik.errors.cav_nacionalidade,
                      })}
                    />
                    {formik.touched.cav_nacionalidade &&
                      formik.errors.cav_nacionalidade ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_nacionalidade}
                      </div>
                    ) : null}
                    <label htmlFor="cav_nome_pai">Nacionalidade</label>
                  </span>
                </div>
              </div>
            </div>

            <div className="grid">

              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_tdc_id"
                      value={formik.values.cav_tdc_id}
                      disabled={campoHabilitado}
                      onChange={formik.handleChange}
                      options={tipoDocPessoa}
                      optionLabel="tdc_descricao"
                      optionValue="tdc_id"
                      placeholder={formik.values.cav_tdc_id}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_tdc_id &&
                          formik.errors.cav_tdc_id,
                      })}
                    />
                    {formik.touched.cav_tdc_id &&
                      formik.errors.cav_tdc_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_tdc_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_tdc_id">Tipo Documento *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={20}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_rg_numero"
                      name="cav_rg_numero"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_rg_numero}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_rg_numero &&
                          formik.errors.cav_rg_numero,
                      })}
                    />
                    {formik.touched.cav_rg_numero && formik.errors.cav_rg_numero ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_rg_numero}
                      </div>
                    ) : null}
                    <label htmlFor="cav_rg_numero">Número *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_documento_uf"
                      value={formik.values.cav_documento_uf}
                      onChange={formik.handleChange}
                      options={lista_uf}
                      placeholder={formik.values.cav_documento_uf}
                      onBlur={formik.handleBlur}
                      disabled={campoHabilitado}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_documento_uf && formik.errors.cav_documento_uf,
                      })}
                    />
                    {formik.touched.cav_documento_uf && formik.errors.cav_documento_uf ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_documento_uf}
                      </div>
                    ) : null}
                    <label htmlFor="cav_documento_uf">UF *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={20}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_rg_orgao_emissor"
                      name="cav_rg_orgao_emissor"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_rg_orgao_emissor}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_rg_orgao_emissor &&
                          formik.errors.cav_rg_orgao_emissor,
                      })}
                    />
                    {formik.touched.cav_rg_orgao_emissor && formik.errors.cav_rg_orgao_emissor ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_rg_numero}
                      </div>
                    ) : null}
                    <label htmlFor="cav_rg_orgao_emissor">Órgão Emissor *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">

                  <span className="p-float-label mt-2">
                    <InputMask
                      disabled={campoHabilitado}
                      mask="99/99/9999"
                      unmask={true}
                      type="text"
                      id="cav_rg_data_emissao_"
                      name="cav_rg_data_emissao_"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_rg_data_emissao_}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_rg_data_emissao_ && formik.errors.cav_rg_data_emissao_,
                      })}
                    />
                    {formik.touched.cav_rg_data_emissao_ && formik.errors.cav_rg_data_emissao_ ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_rg_data_emissao_}
                      </div>
                    ) : null}
                    <label htmlFor="cav_rg_data_emissao_">Data Emissão *</label>
                  </span>
                </div>
              </div>
            </div>

            <h5>Endereço</h5>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      disabled={campoHabilitado}
                      mask=" 99.999-999"
                      unmask={true}
                      type="text"
                      id="cav_cep"
                      name="cav_cep"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_cep}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_cep && formik.errors.cav_cep,
                      })}
                    />
                    {formik.touched.cav_celular && formik.errors.cav_cep ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_cep}
                      </div>
                    ) : null}
                    <label htmlFor="cav_cep">Cep *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <span className="p-float-label  mt-2">
                  <Button
                    disabled={campoHabilitado}
                    type="button"
                    label="Pesquisa"
                    className="p-button-raised p-button-success "
                    onClick={BuscarCep}
                    icon="pi pi-search"
                  />
                </span>
              </div>
              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_endereco"
                      name="cav_endereco"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_endereco}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_endereco &&
                          formik.errors.cav_endereco,
                      })}
                    />
                    {formik.touched.cav_endereco && formik.errors.cav_endereco ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_endereco}
                      </div>
                    ) : null}
                    <label htmlFor="cav_endereco">Endereço *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={100}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_bairroendereco"
                      name="cav_bairroendereco"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_bairroendereco}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_bairroendereco &&
                          formik.errors.cav_bairroendereco,
                      })}
                    />
                    {formik.touched.cav_bairroendereco && formik.errors.cav_bairroendereco ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_bairroendereco}
                      </div>
                    ) : null}
                    <label htmlFor="cav_bairroendereco">Bairro *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={10}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_endereconumero"
                      name="cav_endereconumero"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_endereconumero}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_endereconumero &&
                          formik.errors.cav_endereconumero,
                      })}
                    />
                    {formik.touched.cav_endereconumero && formik.errors.cav_endereconumero ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_endereconumero}
                      </div>
                    ) : null}
                    <label htmlFor="cav_endereconumero">Número *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={10}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_complementoendereco"
                      name="cav_complementoendereco"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_complementoendereco}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_complementoendereco &&
                          formik.errors.cav_complementoendereco,
                      })}
                    />
                    {formik.touched.cav_complementoendereco && formik.errors.cav_complementoendereco ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_complementoendereco}
                      </div>
                    ) : null}
                    <label htmlFor="cav_complementoendereco">Complemento</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-5">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_endereco_tipo"
                      value={formik.values.cav_endereco_tipo}
                      onChange={formik.handleChange}
                      options={endereco_tipo}
                      placeholder={formik.values.cav_endereco_tipo}
                      onBlur={formik.handleBlur}
                      disabled={campoHabilitado}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_endereco_tipo && formik.errors.cav_endereco_tipo,
                      })}
                    />
                    {formik.touched.cav_endereco_tipo && formik.errors.cav_endereco_tipo ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_endereco_tipo}
                      </div>
                    ) : null}
                    <label htmlFor="cav_endereco_tipo">Tipo de endereço *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_estadoendereco"
                      value={formik.values.cav_estadoendereco}
                      disabled={campoHabilitado}
                      onChange={formik.handleChange}
                      onHide={buscaCidades}
                      options={estado}
                      optionLabel="est_uf"
                      optionValue="est_id"
                      placeholder={formik.values.cav_estadoendereco}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_estadoendereco &&
                          formik.errors.cav_estadoendereco,
                      })}
                    />
                    {formik.touched.cav_estadoendereco &&
                      formik.errors.cav_estadoendereco ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_estadoendereco}
                      </div>
                    ) : null}
                    <label htmlFor="cav_estadoendereco">UF *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      disabled={campoHabilitado}
                      id="cav_cidadeendereco"
                      value={formik.values.cav_cidadeendereco}
                      // onChange={(e) => formik.setFieldValue('cav_cidadeendereco', e.value)}                
                      onChange={formik.handleChange}
                      options={cidades}
                      optionLabel="cid_nome"
                      optionValue="cid_id"
                      placeholder={
                        (formik.values.cav_cidadeendereco === ""
                          ? "Selecione"
                          : formik.values.cav_cidadeendereco)
                      }
                      emptyMessage={"UF deve ser selecionado"}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_cidadeendereco && formik.errors.cav_cidadeendereco,
                      })}
                    />


                    {formik.touched.cav_cidadeendereco && formik.errors.cav_cidadeendereco ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_cidadeendereco}
                      </div>
                    ) : null}
                    <label htmlFor="cav_cidadeendereco">Cidade *</label>
                  </span>
                </div>
              </div>



              <div className="col-12 md:col-6">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputNumber
                      maxLength={15}
                      disabled={campoHabilitado}
                      prefix=""
                      value={formik.values.cav_valor_renda}
                      onValueChange={formik.handleChange}
                      mode="decimal"
                      locale="de-DE"
                      id="cav_valor_renda"
                      name="cav_valor_renda"
                      currencyDisplay="code"
                      maxFractionDigits={2}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_valor_renda && formik.errors.cav_valor_renda,
                      })}
                    />

                    {formik.touched.cav_valor_renda && formik.errors.cav_valor_renda ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_valor_renda}
                      </div>
                    ) : null}
                    <label htmlFor="cav_valor_renda">Renda Mensal</label>
                  </span>
                </div>
              </div>


            </div>


            <h5>Dados bancários</h5>
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_ban_id"
                      value={formik.values.cav_ban_id}
                      disabled={campoHabilitado}
                      onChange={formik.handleChange}
                      options={banco}
                      optionLabel="ban_nome"
                      optionValue="ban_id"
                      placeholder={formik.values.cav_ecv_id}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ban_id &&
                          formik.errors.cav_ban_id,
                      })}
                    />
                    {formik.touched.cav_ban_id &&
                      formik.errors.cav_ban_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ban_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ban_id">Banco *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={10}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_ban_agencia"
                      name="cav_ban_agencia"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_ban_agencia}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ban_agencia &&
                          formik.errors.cav_ban_agencia,
                      })}
                    />
                    {formik.touched.cav_ban_agencia && formik.errors.cav_ban_agencia ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ban_agencia}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ban_agencia">Agência *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={10}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_ban_conta"
                      name="cav_ban_conta"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_ban_conta}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ban_conta &&
                          formik.errors.cav_ban_conta,
                      })}
                    />
                    {formik.touched.cav_ban_conta && formik.errors.cav_ban_conta ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ban_conta}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ban_conta">Conta *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      maxLength={3}
                      disabled={campoHabilitado}
                      type="text"
                      id="cav_ban_digito"
                      name="cav_ban_digito"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_ban_digito}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ban_digito &&
                          formik.errors.cav_ban_digito,
                      })}
                    />
                    {formik.touched.cav_ban_digito && formik.errors.cav_ban_digito ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ban_digito}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ban_digito">Dígito *</label>
                  </span>
                </div>
              </div>
            </div>









          </form>
        </TabPanel>
        {tipo !== "I" && permissaoAcesso('adm/servidor/app') ?
          <TabPanel
            header="APP / SERVIDOR"
            disabled={!permissaoAcesso('adm/servidor/app') || tipo !== "U"}>
            <form>
              <div className="field">
                <span className="p-float-label mt-5">
                  <Button
                    visible={permissaoAcesso('adm/servidor/app') && tipo === "U"}
                    type="submit"
                    label={formik_app.values.cav_bloqueado ? "Desbloquear - APP" : "Bloquear - APP"}
                    className="p-button-raised p-button-success "
                    onClick={formik_app.handleSubmit}
                  />
                </span>
              </div>
              <div className="field">
              </div>
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputTextarea
                    disabled={campoHabilitado}
                    rows={5}
                    type="text"
                    id="cav_motivo_bloqueio"
                    name="cav_motivo_bloqueio"
                    onChange={formik_app.handleChange}
                    onBlur={formik_app.handleBlur}
                    value={formik_app.values.cav_motivo_bloqueio}
                    className={classNames({
                      "p-invalid":
                        formik_app.touched.cav_motivo_bloqueio &&
                        formik_app.errors.cav_motivo_bloqueio,
                    })}
                  />
                  {formik_app.touched.cav_motivo_bloqueio &&
                    formik_app.errors.cav_motivo_bloqueio ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik_app.errors.cav_motivo_bloqueio}
                    </div>
                  ) : null}
                  <label htmlFor="cav_motivo_bloqueio">Motivo do Bloqueio</label>
                </span>
              </div>
              <div className="field">
                <div className="field">
                  <span className="p-float-label mt-5">
                    <Button
                      visible={permissaoAcesso('adm/servidor/app') && tipo === "U"}
                      type="submit"
                      label={formik_app.values.cav_cancelado ? "Ativar - Servidor" : "Cancelar - Servidor"}
                      className="p-button-raised p-button-success "
                      onClick={formik_app.handleSubmit}
                    />
                  </span>
                </div>
              </div>
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputTextarea
                    disabled={campoHabilitado}
                    rows={5}
                    type="text"
                    id="cav_motivo"
                    name="cav_motivo"
                    onChange={formik_app.handleChange}
                    onBlur={formik_app.handleBlur}
                    value={formik_app.values.cav_motivo}
                    className={classNames({
                      "p-invalid":
                        formik_app.touched.cav_motivo && formik_app.errors.cav_motivo,
                    })}
                  />
                  {formik_app.touched.cav_motivo && formik_app.errors.cav_motivo ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik_app.errors.cav_motivo}
                    </div>
                  ) : null}
                  <label htmlFor="cav_motivo">Motivo do Cancelamento</label>
                </span>
              </div>

            </form>
          </TabPanel>
          : null}
        {tipo !== "I" && permissaoAcesso('adm/servidor/volus') ?
          <TabPanel header="Cartão Benefício">

            <h5>Bloquear -  Efetua o bloqueio e somente o adminstrativo poderá desbloquear</h5>

            <div className="field">
              <span className="p-float-label mt-5">
                <Button
                  visible={!permissaoAcesso('adm/cartao/bloquearcartaodigital') && tipo === "U"}
                  type="submit"
                  label={formik_outros.values.cav_compras_cartao_bloqueio ? "Desbloquear" : "Bloquear"}
                  className="p-button-raised p-button-success "
                  onClick={formik_outros.handleSubmit}
                />
              </span>
            </div>
            <div className="field">
              <span className="p-float-label mt-5">
                <InputTextarea
                  disabled={campoHabilitado}
                  rows={5}
                  type="text"
                  id="cav_compras_cartao_motivo"
                  name="cav_compras_cartao_motivo"
                  onChange={formik_outros.handleChange}
                  onBlur={formik_outros.handleBlur}
                  value={formik_outros.values.cav_compras_cartao_motivo}
                  className={classNames({
                    "p-invalid":
                      formik_outros.touched.cav_compras_cartao_motivo && formik_outros.errors.cav_compras_cartao_motivo,
                  })}
                />
                {formik_outros.touched.cav_compras_cartao_motivo && formik_outros.errors.cav_compras_cartao_motivo ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik_outros.errors.cav_compras_cartao_motivo}
                  </div>
                ) : null}

                <label htmlFor="cav_compras_cartao_motivo">Motivo</label>
              </span>
            </div>

            <div className="field">
              <h5>Histórico de Bloqueios</h5>
              <span className="p-float-label mt-5">
                <InputTextarea
                  disabled={false}
                  rows={5}
                  type="text"
                  id="cav_compras_historico"
                  name="cav_compras_historico"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cav_compras_historico}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cav_compras_historico &&
                      formik.errors.cav_compras_historico,
                  })}
                />
                <label htmlFor="cav_compras_cartao_motivo"></label>
              </span>
            </div>

          </TabPanel>
          : null}

      </TabView>

      {loadingSpinner ? <LoadingSpinner visualiza={loadingSpinner} /> : ''}
    </div >
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
