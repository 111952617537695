import api from "./Api";

export class LiberarMargemService {
  async buscarCancelamentos() {
    return await api
      .post(
        "gerencial/liberarMargem/listar",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "gerencial/liberarMargem/visualizar",
        { cbn_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getStatusParcelas() {
    return await api
      .get("gerencial/comprasFacajus/listarStatusParcelas", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postLiberar(cbn_id) {
    return await api
      .post(
        "gerencial/liberarMargem/liberar",
        {
          cbn_id: cbn_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
