import api from "./Api";
 
export class UsuariosService {
  async getAll() {
    return await api
      .get("administrativo/usuario/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  async getById(id) {
    return await api
      .post(
        "administrativo/usuario/visualizar",
        { usu_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async getTipoUsuario() {
    return await api
      .get(
        "administrativo/usuario/listar-tipo",       
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postAtualizar(
    usu_id,
    usu_cpf,
    usu_nome,
    usu_login,
    usu_ativo,
    usu_email,    
    usu_senha,
    usu_per_id,
    usu_ust_id,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/usuario/atualizar",
        {
          usu_id: usu_id,
          usu_cpf: usu_cpf,
          usu_nome: usu_nome,
          usu_login: usu_login,
          usu_ativo: usu_ativo,
          usu_email: usu_email,          
          usu_senha: usu_senha,
          usu_per_id: usu_per_id,
          usu_ust_id: usu_ust_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postCadastrar(    
    usu_cpf,
    usu_nome,
    usu_login,
    usu_ativo,
    usu_email,    
    usu_senha,
    usu_per_id,
    usu_ust_id,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/usuario/cadastrar",
        {
          usu_cpf: usu_cpf,
          usu_nome: usu_nome,
          usu_login: usu_login,
          usu_ativo: usu_ativo,
          usu_email: usu_email,          
          usu_senha: usu_senha,
          usu_per_id: usu_per_id,
          usu_ust_id: usu_ust_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
  
}
