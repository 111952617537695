import React, { useRef, useState } from "react";
import "./Teclado.css";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PropTypes from "prop-types";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

export default function Teclado({ maxDigitos, tecladoClose, tecladoPassword,numeros }) {
  const [password, setPassWord] = useState("");
  const dadosTeclado = (num) => {
    if (password.length <= maxDigitos - 1) {
      setPassWord(password + num);
    }
  };

  const apagarDadosTeclado = () => {
    if (password.length !== 0) {
      setPassWord(password.substring(0, password.length - 1));
    }
  };

  const inputRef = useRef(null);
  const [eyeIsClosed, setEyeState] = useState(false);

  const toggleShow = () => {
    if (inputRef.current.type === "password") {
      setEyeState(true);
      inputRef.current.type = "text";
    } else {
      setEyeState(false);
      inputRef.current.type = "password";
    }
  };

 
  
 
  

  return (
    <div className="card p-fluid">
      <h3 className="teclado-titulo">
    
        Digite sua senha de {maxDigitos} dígitos. 
      </h3>
      <div className="formgrid grid mr-2 mb-2">
        <div className="teclado-password p-inputgroup  ">
          <InputText
            ref={inputRef}
            className="teclado-password"
            id="passsword"
            maxLength={maxDigitos}
            type="password"
            value={password}
            disabled
          />
          <button onClick={toggleShow}>
            {eyeIsClosed ? <VscEyeClosed /> : <VscEye />}
          </button>
        </div>
      </div>

      <div className="formgrid grid">
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[0])}
            label={numeros[0] == 0 ?'0':numeros[0]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[1])}
            label={numeros[1] == 0 ?'0':numeros[1]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[2])}
            label={numeros[2] == 0 ?'0':numeros[2]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[3])}
            label={numeros[3] == 0 ?'0':numeros[3]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[4])}
            label={numeros[4] == 0 ?'0':numeros[4]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[5])}
            label={numeros[5] == 0 ?'0':numeros[5]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[6])}
            label={numeros[6] == 0 ?'0':numeros[6]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[7])}
            label={numeros[7] == 0 ?'0':numeros[7]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[8])}
            label={numeros[8] == 0 ?'0':numeros[8]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Button
            type="submit"
            label="Apagar"
            onClick={() => apagarDadosTeclado()}
            className="p-button-warning mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            onClick={() => dadosTeclado(numeros[9])}
            label={numeros[9] == 0 ?'0':numeros[9]}
            className="p-button-success mr-2 mb-2"
          />
        </div>
        <div className="field col-4">
          <Button
            type="submit"
            label="Confirmar"
            onClick={(event) => tecladoPassword(password)}
            className="p-button-success mr-2 mb-2"
          />
        </div>
      </div>
      <div className="field col">
        <Button
          type="submit"
          label="Cancelar"
          onClick={(event) => tecladoClose()}
          className="p-button-danger mr-2 mb-2"
        />
      </div>
    </div>
  );
}

Teclado.propTypes = {
  maxDigitos: PropTypes.func.isRequired,
  tecladoClose: PropTypes.func.isRequired,
  tecladoPassword: PropTypes.func.isRequired,
};
