export const ResumoRepasse = ({dados}) => {

  const total = [
    dados.compras.enviados[0] + dados.saque.enviados[0],
    dados.compras.enviados[1] + dados.saque.enviados[1],
    dados.compras.descontados[0] + dados.saque.descontados[0],
    dados.compras.descontados[1] + dados.saque.descontados[1],
    dados.compras.inadimplentes[0] + dados.saque.inadimplentes[0],
    dados.compras.inadimplentes[1] + dados.saque.inadimplentes[1],
  ]

  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <td className="w-1"></td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Valores enviados</td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Qtd</td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Valores descontados</td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Qtd</td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Valores pendentes</td>
            <td className="border-1 m-1 border-primary text-primary text-lg text-center" style={{borderRadius: 5}}>Qtd</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-right text-lg p-2 font-semibold">Cartão compras</td>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.enviados[0] === 0 
                ? "--"
                : dados.compras.enviados[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.enviados[1] === 0
                ? "--"
                : dados.compras.enviados[1]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.descontados[0] === 0 
                ? "--"
                : dados.compras.descontados[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.descontados[1] === 0
                ? "--"
                : dados.compras.descontados[1]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.inadimplentes[0] === 0 
                ? "--"
                : dados.compras.inadimplentes[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.compras.inadimplentes[1] === 0
                ? "--"
                : dados.compras.inadimplentes[1]
              }
            </th>
          </tr>
          <tr>
            <td className="text-right text-lg p-2 font-semibold">Saque</td>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.enviados[0] === 0
                ? "--"
                : dados.saque.enviados[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.enviados[1] === 0
                ? "--"
                : dados.saque.enviados[1]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.descontados[0] === 0
                ? "--"
                : dados.saque.descontados[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.descontados[1] === 0
                ? "--"
                : dados.saque.descontados[1]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.inadimplentes[0] === 0
                ? "--"
                : dados.saque.inadimplentes[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                dados.saque.inadimplentes[1] === 0
                ? "--"
                : dados.saque.inadimplentes[1]
              }
            </th>
          </tr>
          <tr>
            <td className="text-right text-lg p-2 font-semibold">Total</td>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[0] === 0
                ? "--"
                : total[0].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[1] === 0
                ? "--"
                : total[1]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[2] === 0
                ? "--"
                : total[2].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[3] === 0
                ? "--"
                : total[3]
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[4] === 0
                ? "--"
                : total[4].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            </th>
            <th className="text-lg p-2" style={{backgroundColor: '#F8F9FA', borderRadius: 5}}>
              {
                total[5] === 0
                ? "--"
                : total[5]
              }
            </th>
          </tr>
        </tbody>
      </table>
    </>
  )
}