import api from "./Api";
 
export class InformativosService {
  async getAll() {
    return await api
      .get("administrativo/informativos-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  async getById(id) {
    return await api
      .post(
        "/informativos-id",
        { inf_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postAtualizar(inf_id, inf_est_id, inf_descricao, token) {
    return await api
      .post(
        "/informativos-atualizar",
        {
          inf_id: inf_id,
          inf_est_id: inf_est_id,
          inf_descricao: inf_descricao,
          token: token,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
  async postCadastrar(inf_est_id, inf_descricao, token) {
    return await api
      .post(
        "/informativos-cadastrar",
        {
          inf_est_id: inf_est_id,
          inf_descricao: inf_descricao,
          token: token,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
