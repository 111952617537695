import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Dropdown } from "primereact/dropdown";

import Acessos from "../../components/Acessos/Acessos";
import { LiberarMargemService } from "../../service/LiberarMargemService";
import formataData from "../../components/formataData";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [tipoAcesso, setTipoAcesso] = useState(false);
  const [acessosPerfil, setAcessosPerfil] = useState(0);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/liberarMargem";
  const cabecalho_form = "Liberar margem";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form}</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    cbn_id: null,
    cbn_data: null,
    cbn_valor_resgatado: "",
    cbn_saldo: null,
    cav_matricula: null,
    cav_nome: null,
    cav_cpf: null,
  };

  let retornoDados = {
    status: null,
    mensagem: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const liberarMargemService = new LiberarMargemService();

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "U") {
        // SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);

      liberarMargemService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {
          history.push("/semToken");
        });
    }
  }, [id, tipo]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
      SetLoadingSpinner(false);
    }
  }, [retornoDado]);

  // const [statusParcelas, setStatusParcelas] = useState();

  // useEffect(() => {
  //   SetLoadingSpinner(true);
  //   comprasFacajusService
  //     .getStatusParcelas()
  //     .then((data) => setStatusParcelas(data.dados), SetLoadingSpinner(false));
  // }, []);

  const formik = useFormik({
    initialValues: {
      cbn_id: modelDado.cbn_id,
      cbn_data: modelDado.cbn_data,
      cbn_valor_resgatado: modelDado.cbn_valor_resgatado,
      cbn_saldo: modelDado.cbn_saldo,
      cav_nome: modelDado.cav_nome,
      cav_cpf: modelDado.cav_cpf,
      cav_matricula: modelDado.cav_matricula,
    },
    // validationSchema: yup.object({
    //   prc_id: yup.string().required("O campo é obrigatório."),
    //   prc_valor: yup.string().required("O campo é obrigatório."),
    //   prc_prs_id: yup.string().required("O campo é obrigatório."),
    // }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            liberarMargemService
              .postLiberar(values.cbn_id)
              .then((data) => setRetornoDado(data))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });
    },
  });

  const handleChangeDecimal = (e) => {
    const { name, value } = e.target;
    const formattedValue = handleDecimalsOnValue(value);
    formik.setFieldValue(name, formattedValue);
  };

  function handleDecimalsOnValue(value) {
    // Remove todos os caracteres que não são dígitos
    const sanitizedValue = value.replace(/[^0-9]/g, "");

    // Verifica se o valor está vazio ou não é um número válido
    if (!sanitizedValue) {
      return "";
    }

    // Divide o valor em parte inteira e parte decimal
    const integerPart = sanitizedValue.slice(0, -2);
    const decimalPart = sanitizedValue.slice(-2);

    // Combine a parte inteira e a parte decimal com uma vírgula
    const resultValue = `${integerPart},${decimalPart}`;

    return resultValue;
  }

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Tenho certeza"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        <div className="grid">
          <div className="col-12 md:col-12 "></div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cbn_data"
                  name="cbn_data"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cbn_data}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cbn_data">Data do cancelamento</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cbn_valor_resgatado"
                  name="cbn_valor_resgatado"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cbn_valor_resgatado}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cbn_valor_resgatado">Valor resgatado</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cbn_saldo"
                  name="cbn_saldo"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cbn_saldo}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cbn_saldo">Valor restante</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cav_nome"
                  name="cav_nome"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cav_nome}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cav_nome">Nome</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cav_cpf"
                  name="cav_cpf"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cav_cpf}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cav_cpf">CPF</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="cav_matricula"
                  name="cav_matricula"
                  disabled={true}
                  maxLength={8}
                  type="text"
                  value={formik.values.cav_matricula}
                  // onChange={handleChangeDecimal}
                />

                <label htmlFor="cav_matricula">Matricula</label>
              </span>
            </div>
          </div>
          <h5>Tem certeza que deseja liberar a margem deste servidor?</h5>
        </div>
        {buttonHeader}
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
