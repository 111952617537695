import api from "../Api";
import { Funcoes } from '../../utils/funcoes.js'

export class CorrespondenteService {

  

  async getCategoria() {
    return await api
      .get("administrativo/correspondente/listar-categoria", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getOrgao() {
    return await api
      .get("administrativo/correspondente/listar-orgao", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getEstabelecimento() {
    return await api
      .get("administrativo/correspondente/listar-estabelecimento", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }



  async getEstadoCivil() {
    return await api
      .get("administrativo/correspondente/estadocivil", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getTipoDocPessoa() {
    return await api
      .get("administrativo/correspondente/tipodocpessoa", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }


  async getAll() {
    return await api
      .get("administrativo/correspondente/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "administrativo/correspondente/visualizar",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getByCpf(cpf,token) {
    return await api
      .post(
        "administrativo/correspondente/pesquisarCpf",
        { 
          cav_cpf: cpf,
          tokenGoogleo :token
         },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  postBloqueiaCartaoDigital
  async postBloqueiaCartaoDigital(
    cav_id,
    cav_compras_cartao_bloqueio,
    cav_compras_cartao_motivo,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/correspondente/bloqueiacartaodigital",
        {
          cav_id: cav_id,
          cav_compras_cartao_bloqueio: cav_compras_cartao_bloqueio,
          cav_compras_cartao_motivo: cav_compras_cartao_motivo,
          tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async getResgateById(id) {
    return await api
      .post(
        "administrativo/correspondente/lancamentosresgate",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async postCadastrar(
    cav_ope_id,
    cav_codigo_convenio,
    cav_cpf,
    cav_matricula,
    cav_nome,
    cav_nome_mae,
    cav_nome_pai,
    cav_sexo,
    cav_data_nascimento_,
    cav_ecv_id,
    cav_nacionalidade,
    cav_rg_numero,
    cav_tdc_id,
    cav_documento_uf,
    cav_rg_orgao_emissor,
    cav_rg_data_emissao_,
    cav_celular,
    cav_email,
    cav_endereco,
    cav_bairroendereco,
    cav_cep,
    cav_cidadeendereco,
    cav_complementoendereco,
    cav_endereconumero,
    cav_estadoendereco,
    cav_valor_renda,
    cav_endereco_tipo,
    cav_cancelado,
    cav_motivo,
    cav_bloqueado,
    cav_motivo_bloqueio,
    cav_ban_id,
    cav_ban_agencia,
    cav_ban_conta,
    cav_ban_digito,
    cav_ctg_id,
    cav_zee_id,
    cav_zeo_id,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/correspondente/cadastrar",
        {
         
          cav_ope_id: cav_ope_id,
          cav_codigo_convenio:cav_codigo_convenio,
          cav_cpf: cav_cpf,
          cav_matricula: cav_matricula,
          cav_nome: cav_nome,
          cav_nome_mae: cav_nome_mae,
          cav_nome_pai: cav_nome_pai,
          cav_sexo: cav_sexo,
          cav_data_nascimento_: cav_data_nascimento_,
          cav_ecv_id: cav_ecv_id,
          cav_nacionalidade: cav_nacionalidade,
          cav_rg_numero: cav_rg_numero,
          cav_tdc_id: cav_tdc_id,
          cav_documento_uf: cav_documento_uf,
          cav_rg_orgao_emissor: cav_rg_orgao_emissor,
          cav_rg_data_emissao_: cav_rg_data_emissao_,
          cav_celular: cav_celular,
          cav_email: cav_email,
          cav_endereco: cav_endereco,
          cav_bairroendereco: cav_bairroendereco,
          cav_cep: cav_cep,
          cav_cidadeendereco: cav_cidadeendereco,
          cav_complementoendereco: cav_complementoendereco,
          cav_endereconumero: cav_endereconumero,
          cav_estadoendereco: cav_estadoendereco,
          cav_valor_renda: cav_valor_renda,
          cav_endereco_tipo: cav_endereco_tipo,
          cav_cancelado: cav_cancelado,
          cav_motivo: cav_motivo,
          cav_bloqueado: cav_bloqueado,
          cav_motivo_bloqueio: cav_motivo_bloqueio,
          cav_ban_id: cav_ban_id,
          cav_ban_agencia: cav_ban_agencia,
          cav_ban_conta: cav_ban_conta,
          cav_ban_digito: cav_ban_digito,
          cav_ctg_id: cav_ctg_id,
          cav_zee_id: cav_zee_id,
          cav_zeo_id: cav_zeo_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }



  async postAtualizar(
    cav_id,
    cav_ope_id,
    cav_cpf,
    cav_matricula,
    cav_nome,
    cav_nome_mae,
    cav_nome_pai,
    cav_sexo,
    cav_data_nascimento_,
    cav_ecv_id,
    cav_nacionalidade,
    cav_rg_numero,
    cav_tdc_id,
    cav_documento_uf,
    cav_rg_orgao_emissor,
    cav_rg_data_emissao_,
    cav_celular,
    cav_email,
    cav_endereco,
    cav_bairroendereco,
    cav_cep,
    cav_cidadeendereco,
    cav_complementoendereco,
    cav_endereconumero,
    cav_estadoendereco,
    cav_valor_renda,
    cav_endereco_tipo,
    cav_cancelado,
    cav_motivo,
    cav_bloqueado,
    cav_motivo_bloqueio,
    cav_ban_id,
    cav_ban_agencia,
    cav_ban_conta,
    cav_ban_digito,
    cav_ctg_id,
    cav_zee_id,
    cav_zeo_id,
    tokenGoogleo
  ) {
     
    return await api
      .post(
        "administrativo/correspondente/atualizar",
        {
          cav_id: cav_id,
          cav_ope_id: cav_ope_id,
          cav_cpf: cav_cpf,
          cav_matricula: cav_matricula,
          cav_nome: cav_nome,
          cav_nome_mae: cav_nome_mae,
          cav_nome_pai: cav_nome_pai,
          cav_sexo: cav_sexo,
          cav_data_nascimento_: cav_data_nascimento_,
          cav_ecv_id: cav_ecv_id,
          cav_nacionalidade: cav_nacionalidade,
          cav_rg_numero: cav_rg_numero,
          cav_tdc_id: cav_tdc_id,
          cav_documento_uf: cav_documento_uf,
          cav_rg_orgao_emissor: cav_rg_orgao_emissor,
          cav_rg_data_emissao_: cav_rg_data_emissao_,
          cav_celular: cav_celular,
          cav_email: cav_email,
          cav_endereco: cav_endereco,
          cav_bairroendereco: cav_bairroendereco,
          cav_cep: cav_cep,
          cav_cidadeendereco: cav_cidadeendereco,
          cav_complementoendereco: cav_complementoendereco,
          cav_endereconumero: cav_endereconumero,
          cav_estadoendereco: cav_estadoendereco,
          cav_valor_renda: cav_valor_renda,
          cav_endereco_tipo: cav_endereco_tipo,
          cav_cancelado: cav_cancelado,
          cav_motivo: cav_motivo,
          cav_bloqueado: cav_bloqueado,
          cav_motivo_bloqueio: cav_motivo_bloqueio,
          cav_ban_id: cav_ban_id,
          cav_ban_agencia: cav_ban_agencia,
          cav_ban_conta: cav_ban_conta,
          cav_ban_digito: cav_ban_digito,
          cav_ctg_id: cav_ctg_id,
          cav_zee_id: cav_zee_id,
          cav_zeo_id: cav_zeo_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async planosEmprestimoServidor(id) {
    return await api
      .post(
        "administrativo/correspondente/lancamentosplanos",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async lancamentosServidor(id) {
    return await api
      .post(
        "administrativo/correspondente/lancamentos",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
  async postResgatarEmprestimo(
    cav_id,
    valor,
    arquivo,
    qtdeParcelas,
    tokenGoogleo
  ) {     
    return await api
      .post(
        "administrativo/correspondente/resgataremprestimo",
        {
          cav_id,
          valor,
          arquivo,
          qtdeParcelas,
          tokenGoogleo
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postResgatarMargem(
    cav_id,
    valor,
    arquivo,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/correspondente/resgatarmargem",
        {
          cav_id: cav_id,
          valor,
          arquivo,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postCancelarMargem(
    cav_id,
    valor,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/correspondente/cancelarmargem",
        {
          cav_id: cav_id,
          valor,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async emprestimoListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/correspondente/relatorio-emprestimo",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async getComprarSeguros(cav_id,ser_id,token) {
    return await api
      .post(
        "administrativo/correspondente/comprar-seguros",
        { 
          cav_id: cav_id,
          ser_id: ser_id,
          tokenGoogleo : token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }


  async getById(id) {
    return await api
      .post(
        "administrativo/correspondente/visualizar",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getResgateEmprestimoById(id) {
    return await api
      .post(
        "administrativo/correspondente/lancamentosresgateemprestimo",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  

  async getDownloadById(id) {
    return await api
      .post(
        "administrativo/correspondente/download-averbacao",
        { tra_id: id },
        {
          headers: {            
            "Content-type": "application/json",         
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

}
