import api from "./Api";

export class LoginService {
  async post(login, senha,tokenGoogleo) {
    return await api
      .post(
        "/administrativo/login/autenticar",
        {
          login: login,
          senha: senha,
          tokenGoogleo:tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async esqueciMinhaSenha(login, tokenGoogleo) {
    return await api
      .post(
        "administrativo/esqueci-minha-senha",
        {
          login: login,
          veioDeQuem:'ADM',
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json"
          },
        }
      )
      .then((res) => res.data);
  }


  async resetaSenha(userName, token, password, passwordNew, tokenGoogleo) {
    return await api
      .post(
        "/reseta-senha",
        {
          id: userName,
          token: token,
          senha: password,
          senhaconfirma: passwordNew,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => res.data);
  }

}
