import api from "./Api";

export class PerfilService {
  async getAll() {
    return await api
      .get("administrativo/perfil/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getByEmpresa(empresa_id) {
    return await api
      .post("administrativo/perfil/listarByEmpresa", {
        empresa_id : empresa_id
      },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      .then((res) => res.data);
  }

  async getAcesso() {
    return await api
      .get("administrativo/perfil/acessos", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "administrativo/perfil/visualizar",
        { per_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postAtualizar(id, descricao, nome,per_ust_id,acessos) {
    return await api
      .post(
        "administrativo/perfil/atualizar",
        {
          per_id: id,
          per_descricao: descricao,
          per_nome: nome,
          per_ust_id : per_ust_id,
          per_acessos: acessos,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postCadastrar(descricao, nome, per_ust_id, acessos) {
    return await api
      .post(
        "administrativo/perfil/cadastrar",
        {
          per_descricao: descricao,
          per_nome: nome,
          per_ust_id: per_ust_id,
          per_acessos: acessos,

        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
