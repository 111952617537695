import React, { useState, useEffect, useRef, useCallback } from "react";
import { Chart } from 'primereact/chart';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PropTypes from "prop-types";


export function GraficoLogEstatistico({labels,datasetA,datasetF,ano}) {


    const data = {
        labels: labels,
        datasets: [
            {
                label: "Acessos",
                data: datasetA,
                fill: true,
                borderColor: '#91c145',
                color: '#91c145',
                backgroundColor: "#91c145"
            },
            {
                label: "Compras",
                data: datasetF,
                fill: true,
                borderColor: '#565656',
                color: '#565656',
                backgroundColor: "#565656"
            }
        ]
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Estatístico " + ano,
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'bottom'
            }
        }
    }

    return (
        <Chart type="bar" data={data} options={options} />
     )
}