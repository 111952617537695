import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputTextarea } from "primereact/inputtextarea";

import { LogAppService } from "../../service/LogAppService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/logApp";
  const cabecalho_form = "Log do Aplicativo";

  let header = (
    // tipo === "I" ? (
    //   <h5>{cabecalho_form} - Cadastrar</h5>
    // ) : tipo === "U" ? (
    //   <h5>{cabecalho_form} - Alterar</h5>
    // ) : (
    <h5>{cabecalho_form} - Visualizar</h5>
  );

  let emptyDados = {
      lws_id: "",
      lws_data: "",
      lws_tipo: "",
      lws_sistema: "",
      cav_matricula: "",
      cav_cpf: "",
      cav_nome: "",
      est_cnpj: "",
      est_razao_social: "",

      lws_envio: "",
      lws_retorno: "",
      lws_metodo: "",
      lws_exception: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const logAppService = new LogAppService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const logAppService = new LogAppService();
      logAppService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))        
        ;
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {    
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      lws_id: modelDado.lws_id,
      lws_data: modelDado.lws_data,
      lws_tipo: modelDado.lws_tipo,
      lws_sistema: modelDado.lws_sistema,
      cav_matricula: modelDado.cav_matricula,
      cav_cpf: modelDado.cav_cpf,
      cav_nome: modelDado.cav_nome,
      est_cnpj: modelDado.est_cnpj,
      est_razao_social: modelDado.est_razao_social,

      lws_envio: modelDado.lws_envio,
      lws_retorno: modelDado.lws_retorno,
      lws_metodo: modelDado.lws_metodo,
      lws_exception: modelDado.lws_exception,
    },
  });

  const buttonHeader = (
    <div className="grid">
      {/* {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )} */}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      {header}
      {buttonHeader}
      <div className="col-12">
        <div className="card">
          <div className="p-fluid formgrid grid">
            <span className="p-float-label mt-5 lg:col-3">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="lws_data"
                name="lws_data"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_data}
              />
              <label htmlFor="lws_descricao">Data e hora</label>
            </span>

            <span className="p-float-label mt-5 lg:col-2">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="lws_tipo"
                name="lws_tipo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_tipo}
              />
              <label htmlFor="lws_descricao">Tipo</label>
            </span>

            <span className="p-float-label mt-5 lg:col-2">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="lws_sistema"
                name="lws_sistema"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_sistema}
              />
              <label htmlFor="lws_descricao">Sistema</label>
            </span>

            <span className="p-float-label mt-5 lg:col-5">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="lws_descricao"
                name="lws_descricao"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_descricao}
              />
              <label htmlFor="lws_descricao">Descrição</label>
            </span>

            <span className="p-float-label mt-5 lg:col-4">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="cav_nome"
                name="cav_nome"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cav_nome}
              />
              <label htmlFor="lws_descricao">Nome</label>
            </span>
            <span className="p-float-label mt-5 lg:col-4">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="cav_matricula"
                name="cav_matricula"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cav_matricula}
              />
              <label htmlFor="lws_descricao">Matrícula</label>
            </span>
            <span className="p-float-label mt-5 lg:col-4">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="cav_cpf"
                name="cav_cpf"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cav_cpf}
              />
              <label htmlFor="lws_descricao">CPF</label>
            </span>

            <span className="p-float-label mt-5 lg:col-6">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="est_cnpj"
                name="est_cnpj"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.est_cnpj}
              />
              <label htmlFor="lws_descricao">CNPJ</label>
            </span>

            <span className="p-float-label mt-5 lg:col-6">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="est_razao_social"
                name="est_razao_social"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.est_razao_social}
              />
              <label htmlFor="lws_descricao">Razão Social</label>
            </span>

            <span className="p-float-label mt-5 lg:col-4">
              <InputTextarea
               
                type="text"
                id="lws_envio"
                name="lws-envio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_envio}
              />
              <label htmlFor="lws_descricao">Mensagem de envio</label>
            </span>

            <span className="p-float-label mt-5 lg:col-4">
              <InputTextarea
                
                type="text"
                id="lws_retorno"
                name="lws-retorno"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_retorno}
              />
              <label htmlFor="lws_descricao">Mensagem de Retorno</label>
            </span>

            <span className="p-float-label mt-5 lg:col-4">
              <InputText
              
                type="text"
                id="lws_metodo"
                name="lws-metodo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_metodo}
              />
              <label htmlFor="lws_descricao">Método</label>
            </span>

            <span className="p-float-label mt-5 lg:col-12">
              <InputTextarea
               
                type="text"
                id="lws_exception"
                name="lws-exception"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lws_exception}
              />
              <label htmlFor="lws_descricao">Exceção</label>
            </span>
          </div>
        </div>
      </div>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
