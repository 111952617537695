import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { ConvenioService } from "../../service/ConvenioService";


const _Index = () => {
  const isset = (ref) => typeof ref !== 'undefined'
  const history = useHistory();

  let retornoDados = {
    status: null,
    mensagem: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Convênio";
  // let _linkSistema_I = "/convenioForm_I/";
  let _linkSistema_U = "/convenioForm_U/";
  let _linkSistema_V = "/convenioForm_V/";

  let _linkSistemaAgenda = "/agendaCorteIndex/";
  let _linkSistemaFeriados = "/FeriadosIndex/";

  const [convenios, setConvenios] = useState(null);
  const [selectedConvenios, setSelectedConvenios] = useState(null);
  const convenioService = new ConvenioService();

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };


  useEffect(() => {
    if (retornoDado.status === false) {

      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    convenioService
      .getAll()
      .then(
        (data) => (data.status === true ? setConvenios(data.dados) : setRetornoDado(data)),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  const leftToolbarTemplate = () => {
    // let _link_I = _linkSistema_I + "0/I";
    // return (
    //   <React.Fragment>
    //     <div className="my-2">
    //       <Link to={_link_I}>
    //         <Button
    //           label="Cadastrar"
    //           icon="pi pi-plus"
    //           className="p-button-success mr-2"
    //         />
    //       </Link>
    //     </div>
    //   </React.Fragment>
    // );
  };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "operadora.ope_nome", header: "Processadora" },
      { field: "con_nome_fantasia", header: "Convênio" },

    ];
    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={convenios} />
      </React.Fragment>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.con_id}
      </>
    );
  };

  const operadoraBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Processadora</span>
        {rowData.operadora.ope_nome}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Name</span>
        {rowData.con_nome_fantasia}
      </>
    );
  };

  const prazoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Name</span>
        {rowData.con_proposta_dias_aguardo_aceite}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.con_id + "/" + rowData.con_ope_id + "/U";
    let _link_V = _linkSistema_V + rowData.con_id + "/" + rowData.con_ope_id + "/V";
    let _link_Agenda = _linkSistemaAgenda + rowData.con_id + "/" + rowData.con_ope_id;
    let _acesso_U = "adm/convenio/alterar";
    let _acesso_V = "adm/convenio/visualizar";
    let _acesso_Agenda = "adm/agenda/acessar";
    

    return (
      <div className="grid">
        <div className="col-3 md:col-3">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              tooltip={"Alterar"}
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success "
            />
          </Link>
        </div>
        <div className="col-3 md:col-3">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              tooltip={"Visualizar"}
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning"
            />
          </Link>
        </div>
        <div className="col-3 md:col-3">
          <Link to={permissaoAcesso(_acesso_Agenda) && _link_Agenda}>
            <Button
              label={"Corte"}
              icon={permissaoAcesso(_acesso_Agenda) ? "" : "pi pi-lock"}
              tooltip={"Agenda de Corte"}
              className="p-button-rounded p-button-success"
            />
          </Link>
        </div>

      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={convenios}
            selection={selectedConvenios}
            onSelectionChange={(e) => setSelectedConvenios(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="operadora.ope_nome"
              header="Processadora"
              sortable
              body={operadoraBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="con_id"
              header="Id Convênio"
              hidden={true}
              sortable
              body={codeBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="con_razao_social"
              header="Convênio"
              sortable
              body={nameBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>

            <Column
              header="Ações"
              align={"center"}
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "35rem" }}
            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
