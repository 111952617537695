import api from "./Api";
 
export class RelatorioService {

  async comprasListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/lancamentoscompras",
        {
            dataInicial: dataInic,
            dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>   res.data)
  }

  async cartaoListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/lancamentoscartao",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async emprestimoListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/lancamentosemprestimo",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async aplicativoListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/aplicativocadastrado",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async solicitacaoCartaoListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/solicitacaocartao",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
          },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async assinaturasListar(dataInic, dataFinal) {
    return await api
      .post(
        "administrativo/relatorios/assinaturaslistar",
        {
          dataInicial: dataInic,
          dataFinal: dataFinal
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

}
