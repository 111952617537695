import api from "./Api";
 
export class BancoService {
  async getAll() {
    return await api
      .get("administrativo/banco/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
}
