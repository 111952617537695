import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { LogAppService } from "../../service/LogAppService";
import Exportar from "../../components/Exportar";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import formataDataEnviada from "../../components/formataDataEnviada";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
/* Pesquisar */

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Log APP";
  let _linkSistema_V = "/logAppForm_V/";

  const [logApps, setLogApps] = useState(null);
  const [selectedLogApps, setSelectedLogApps] = useState(null);
  const logAppService = new LogAppService();

  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
      setLogApps('')
    }
  }, [retornoDado]);



  const exportCSV = () => {
    dt.current.exportCSV();
  };

  /*  Pesquisar   */
  const formik = useFormik({
    initialValues: {
      dataInicial: "",
      dataFinal: "",
      checaErros: false
    },
    validationSchema: yup.object({
      dataInicial: yup.string().required("O campo é obrigatório."),
      dataFinal: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      logAppService
        .getAll(values.dataInicial, values.dataFinal, values.checaErros)
        .then(
          (data) =>
            data.status === true ? setLogApps(data.dados) : setRetornoDado(data),
          SetLoadingSpinner(false)
        );
    },
  });
  /* Pesquisar */




  const leftToolbarTemplate = () => {
    const cols = [
      { field: "lws_data", header: "Data" },
      { field: "lws_tipo", header: "Tipo" },
      { field: "lws_descricao", header: "Descrição" },
      { field: "lws_sistema", header: "Sistema" },
      { field: "cav_cpf", header: "CPF" },
      { field: "cav_nome", header: "Nome" },
    ];

    return (
      <React.Fragment>
        <form>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataInicial"
                    name="dataInicial"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataInicial}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataInicial && formik.errors.dataInicial,
                    })}
                  />
                  {formik.touched.dataInicial && formik.errors.dataInicial ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataInicial">Data Inicial</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataFinal"
                    name="dataFinal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataFinal}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataFinal && formik.errors.dataFinal,
                    })}
                  />
                  {formik.touched.dataFinal && formik.errors.dataFinal ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataFinal">Data Final</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-12 ">
              <div className="field-checkbox">
                <Checkbox
                  inputId="checaErros"
                  name="checaErros"
                  checked={formik.values.checaErros}
                  onChange={formik.handleChange}
                />
                <label htmlFor="checaErros">Somente Exceções</label>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <Exportar titulo={_header} dt={dt} cols={cols} dadosService={logApps} />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };

  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.lws_id}
      </>
    );
  };
  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.lws_data}
      </>
    );
  };

  const tipoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Tipo</span>
        {rowData.lws_tipo}
      </>
    );
  };
  const descricaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Name</span>
        {rowData.lws_descricao}
      </>
    );
  };

  const sisBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.lws_sistema}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.cav_nome}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const actionBodyTemplate = (rowData) => {
    // let _link_U = _linkSistema + rowData.tco_id + "/U";
    let _link_V = _linkSistema_V + rowData.lws_id + "/V";
    // let _acesso_U = "adm/tipos/convenio/alterar";
    let _acesso_V = "adm/log/logApp/visualizar";
    return (
      <div className="grid">
        {/* <div className="col-6 md:col-6">
          <Link to={!permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-lock" : "pi pi-pencil"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div> */}
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4"
            left={leftToolbarTemplate}>

          </Toolbar>
          <DataTable
            ref={dt}
            value={logApps}
            selection={selectedLogApps}
            onSelectionChange={(e) => setSelectedLogApps(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="lws_id"
              header="Id"
              sortable
              body={idBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="lws_data"
              header="Data"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "15%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="lws_tipo"
              header="Tipo"
              sortable
              body={tipoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="lws_sistema"
              header="Sistema"
              sortable
              body={sisBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="lws_descricao"
              header="Descrição"
              sortable
              body={descricaoBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="CPF"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>
            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
