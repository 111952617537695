import "./_Index.css";

export default function _Index() {
  return (
    <div className="center-form">
      <div className="card p-fluid">
        <img
          src="assets/layout/images/logo.png"
          alt="Logo"
          height="60"
          className="mr-4"
        />
        <h1 className="titulo">Sem Permissão de Acesso</h1>
      </div>
    </div>
  );
}
