import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { AgendaCorteService } from "../../service/AgendaCorteService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";

  let retornoDados = {
    status: null,
    mensagem: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Agenda de Corte";
  let _linkSistema_I = "/agendaCorteForm_I/";
  let _linkSistema_U = "/agendaCorteForm_U/";
  let _linkSistema_V = "/agendaCorteForm_V/";

  const [agendaCortes, setAgendaCortes] = useState(null);
  const [selectedAgendaCortes, setSelectedAgendaCortes] = useState(null);
  const agendaCorteService = new AgendaCorteService();

  let { id_convenio } = useParams();
  let { id_operadora } = useParams();
  const link_voltar = "/convenio";

  useEffect(() => {
    if (retornoDado.status === false) {
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    agendaCorteService
      .getByConvenio(id_convenio, id_operadora)
      .then(
        (data) =>
          data.status === true
            ? setAgendaCortes(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false),
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  //Formata YYYYMM para YYYY/MM
  function formataAnoMes(dataPraF) {
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(4, 6);
    const f = ano + "/" + mes;
    return f;
  }

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(5, 7);
    const dia = dataPraF.toString().slice(8, 10);
    const f = dia + "/" + mes + "/" + ano;
    return f;
  }

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/" + id_convenio + "/" + id_operadora + "/I";
    let _acesso_I = "adm/agenda/incluir";

    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={permissaoAcesso(_acesso_I) && _link_I}>
            <Button
              label="Cadastrar"
              icon="pi pi-plus"
              className="p-button-success mr-2"
            />
          </Link>
          <Link to={link_voltar}>
            <Button type="submit" label="Voltar" className="p-button-raised" />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "agc_id", header: "ID" },
      { field: "agc_ope_id", header: "Processadora" },
      { field: "agc_con_id", header: "Convênio" },
      { field: "agc_ano_mes", header: "Ano Mês" },
      { field: "agc_dia", header: "Dia" },
      { field: "agc_data_inicial", header: "Data Inicial" },
      { field: "agc_data_final", header: "Data Final" },
      { field: "agc_geracao_cartao", header: "Geração Corte" },
    ];
    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={agendaCortes} />
      </React.Fragment>
    );
  };


  const convenioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.con_nome_fantasia}
      </>
    );
  };

  const processadoraBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.ope_nome}
      </>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.agc_id}
      </>
    );
  };

  const anoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataAnoMes(rowData.agc_ano_mes)}
      </>
    );
  };

  const diaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {rowData.agc_dia}
      </>
    );
  };

  const dataInicialTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_data_inicial)}
      </>
    );
  };

  const dataFinalTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_data_final)}
      </>
    );
  };

  const geracaoCartaoTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_geracao_cartao)}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  // --------------- GERAÇÃO DO ARQUIVO DE CORTE .TXT -----------------------

  const gerarDadosTxt = (id_agenda) => {
    SetLoadingSpinner(true);
    agendaCorteService
      .getGerarDadosCorte(id_agenda)
      .then((data) => geraArquivo(data)
      );
   
  };

  const geraArquivo = (dados_) => {

    if (dados_.status) {

      const linkSource = dados_.dados['base64'];
      const downloadLink = document.createElement("a");
      const fileName = dados_.dados['nomearquivo'] + "." + dados_.dados['extensao']

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } 
    SetLoadingSpinner(false);
    toast.current.show({
      severity: dados_.status ? "success" : "error",
      summary: dados_.mensagem,
      life: 3000,
    });

  };

  // -------------------------------------------------------------------------

  const actionBodyTemplate = (rowData) => {

    let _acesso_Gerar = "adm/agenda/visualizar";
    return (
      <div className="grid">

        <div className="col-4 md:col-12">
          <Button
            label={"Gerar TXT"}
            icon={permissaoAcesso(_acesso_Gerar) ? "" : "pi pi-lock"}
            tooltip={"Agenda de Corte"}
            className="p-button-raised p-button-success "
            value={rowData.agc_id}
            onClick={(e) => gerarDadosTxt(rowData.agc_id)}
          />
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={agendaCortes}
            selection={selectedAgendaCortes}
            onSelectionChange={(e) => setSelectedAgendaCortes(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="ope_nome"
              header="Processadora"
              sortable
              body={processadoraBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="con_nome_fantasia"
              header="Convênio"
              sortable
              body={convenioBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="agc_id"
              header="Id"
              hidden={true}
              sortable
              body={codeBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="agc_ano_mes"
              header="Ano/Mês"
              sortable
              body={anoBodyTemplate}
              headerStyle={{ width: "20%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="agc_dia"
              header="Dia Corte"
              sortable
              body={diaBodyTemplate}
              headerStyle={{ width: "20%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="agc_data_inicial"
              header="Data Inicial"
              sortable
              body={dataInicialTemplate}
              headerStyle={{ width: "20%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="agc_data_final"
              header="Data Final"
              sortable
              body={dataFinalTemplate}
              headerStyle={{ width: "20%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="agc_geracao_cartao"
              header="Geração Corte"
              sortable
              body={geracaoCartaoTemplate}
              headerStyle={{ width: "25%", minWidth: "5rem" }}
            ></Column>

          
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
