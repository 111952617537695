import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import formataData from "../../../components/formataData";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CorrespondenteService } from "../../../service/Servidor/CorrespondenteService"

import { InputMask } from "primereact/inputmask";
import Exportar from "../../../components/Exportar";
import { useParams, Link, useHistory } from "react-router-dom";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

/* Pesquisar */

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Empréstimos";
  const [selectedResgate, setSelectedResgate] = useState(null);

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [selectedCompras, setSelectedCompras] = useState(null);
  const relatorioService = new CorrespondenteService();

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .emprestimoListar(
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString()),
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString()),
        ''
      )
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {         
           history.push("/semToken");
      });
  }, [])


  useEffect(() => {
    if (retornoDado.status === false) {

      setCompras(null);
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  /*  Pesquisar   */
  const formik = useFormik({
    initialValues: {
      dataInicial: new Date(Date.now()).toLocaleDateString(),
      dataFinal: new Date(Date.now()).toLocaleDateString(),
    },
    validationSchema: yup.object({
      dataInicial: yup.string().required("O campo é obrigatório."),
      dataFinal: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      relatorioService
        .emprestimoListar(
          formataDataInicialFinal(values.dataInicial),
          formataDataInicialFinal(values.dataFinal),
          values.cnpj
        )
        .then(
          (data) =>
            data.status === true
              ? setCompras(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        )
        .catch((error) => {          
            history.push("/semToken");
        });
    },
  });

  //console.log(formik);
  /* Pesquisar */

  const leftToolbarTemplate = () => {
    const cols = [
      { field: "data", header: "Cadastrado em" },
      { field: "vigencia", header: "Vigência Até" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_matricula", header: "Matrícula" },
      { field: "cav_nome", header: "Nome" },
      { field: "eml_qtdeparcelas", header: "Nº Parcelas" },
      { field: "valor", header: "Valor Averbado" },

      { field: "sml_valor_liquido", header: "Emprestimo Liberado" },
      { field: "valor_liberado", header: "Montante" },
       
      { field: "utilizado_credito", header: "Crédito não utilizado" },
      { field: "status", header: "Status" },
    ];
    return (
      <React.Fragment>
        <form>
          <div className="grid">


            <div className="col-12 md:col-6">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataInicial"
                    name="dataInicial"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataInicial}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataInicial && formik.errors.dataInicial,
                    })}
                  />
                  {formik.touched.dataInicial && formik.errors.dataInicial ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataInicial">Data Inicial</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataFinal"
                    name="dataFinal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataFinal}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataFinal && formik.errors.dataFinal,
                    })}
                  />
                  {formik.touched.dataFinal && formik.errors.dataFinal ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataFinal">Data Final</label>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };


  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.data}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.matricula}
      </>
    );
  };



  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {rowData.valor}
      </>
    );
  };

  const qtdeParcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.eml_qtdeparcelas}
      </>
    );
  };


  const creditoUtilizadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.utilizado_credito}
      </>
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.status}
      </>
    );
  };

  const vigenciaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.vigencia}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cpf}
      </>
    );
  };

  const usuarioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuar'io</span>
        {rowData.usuario}
      </>
    );
  };

  const servidorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuar'io</span>
        {rowData.cav_nome}
      </>
    );
  };

  const servidorCpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const valorLiberadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor Liberado</span>
        {rowData.sml_valor_liquido}
      </>
    );
  };

  const montanteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Montante</span>
        {rowData.valor_liberado}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

          <DataTable
            header={header}
            ref={dt}
            value={compras}
            selection={selectedResgate}
            onSelectionChange={(e) => setSelectedResgate(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 50, 100,200,500]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            responsiveLayout="scroll"
          >

            <Column
              field="utilizado_credito"
              header="Empréstimo"
              sortable
              body={creditoUtilizadoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="data"
              header="Cadastro em"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="vigencia"
              header="Vigência até"
              sortable
              body={vigenciaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={servidorCpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Servidor"
              sortable
              body={servidorBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>

            <Column
              field="eml_qtdeparcelas"
              header="Nº Parcelas"
              sortable
              body={qtdeParcelasBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="valor"
              header="Valor Averbado"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

              <Column
              field="valor"
              header="Valor Liberado"
              sortable
              body={valorLiberadoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
              <Column
              field="valor"
              header="Montante"
              sortable
              body={montanteBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
             
            <Column
              field="usuario"
              header="Usuário"
              sortable
              body={usuarioBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="tra_credito"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
