import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

import { ParametrosService } from "../../service/ParametrosService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const cabecalho_form = "Parâmetros";

  let header = <h5>{cabecalho_form}</h5>;

  let emptyDados = {
    par_mre_id: "",
    par_mtr_id: "",
    par_email_bordero: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [mintransferencia, setMintransferencia] = useState(null);
  const [minresgate, setMinresgate] = useState(null);

  const parametrosService = new ParametrosService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const parametrosService = new ParametrosService();
      parametrosService
        .get()
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });

      SetLoadingSpinner(true);
      parametrosService
        .getMintransferencia()
        .then(
          (data) => setMintransferencia(data.dados),
          SetLoadingSpinner(false)
        );

      SetLoadingSpinner(true);
      parametrosService
        .getMinresgate()
        .then((data) => setMinresgate(data.dados), SetLoadingSpinner(false));
    }
  }, [id]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
     
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      par_mre_id: modelDado.par_mre_id,
      par_mtr_id: modelDado.par_mtr_id,

      par_email_bordero: modelDado.par_email_bordero,

      mre_descricao: modelDado.mre_descricao,
      mtr_descricao: modelDado.mtr_descricao,
    },
    validationSchema: yup.object({
      par_mre_id: yup.string().required("O campo é obrigatório."),
      par_mtr_id: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            parametrosService
              .postAtualizar(
                values.par_mre_id,
                values.par_mtr_id,
                values.par_email_bordero,
                token
              )
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {              
                history.push("/semToken");        
            });
          });
      });
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <div className="grid">
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="par_mre_id"
                  value={formik.values.par_mre_id}
                  onChange={formik.handleChange}
                  options={minresgate}
                  optionLabel="mre_descricao"
                  optionValue="mre_id"
                  placeholder={formik.values.par_mre_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.par_mre_id && formik.errors.par_mre_id,
                  })}
                />
                {formik.touched.par_mre_id && formik.errors.par_mre_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_sta_id}
                  </div>
                ) : null}
                <label htmlFor="est_sta_id">
                  Valor mínimo para servidores realizarem resgate:
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="par_mtr_id"
                  value={formik.values.par_mtr_id}
                  onChange={formik.handleChange}
                  options={mintransferencia}
                  optionLabel="mtr_descricao"
                  optionValue="mtr_id"
                  placeholder={formik.values.par_mtr_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.par_mtr_id && formik.errors.par_mtr_id,
                  })}
                />
                {formik.touched.par_mtr_id && formik.errors.par_mtr_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.par_mtr_id}
                  </div>
                ) : null}
                <label htmlFor="par_mtr_id">
                  Valor mínimo para servidores realizarem transferências:
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="par_email_bordero"
                  name="par_email_bordero"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.par_email_bordero}
                  className={classNames({
                    "p-invalid":
                      formik.touched.par_email_bordero &&
                      formik.errors.par_email_bordero,
                  })}
                />
                {formik.touched.par_email_bordero &&
                formik.errors.par_email_bordero ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.par_email_bordero}
                  </div>
                ) : null}
                <label htmlFor="par_email_bordero">Email</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
