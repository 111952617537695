import api from "./Api";

 
export class DashBoardService {
   
  async getId(ano,mes,cav_ope_id,cav_codigo_convenio,token) {   
    return await api
      .post("administrativo/dashboard/dados", 
      {
        ano : ano,
        mes : mes,
        processadora : cav_ope_id,
        convenio : cav_codigo_convenio,
        tokengoogleo : token
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
}
