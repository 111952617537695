import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";
import formataData from "../../../components/formataData";
import { InputMask } from "primereact/inputmask";
import Exportar from "../../../components/Exportar";
import { useParams, Link, useHistory } from "react-router-dom";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

/* Pesquisar */

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Lançamentos";
  let { id } = useParams();  
  const link_voltar = "/servidorForm_V/" + id + "/V";


  let retornoDados = {
    status: null,
    msg: "",
  };

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [selectedCompras, setSelectedCompras] = useState(null);
  const relatorioService = new CartaoVirtualService();

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }


  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .lancamentosServidor(id)
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      );

  }, []);

  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      setCompras(null);
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  /*  Pesquisar   */
  const formik = useFormik({


    onSubmit: (values) => {
      SetLoadingSpinner(true);
      relatorioService
        .lancamentosServidor(id)
        .then(
          (data) =>
            data.status === true
              ? setCompras(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        );
    },
  });


  const leftToolbarTemplate = () => {
    const cols = [
      { field: "data", header: "Data" },
      { field: "tipo", header: "Tipo" },
      { field: "tipo_estabelecimento", header: "Tipo Estabelecimento" },
      { field: "empresa", header: "Empresa" },
      { field: "servico", header: "Serviço" },
      { field: "valor", header: "Valor" },
      { field: "status", header: "Status" },
    ];

    return (
      <React.Fragment>

        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Link to={link_voltar}>
              <Button type="submit" label="Voltar" className="p-button-raised" />
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
          </div>
        </div>


      </React.Fragment >
    );
  };


  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.data}
      </>
    );
  };

  const tipoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Tipo</span>
        {rowData.tipo}
      </>
    );
  };

  const tipo_estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Tipo estabelecimento</span>
        {rowData.tipo_estabelecimento}
      </>
    );
  };

  const empresaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Empresa</span>
        {rowData.empresa}
      </>
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Estabelecimento</span>
        {rowData.estabelecimento_venda}
      </>
    );
  };

  const servicoBodyTemplate = (rowData) => {
    return (
      <>
        {/* <span className="p-column-title">Servico</span> */}
        {rowData.servico}
      </>
    );
  };


  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {rowData.tra_credito ? rowData.valor : (rowData.valor * -1)}
      </>
    );
  };

  const creditoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Crédito</span>
        {rowData.tra_credito ?(rowData.tra_trt_id == 1 ? "Crédito" : "Estorno") : "Débito"}
      </>
    );
  };



  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.status}
      </>
    );
  };


  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={25}
            rowsPerPageOptions={[50, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="data"
              header="Data"
               
              body={dataBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="empresa"
              header="Empresa"
              sortable
              body={empresaBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="tipo"
              header="Tipo"
              sortable
              body={tipoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="estabelecimento_venda"
              header="Estabelecimento"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
            ></Column>
             <Column             
              field="servico"
              header="Serviço"           
              sortable
              body={servicoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="compra"
              header="Compras"           
              sortable
              body={creditoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column       
            align={"right"}     
              field="valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
           
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
