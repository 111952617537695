import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Funcoes } from "../../utils/funcoes";
import { LiberarMargemService } from "../../service/LiberarMargemService";
import formataData from "../../components/formataData";

const _Index = () => {
  const funcoes = new Funcoes();
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    mensagem: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Liberar Margem";
  let _linkSistema_I = "/liberarMargemForm_I/";
  let _linkSistema_U = "/liberarMargemForm_U/";
  let _linkSistema_V = "/liberarMargemForm_V/";

  const [perfil, setPerfil] = useState(null);

  const [cancelamentos, setCancelamentos] = useState(null);
  const [selectedCancelamentos, setSelectedCancelamentos] = useState(null);

  const liberarMargemService = new LiberarMargemService();

  useEffect(() => {
    if (retornoDado.status === false) {
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    liberarMargemService
      .buscarCancelamentos()
      .then(
        (data) =>
          data.status === true
            ? setCancelamentos(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  const permissaoAcesso = (num) => {
    const accesses = sessionStorage.getItem("acessos");
    const access = accesses.trim().split(",");
    const hasAccess = access.find((item) => item === num);
    return hasAccess === undefined ? false : true;
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2"></div>
      </React.Fragment>
    );
  };

  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {formataData(rowData.cbn_data.slice(0, 19))}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const operadoraBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.ope_nome}
      </>
    );
  };

  const saldoRestanteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {"R$ " + rowData.cbn_saldo}
      </>
    );
  };

  const margemBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor Averbado</span>
        {"R$ " + rowData.cbn_valor_resgatado}
      </>
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {"R$ " + rowData.est_razao_social}
      </>
    );
  };

  const convenioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome convênio</span>
        {rowData.con_nome_fantasia}
      </>
    );
  };

  const canceladoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Margem liberada</span>
        {rowData.efetivado}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.cbn_id + "/U";
    let _acesso_U = "ger/relatorio/liberarmargem/alterar";
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          {(!rowData.cbn_efetivada_devolucao_resgate ?
              <Link to={permissaoAcesso(_acesso_U) && _link_U}>
                <Button
                  label="Editar"
                  icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
                  className="p-button-rounded p-button-success mr-6"
                />
              </Link>
              :
              <></>
            )}
        </div>
      </div>
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={cancelamentos}
            selection={selectedCancelamentos}
            onSelectionChange={(e) => setSelectedCancelamentos(e.value)}
            dataKey="id"
            paginator
            rows={50}
            rowsPerPageOptions={[100, 200, 250]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="cbn_data"
              header="Data"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="ope_nome"
              header="Processadora"
              sortable
              body={operadoraBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="con_nome_fantasia"
              header="Convênio"
              sortable
              body={convenioBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "20%", minWidth: "2rem" }}
            ></Column>
            <Column
              align="right"
              field="cbn_valor_resgatado"
              header="Valor averbado"
              sortable
              body={margemBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cbn_saldo"
              header="Saldo Restante"
              sortable
              body={saldoRestanteBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="efetivado"
              header="Margem liberada"
              sortable
              body={canceladoBodyTemplate}
              headerStyle={{ minWidth: "5rem" }}
            ></Column>
            <Column
              header="Ações"
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "5rem" }}
            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};
const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
