import api from "./Api";

export class SimularService {
  async postSimular(
    operadora,
    convenio,
    cpf,
    matricula,
    dataNascimento,
    tipoSimulacao,
    valor,
    quantidadeParcelas,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/simulacoes/simularSaque",
        {
          cav_ope_id: operadora,
          convenio: convenio,
          cpf: cpf,
          matricula: matricula,
          dataNascimento: dataNascimento,
          tipoSimulacao: tipoSimulacao,
          valor: valor,
          quantidadeParcelas: quantidadeParcelas,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
  async getParcelas(operadora, convenio, tokenGoogleo) {
    return await api
      .post(
        "administrativo/simulacoes/listarParcelas",
        {
          cav_ope_id: operadora,
          convenio: convenio,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
