import api from "./Api";

export class ComprasFacajusService {
  async buscarCompras(ano, mes, ope_id, cod_convenio) {
    return await api
      .post(
        "gerencial/comprasFacajus/listar",
        {
          ope_id: ope_id,
          con_id: cod_convenio,
          ano: ano,
          mes: mes,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "gerencial/comprasFacajus/visualizar",
        { prc_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getStatusParcelas() {
    return await api
      .get("gerencial/comprasFacajus/listarStatusParcelas", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postAtualizar(prc_id, prc_valor, prc_prs_id) {
    return await api
      .post(
        "gerencial/comprasFacajus/atualizar",
        {
          prc_id: prc_id,
          valorPago: prc_valor,
          prc_prs_id: prc_prs_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
