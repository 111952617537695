import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { FeriadosService } from "../../service/FeriadosService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Feriados";
  let _linkSistema_I = "/FeriadosForm_I/";
  let _linkSistema_U = "/FeriadosForm_U/";
  let _linkSistema_V = "/FeriadosForm_V/";

  const [feriados, setFeriados] = useState(null);
  const [selectedFeriados, setSelectedFeriados] = useState(null);
  const feriadosService = new FeriadosService();

  let { id_convenio } = useParams();
  const link_voltar = "/convenio";

  useEffect(() => {
    if (retornoDado.status === false) {
       
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    feriadosService
      .getByConvenio(id_convenio)
      .then(
        (data) =>
          data.status === true ? setFeriados(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF == null || dataPraF == "") {
      return null;
    }
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(5, 7);
    const dia = dataPraF.toString().slice(8, 10);
    const f = dia + "/" + mes + "/" + ano;
    return f;
  }

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/" + id_convenio + "/I";
    let _acesso_I = "adm/feriados/incluir";

    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={permissaoAcesso(_acesso_I) && _link_I}>
            <Button
              label="Cadastrar"
              icon="pi pi-plus"
              className="p-button-success mr-2"
            />
          </Link>
          <Link to={link_voltar}>
            <Button type="submit" label="Voltar" className="p-button-raised" />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const cols = [{ field: "fer_data", header: "ID" }];
    return (
      <React.Fragment>
        <Exportar titulo ={_header}  dt={dt} cols={cols} dadosService={feriados} />
      </React.Fragment>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.fer_id}
      </>
    );
  };

  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.fer_data)}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? true : false;
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.fer_id + "/" + id_convenio + "/U";
    let _link_V = _linkSistema_V + rowData.fer_id + "/" + id_convenio + "/V";

    let _acesso_U = "adm/feriados/alterar";
    let _acesso_V = "adm/feriados/visualizar";

    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={!permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-lock" : "pi pi-pencil"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
        <div className="col-6 md:col-6">
          <Link to={!permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-lock" : "pi pi-eye"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={feriados}
            selection={selectedFeriados}
            onSelectionChange={(e) => setSelectedFeriados(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="fer_data"
              header="Data"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
