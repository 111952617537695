import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";
import formataData from "../../../components/formataData";
import { InputMask } from "primereact/inputmask";
import Exportar from "../../../components/Exportar";
import { useParams, Link, useHistory } from "react-router-dom";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

/* Pesquisar */

const _Index = () => {
  
  const _header = "Assinaturas";
  const history = useHistory();
  const toast = useRef(null);
  const dt = useRef(null);
  let { id } = useParams();  
  const link_voltar = "/servidorForm_V/" + id + "/V";
  const isset = (ref) => typeof ref !== "undefined";

  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });
  const [selectedCompras, setSelectedCompras] = useState(null);

  const relatorioService = new CartaoVirtualService();

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .assinaturaServidor(id)
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      );

  }, []);

  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      setCompras(null);
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  const formik = useFormik({
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      relatorioService
        .lancamentosServidor(id)
        .then(
          (data) =>
            data.status === true
              ? setCompras(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        );
    },
  });

  const leftToolbarTemplate = () => {
    const cols = [
      { field: "est_nome_fantasia", header: "Estabelecimento" },
      { field: "ser_descricao", header: "Plano" },
      { field: "ser_valor", header: "Valor" },
      { field: "trr_qtde_parcelas", header: "Fidelidade" },
      { field: "trr_data_cadastro", header: "Assinado em" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_matricula", header: "Matrícula" },
      { field: "cav_nome", header: "Nome" },
      { field: "tes_descricao", header: "Status" },
      { field: "trr_data_cancelamento", header: "Cancelado em" },
    ];

    return (
      <React.Fragment>

        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Link to={link_voltar}>
              <Button type="submit" label="Voltar" className="p-button-raised" />
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
          </div>
        </div>

      </React.Fragment >
    );
  };

  const actionBodyTemplate = (rowData) => {
    let link_parcelas = "/assinaturasParcelasIndex/" + id + "/" + rowData.trr_id;
    return (
      <div className="grid">
        <div className="col-3 md:col-3">
          <Link to={link_parcelas}>
            <Button
              icon={"pi pi-money-bill"}
              className="p-button-rounded p-button-success "
              tooltip={"Parcelas"}
            />
          </Link>
        </div>        
      </div >
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };

  const planoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.ser_descricao}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor total</span>
        {rowData.ser_valor}
      </>
    );
  };

  const fidelidadeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nº Parcelas</span>
        {rowData.trr_qtde_parcelas} meses
      </>
    );
  };

  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.trr_data_cadastro}
      </>
    );
  };

  const dataCancelamentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cancelamento</span>
        {rowData.trr_data_cancelamento == null ? '---' : rowData.trr_data_cancelamento}

      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.tes_descricao}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 250]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_nome_fantasia"
              header="Estabelecimento"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "8%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="ser_descricao"
              header="Plano"
              sortable
              body={planoBodyTemplate}
              headerStyle={{ width: "12%", minWidth: "12rem" }}
            ></Column>

            <Column
              field="ser_valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "8%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="trr_qtde_parcelas"
              header="Fidelidade"
              sortable
              body={fidelidadeBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="trr_data_cadastro"
              header="Assinado em"
              body={dataBodyTemplate}
              headerStyle={{ width: "12%", minWidth: "13rem" }}
            ></Column>

            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "30%", minWidth: "20rem" }}
            ></Column>

            <Column
              field="tes_descricao"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="trr_data_cancelamento"
              header="Cancelado em"
              sortable
              body={dataCancelamentoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "13rem" }}
            ></Column>

            <Column header="Ações"
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "10rem" }}
            ></Column>

          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
