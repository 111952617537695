import React, { useState, useEffect, useRef, useCallback } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { LogEstatisticoAppService } from "../../service/LogEstatisticoAppService";
import formataData from "../../components/formataData";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import { GraficoLogEstatistico } from "../../components/Graficos/GraficoLogEstatistico";
import Exportar from "../../components/Exportar";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import { GraficoLogEstatisticoApp } from "../../components/Graficos/GraficoLogEstApp";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Log do aplicativo";

  const [anos, setAnos] = useState(null);
  const [meses, setMeses] = useState(null);
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [datAno, setDatAno] = useState("");

  let emptyLog = [
    {
      date: [],
      logsAcesso: null,
      logsFinalizado: null,
    },
  ];

  const [log, setLog] = useState(emptyLog);
  const [data, setData] = useState("");
  const [options, setOptions] = useState("");
  const [value, setValue] = useState("");

  const [dt1, setDt1] = useState("");
  const [dt2, setDt2] = useState("");
  const [dt3, setDt3] = useState("");
  const [dt4, setDt4] = useState("");
  const [dt5, setDt5] = useState("");
  const [dt6, setDt6] = useState("");
  const [dt7, setDt7] = useState("");
  const [dt8, setDt8] = useState("");
  const [dt9, setDt9] = useState("");


  const [logSistema, setLogSistema] = useState(null);
  const [selectedLogSistema, setSelectedLogSistema] = useState(null);
  const logEstatisticoAppService = new LogEstatisticoAppService();

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    // logEstatisticoAppService
    //   .getAll()
    //   .then(
    //     (data) =>
    //       data.status === true
    //         ? setLogSistema(data.dados)
    //         : setRetornoDado(data),
    //     SetLoadingSpinner(false)
    //   );

    logEstatisticoAppService
      .getAnos()
      .then(
        (data) =>
          data.status === true ? setAnos(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      );

    logEstatisticoAppService
      .getMeses()
      .then(
        (data) =>
          data.status === true ? setMeses(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      );
    SetLoadingSpinner(false);
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportGraphic = () => {};

  const formik = useFormik({
    initialValues: {
      ano: "",
      mes: "",
      status: "",
    },
    validationSchema: yup.object({
      ano: yup.string().required("Ano é obrigatório"),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      logEstatisticoAppService
        .buscaLog(values.mes, values.ano)
        .then(
          (data) => (data.status === false ? setRetornoDado(data) : setBusca(data)),
          SetLoadingSpinner(false)
        );
    },
  });

  function setDate(date) {
    switch (date) {
      case "01":
        return "Janeiro";
      case "02":
        return "Fevereiro";

      case "03":
        return "Março";

      case "04":
        return "Abril";

      case "05":
        return "Maio";

      case "06":
        return "Junho";

      case "07":
        return "Julho";

      case "08":
        return "Agosto";

      case "09":
        return "Setembro";

      case "10":
        return "Outubro";

      case "11":
        return "Novembro";

      case "12":
        return "Dezembro";

      default:
        return null;
    }
  }

  function setDateDay(data) {
    let date = data.substr(2, 3) + "/" + data.substr(0, 2);
    return date;
  }

  const setBusca = async (data) => {
    if (data.grafico === "ano") {
      setLog(
        data.dados.map(function (item) {
          return {
            date: setDate(item.dt.substr(4))+ " ( " +((item.finalizado * 100) /item.acesso).toFixed(2)+ " % )",
            solicitarcartaovolus: item.solicitarcartaovolus,
            cancelarcartaovolus: item.cancelarcartaovolus,
            solicitarnovaviavolus: item.solicitarnovaviavolus,
            realizarresgate: item.realizarresgate,
            confirmaremprestimo: item.confirmaremprestimo,
            simularemprestimo: item.simularemprestimo,
            assinaremprestimo: item.assinaremprestimo,
            cadastrarusuario: item.cadastrarusuario,
            realizarlogin: item.realizarlogin,
          };
        })
      );
    } else {
      setLog(
        data.dados.map(function (item) {
          return {
            date: setDateDay(item.dt.substr(4))  ,
            solicitarcartaovolus: item.solicitarcartaovolus,
            cancelarcartaovolus: item.cancelarcartaovolus,
            solicitarnovaviavolus: item.solicitarnovaviavolus,
            realizarresgate: item.realizarresgate,
            confirmaremprestimo: item.confirmaremprestimo,
            simularemprestimo: item.simularemprestimo,
            assinaremprestimo: item.assinaremprestimo,
            cadastrarusuario: item.cadastrarusuario,
            realizarlogin: item.realizarlogin,
          };
        })
      );
    }
    setLogSistema(data.grid);
    setDatAno(data.ano);
  };

  useEffect(() => {
    var dates = log.map(function (key) {
      return key.date;
    });

    var logDeSolicitarcartaovolus = log.map(function (key) {
      return key.solicitarcartaovolus;
    });

    var logDecancelarcartaovolus = log.map(function (key) {
      return key.cancelarcartaovolus;
    });

    var logDesolicitarnovaviavolus = log.map(function (key) {
      return key.solicitarnovaviavolus;
    });

    var logDerealizarresgate = log.map(function (key) {
      return key.realizarresgate;
    });

    var logDeconfirmaremprestimo = log.map(function (key) {
      return key.confirmaremprestimo;
    });

    var logDesimularemprestimo = log.map(function (key) {
      return key.simularemprestimo;
    });

    var logDeassinaremprestimo = log.map(function (key) {
      return key.assinaremprestimo;
    });

    var logDecadastrarusuario = log.map(function (key) {
      return key.cadastrarusuario;
    });

    var logDerealizarlogin = log.map(function (key) {
      return key.realizarlogin;
    });


    setData(dates);

    setDt1({"title": 'Cartões Solicitados', "value":logDeSolicitarcartaovolus})
    setDt2({"title": 'Cartões Cancelados', "value":logDecancelarcartaovolus})
    setDt3({"title": 'Cartões Nova Via', "value":logDesolicitarnovaviavolus})
    setDt4({"title": 'Resgates Realizados', "value":logDerealizarresgate})
    setDt5({"title": 'Empréstimos Confirmados', "value":logDeconfirmaremprestimo})
    setDt6({"title": 'Empréstimos Simulados', "value":logDesimularemprestimo})
    setDt7({"title": 'Empréstimos Assinados', "value":logDeassinaremprestimo})
    setDt8({"title": 'Cadastros de usuário', "value":logDecadastrarusuario})
    setDt9({"title": 'Logins Realizados', "value":logDerealizarlogin})

  }, [log]);

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "lge_data", header: "Data" },
      { field: "est_razao_social", header: "Estabelecimento" },
      { field: "lge_descricao", header: "Descrição" },
      { field: "cav_nome", header: "Usuário" },
      { field: "lge_matricula", header: "Matrícula" },
    ];
    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={logSistema} />
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="grid">
          <div className="col-12 md:col-5">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="mes"
                  value={formik.values.mes}
                  onChange={formik.handleChange}
                  style={{ width: 200 }}
                  options={meses}
                  optionLabel="uf"
                  optionValue="id"
                  placeholder={formik.values.mes}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid": formik.touched.mes && formik.errors.mes,
                  })}
                />
                {formik.touched.mes && formik.errors.mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.mes}
                  </div>
                ) : null}
                <label htmlFor="met_mes">Mês</label>
              </span>
            </div>
          </div>

          <div className="col-8 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="ano"
                  value={formik.values.ano}
                  onChange={formik.handleChange}
                  options={anos}
                  style={{ width: 200 }}
                  optionLabel="id"
                  optionValue="id"
                  placeholder={formik.values.ano}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid": formik.touched.ano && formik.errors.ano,
                  })}
                />
                {formik.touched.ano && formik.errors.ano ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ano}
                  </div>
                ) : null}
                <label htmlFor="ano">Ano</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-1">
            <div className="field">
              <span className="p-float-label mt-5">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.lge_id}
      </>
    );
  };
  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.lge_data}
      </>
    );
  };
  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Estabelecimento</span>
        {rowData.est_razao_social}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descrição</span>
        {rowData.lge_descricao}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const acaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Ação</span>
        {rowData.lge_matricula}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <div className="card" id="graphic">
            <div className="col-12" id="graphic">
              <GraficoLogEstatisticoApp
                labels={data}
                data1={dt1}
                data2={dt2}
                data3={dt3}
                data4={dt4}
                data5={dt5}
                data6={dt6}
                data7={dt7}
                data8={dt8}
                data9={dt9}
                ano={datAno}
              />
            </div>
            <div id="screenshot"></div>
          </div>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
