import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { TipoEstabelecimentoService } from "../../service/TipoEstabelecimentoService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Tipo de Empresas Parceiras";
  let _linkSistema_I = "/tipoEstabelecimentoForm_I/";
  let _linkSistema_U = "/tipoEstabelecimentoForm_U/";
  let _linkSistema_V = "/tipoEstabelecimentoForm_V/";

  const [tipoEstabelecimentos, setTipoEstabelecimentos] = useState(null);
  const [selectedTipoEstabelecimentos, setSelectedTipoEstabelecimentos] =
    useState(null);
  const tipoEstabelecimentoService = new TipoEstabelecimentoService();

  useEffect(() => {
    if (retornoDado.status === false) {
      
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    tipoEstabelecimentoService
      .getAll()
      .then(
        (data) =>
          data.status === true
            ? setTipoEstabelecimentos(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });
  }, []);

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/I";
    let _acesso = "adm/tipos/estabelecimento/incluir";
    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={permissaoAcesso(_acesso) && _link_I}>
            <Button
              label="Cadastrar"
              icon={permissaoAcesso(_acesso) ? "pi pi-plus" : "pi pi-lock"}
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const rightToolbarTemplate = () => {
    const cols = [{ field: "tco_descricao", header: "Informação" }];
    return (
      <React.Fragment>
        <Exportar titulo ={_header}  dt={dt} cols={cols} dadosService={tipoEstabelecimentos} />
      </React.Fragment>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.tco_id}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descrição</span>
        {rowData.tco_descricao}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.tco_id + "/U";
    let _link_V = _linkSistema_V + rowData.tco_id + "/V";
    let _acesso_U = "adm/tipos/estabelecimento/alterar";
    let _acesso_V = "adm/tipos/estabelecimento/visualizar";
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={tipoEstabelecimentos}
            selection={selectedTipoEstabelecimentos}
            onSelectionChange={(e) => setSelectedTipoEstabelecimentos(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="tco_id"
              header="Id"
              sortable
              body={codeBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="tco_descricao"
              header="Descrição"
              sortable
              body={nameBodyTemplate}
              headerStyle={{ width: "100%", minWidth: "10rem" }}
            ></Column>
            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
