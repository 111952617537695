import api from "./Api";
 
export class MetasService {
  async getAll() {
    return await api
      .get("administrativo/metas-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  async getById(id) {
    return await api
      .post(
        "/metas-id",
        { met_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
  async getAnos() {
    return await api
      .get("/anos-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  async getMeses() {
    return await api
      .get("/meses-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  async postAtualizar(met_id, met_ano, met_mes, met_valor, met_est_id, token) {
    return await api
      .post(
        "/metas-atualizar",
        {
          met_id: met_id,
          met_ano: met_ano,
          met_mes: met_mes,
          met_valor: met_valor,
          met_est_id: met_est_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
  async postCadastrar(met_ano, met_mes, met_valor, met_est_id, token) {
    return await api
      .post(
        "/metas-cadastrar",
        {
          met_ano: met_ano,
          met_mes: met_mes,
          met_valor: met_valor,
          met_est_id: met_est_id,
          token: token,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
