import React, { useState, useEffect, useRef } from "react";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import { useSessionStorage } from "./storage/UseSessionStorage";
import Login from "./pages/Login/Login";
import LoginReset from "./pages/Login/LoginReset";
import AppMain from "./AppMain";
import AppMainPrisma from "./AppMainPrisma";
import AppMainFacaJus from "./AppMainFacaJus";
import AppMainFacaSeguros from "./AppMainFacaSeguros";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';


const App = () => {
  const [token, setToken] = useState(null);
  const [logado, setLogado] = useState(sessionStorage.getItem('logado'))
  const [empresa, setEmpresa] = useState(sessionStorage.getItem('empresa_id'))

  const AutenticaLogin = (token) => {
    setToken(token)
    setLogado(sessionStorage.getItem('logado'))
    return true
  }

  if (logado) {

    if (sessionStorage.getItem('empresa_id') == 1) {
      return <AppMain></AppMain>
    } else if (sessionStorage.getItem('empresa_id') == 2) {
      return <AppMainPrisma></AppMainPrisma>
    } else if (sessionStorage.getItem('empresa_id') == 3) {
      return <AppMainFacaJus></AppMainFacaJus>
    } else if (sessionStorage.getItem('empresa_id') == 4) {
      return <AppMainFacaSeguros></AppMainFacaSeguros>
    }
  }

  return (

    <Router>
      <Switch>
        <Route path="/Login">
          <Login setToken={AutenticaLogin}></Login>
        </Route>
        <Route path="/LoginReset/:nome/:id/:tokenReset" component={LoginReset} />
        <Redirect to="/Login" />;
      </Switch>

    </Router >

  );
};

export default App;

