import api from "./Api";
 
export class OperadoraService {
  async getAll() {
    return await api
      .get("administrativo/operadora/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

 

  async getByOperadoraConvenio(ope_id, con_id) {
    return await api
      .post(
        "administrativo/operadora/pesquisarOperadoraConvenio",
        {
          ope_id: ope_id,
          con_id: con_id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

}
