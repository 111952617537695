import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SegurosService } from "../../service/SegurosService";
import { useFormik } from "formik";
import * as yup from "yup";
import formataData from "../../components/formataData";
import formataDataSegurosI from "../../components/formataDataSeguroI";
import formataCpf from "../../components/formataCpf";
import { Dialog } from 'primereact/dialog';

import Exportar from "../../components/Exportar";
import { triggerBase64Download } from "common-base64-downloader-react";

import { InputMask } from "primereact/inputmask";


import classNames from "classnames";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Seguros";

  let _linkSistema_I = "/SegurosSulAmericaForm_I/";
  let _linkSistema_V = "/SegurosSulAmericaForm_V/";
  const [seguros, setSeguros] = useState(null);
  const [selectedSeguros, setSelectedSeguros] = useState(null);
  const segurosService = new SegurosService();

  const [IdRowGrid, setIdRowGrid] = useState();

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }


  useEffect(() => {
    SetLoadingSpinner(true);
    segurosService
      .getAll(
        new Date(Date.now()).toLocaleDateString(),
        new Date(Date.now()).toLocaleDateString()
      )
      .then(
        (data) => {
          if (data.status === true) {
            setSeguros(data.dados)
          } else {
            setSeguros("")
          }
        },
        SetLoadingSpinner(false)
      );
  }, [retornoDado]);





  useEffect(() => {
    if (retornoDado.status !== null) {
      SetLoadingSpinner(false)

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 5000,
      });
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      dataInicial: new Date(Date.now()).toLocaleDateString(),
      dataFinal: new Date(Date.now()).toLocaleDateString(),
      status: "",
    },
    validationSchema: yup.object({
      dataInicial: yup.string().required("O campo é obrigatório."),
      dataFinal: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      segurosService
        .getAll(
          values.dataInicial,
          values.dataFinal,
        )
        .then(
          (data) => {
            if (data.status === true) {
              setSeguros(data.dados)
            } else {
              setSeguros("")
              setRetornoDado(data)
            }
          },
          SetLoadingSpinner(false)
        )
        .catch((error) => {
          history.push("/semToken");
        });
    },
  });



  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const leftToolbarTemplate = () => {

    let _link_I = _linkSistema_I + "0/I";
    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={_link_I}>
            <Button
              label="Cadastrar"
              icon="pi pi-plus"
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const pesquisaTemplate = () => {
    return (
      <React.Fragment>
        <form>
          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataInicial"
                    name="dataInicial"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataInicial}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataInicial && formik.errors.dataInicial,
                    })}
                  />
                  {formik.touched.dataInicial && formik.errors.dataInicial ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataInicial">Data Inicial</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-4 ml-8">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataFinal"
                    name="dataFinal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataFinal}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataFinal && formik.errors.dataFinal,
                    })}
                  />
                  {formik.touched.dataFinal && formik.errors.dataFinal ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataFinal">Data Final</label>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "seg_vigencia_inicio", header: "Data Inicial" },
      { field: "seg_vigencia_fim", header: "Data Final" },
      { field: "seg_cpf", header: "CPF" },
      { field: "seg_nome", header: "Nome" },
      { field: "seg_numoperacao", header: "Num. Operação" },
      { field: "seg_numapolice", header: "Num. Apólice" },
      { field: "usu_login", header: "Usuário" },
      { field: "seg_email_data", header: "E-mail enviado em:" },
      { field: "vigencia", header: "Status" },
    ];
    return (
      <Exportar titulo={_header} dt={dt} cols={cols} dadosService={seguros} />
    );
  };

  const IdBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.seg_id}
      </>
    );
  };

  const EmailEnviadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Email</span>
        {rowData.seg_email_enviado}
      </>
    );
  };

  const EmailDataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">EmailData</span>
        {rowData.seg_email_data}
      </>
    );
  };

  const CpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CPF</span>
        {formataCpf(rowData.seg_cpf)}
      </>
    );
  };

  const NomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.seg_nome}
      </>
    );
  };

  const DataNascimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Nascimento</span>
        {formataDataSegurosI(rowData.seg_data_nascimento)}
      </>
    );
  };

  const SexoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sexo</span>
        {rowData.seg_sexo}
      </>
    );
  };

  const EmailBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">E-mail</span>
        {rowData.seg_email}
      </>
    );
  };

  const NumOperacaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nº Operação</span>
        {rowData.seg_numoperacao}
      </>
    );
  };

  const ValorPremioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor do Premio</span>
        {rowData.seg_valorpremio}
      </>
    );
  };

  const VigenciaInicioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Inicial</span>
        {rowData.seg_vigencia_inicio}
      </>
    );
  };

  const VigenciaFimBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Final</span>
        {rowData.seg_vigencia_fim}
      </>
    );
  };

  const PlanoIdBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id do plano</span>
        {rowData.seg_plano_id}
      </>
    );
  };

  const NumeroOperacaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Número de operação</span>
        {rowData.seg_numoperacao}
      </>
    );
  };

  const NumeroApoliceBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Número de Apólice</span>
        {rowData.seg_numapolice}
      </>
    );
  };

  const VigenciaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.vigencia}
      </>
    );
  };

  const UsuarioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuario</span>
        {rowData.usu_login}
      </>
    );
  };

  const UsuarioCancelouBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuario</span>
        {rowData.usucancelou}
      </>
    );
  };

  const DataCancelouBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cancelado</span>
        {rowData.seg_cancelamento_data}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const actionBodyTemplate = (rowData) => {
    let _link_V = _linkSistema_V + rowData.seg_id + "/V";
    let _acesso_C = "ger/seguros/SulAmerica/cancelar";
    let _acesso_V = "ger/seguros/SulAmerica/visualizar";
    let id = rowData.seg_id;
    return (
      <div className="grid">
        <div className="col-6 md:col-4">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mr-7 ml-4"
            />
          </Link>
        </div>
        {rowData.vigencia === 'VIGENTE' && (
          <div className="col-2 md:col-4">
            <Button
              icon={permissaoAcesso(_acesso_C) ? "pi pi-trash" : "pi pi-lock"}
              className="p-button-rounded p-button-danger mr-7 ml-4"
              tooltip="Cancelar Seguro"
              onClick={() => { permissaoAcesso(_acesso_C) && cancelarSeguro(id) }}
            />
          </div>
        )}
        {rowData.vigencia === 'VIGENTE' && (
          <div className="col-4 md:col-4">
            <Button
              icon="pi-file"
              tooltip="Enviar Apólice"
              className="p-button-rounded p-button-field mr-7 ml-4"
              onClick={() => baixarDocumento(id)}
            />
          </div>
        )}
      </div>
    );
  };

  const baixarDocumento = (id, nomeDoc) => {
    SetLoadingSpinner(true);
    segurosService
      .baixarPdf(id)
      .then(
        (data) =>
          setRetornoDado(data),
      )
      .catch((error) => {
        history.push("/semToken");
      });
  };

  const cancelarSeguro = (id) => {
    setIdRowGrid(id);
    setDeleteSeguroDialog(true);
  };

  const handleSearchInput = (content) => {
    const string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  const [deleteSeguroDialog, setDeleteSeguroDialog] = useState(false);
  const hideDeleteSeguroDialog = () => {
    setDeleteSeguroDialog(false);
  };

  const deleteSeguro = () => {
    SetLoadingSpinner(true);
    segurosService
      .cancelarSeguro(IdRowGrid)
      .then(
        (data) => setRetornoDado(data),

        setDeleteSeguroDialog(false)
      );

  };

  const deleteSeguroDialogFooter = (
    <>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSeguroDialog} />
      <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSeguro} />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={pesquisaTemplate}
          ></Toolbar>
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={seguros}
            selection={selectedSeguros}
            onSelectionChange={(e) => setSelectedSeguros(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >

            <Column
              field="seg_vigencia_inicio"
              header="Cadastro em "
              sortable
              body={VigenciaInicioBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_vigencia_fim"
              header="Vigência até "
              sortable
              body={VigenciaFimBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_cpf"
              header="CPF"
              sortable
              body={CpfBodyTemplate}
              headerStyle={{ width: "15%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_nome"
              header="Nome"
              sortable
              body={NomeBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_numoperacao"
              header="Nº Operação"
              sortable
              body={NumeroOperacaoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_numapolice"
              header="Nº Apólice"
              sortable
              body={NumeroApoliceBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_login"
              header="Usuário"
              sortable
              body={UsuarioBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usucancelou"
              header="Usuário Cancelou"
              sortable
              body={UsuarioCancelouBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_cancelamento_data"
              header="Data Cancelou"
              sortable
              body={DataCancelouBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_email_enviado"
              header="E-Mail "
              sortable
              body={EmailEnviadoBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="seg_email_data"
              header="Data de Envio"
              sortable
              body={EmailDataBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="vigencia"
              header="Status"
              sortable
              body={VigenciaBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>

        <Dialog visible={deleteSeguroDialog} style={{ width: '450px' }} header="Confirmação"
          modal footer={deleteSeguroDialogFooter} onHide={hideDeleteSeguroDialog}>
          <div className="flex align-items-center justify-content-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            <span>
              Você têm certeza deste cancelamento ?
            </span>

          </div>
        </Dialog>


      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
