import api from "./Api";

export class LogAppService {
  async getAll(dataInicial, dataFinal, checaErros) {
    return await api
      .post("administrativo/log-app-listar",
        {
          dataInicio: dataInicial,
          dataFinal: dataFinal,
          checaErros: checaErros
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "/log-app-id",
        { lws_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
