import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Dropdown } from "primereact/dropdown";

import Acessos from "../../components/Acessos/Acessos";
import { ComprasFacajusService } from "../../service/ComprasFacajusService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [tipoAcesso, setTipoAcesso] = useState(false);
  const [acessosPerfil, setAcessosPerfil] = useState(0);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/comprasfacajus";
  const cabecalho_form = "Compras Faça Jus";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    prc_id: null,
    prc_valor: null,
    prc_prs_id: null,
    prc_data_parcela: null,
    prc_numero: null,
    prc_trr_id: null,
  };

  let retornoDados = {
    status: null,
    mensagem: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const comprasFacajusService = new ComprasFacajusService();

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "U") {
        // SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);

      comprasFacajusService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {
          history.push("/semToken");
        });
    }
  }, [id, tipo]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
      SetLoadingSpinner(false);
    }
  }, [retornoDado]);

  const [statusParcelas, setStatusParcelas] = useState();

  useEffect(() => {
    SetLoadingSpinner(true);
    comprasFacajusService
      .getStatusParcelas()
      .then((data) => setStatusParcelas(data.dados), SetLoadingSpinner(false));
  }, []);

  const formik = useFormik({
    initialValues: {
      prc_id: modelDado.prc_id,
      prc_valor: modelDado.prc_valor,
      prc_prs_id: modelDado.prc_prs_id,
    },
    validationSchema: yup.object({
      prc_id: yup.string().required("O campo é obrigatório."),
      prc_valor: yup.string().required("O campo é obrigatório."),
      prc_prs_id: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            comprasFacajusService
              .postAtualizar(values.prc_id, values.prc_valor, values.prc_prs_id)
              .then((data) => setRetornoDado(data))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });
    },
  });

  const handleChangeDecimal = (e) => {
    const { name, value } = e.target;
    const formattedValue = handleDecimalsOnValue(value);
    formik.setFieldValue(name, formattedValue);
  };

  function handleDecimalsOnValue(value) {
    // Remove todos os caracteres que não são dígitos
    const sanitizedValue = value.replace(/[^0-9]/g, "");

    // Verifica se o valor está vazio ou não é um número válido
    if (!sanitizedValue) {
      return "";
    }

    // Divide o valor em parte inteira e parte decimal
    const integerPart = sanitizedValue.slice(0, -2);
    const decimalPart = sanitizedValue.slice(-2);

    // Combine a parte inteira e a parte decimal com uma vírgula
    const resultValue = `${integerPart},${decimalPart}`;

    return resultValue;
  }

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <div className="grid">
          <div className="col-12 md:col-12 ">
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="prc_prs_id"
                  value={formik.values.prc_prs_id}
                  onChange={formik.handleChange}
                  options={statusParcelas}
                  optionLabel="prs_descricao"
                  optionValue="prs_id"
                  placeholder={formik.values.prc_prs_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.prc_prs_id && formik.errors.prc_prs_id,
                  })}
                />
                {formik.touched.prc_prs_id && formik.errors.prc_prs_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.prc_prs_id}
                  </div>
                ) : null}
                <label htmlFor="prc_prs_id">Status da parcela</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  id="prc_valor"
                  name="prc_valor"
                  disabled={campoHabilitado}
                  maxLength={8}
                  type="text"
                  value={formik.values.prc_valor}
                  onChange={handleChangeDecimal}
                />

                {formik.touched.prc_valor && formik.errors.prc_valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.prc_valor}
                  </div>
                ) : null}
                <label htmlFor="prc_valor">Digite o valor pago *</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
