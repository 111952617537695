import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from 'primereact/dialog';
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { UsuariosService } from "../../service/UsuariosService";
import SelectEst from '../../components/SelecionarEstabelecimento/SelectEst';
import { PerfilService } from "../../service/PerfilService";
import Teclado from "../../components/Teclado/Teclado";

import { MetasService } from "../../service/MetasService";



const _Form = () => {
  const history = useHistory();
	const isset = (ref) => typeof ref !== 'undefined'
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [estSelecionado, SetEstSelecinado] = useState(null);
  const [login, setLogin] = useState(emptyDados);
  const [tipoUsu, setTipoUsu] = useState(null);

  const toggle = (event) => {
    op.current.toggle(event);
  };
  const op = useRef(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/metas";
  const cabecalho_form = "Metas";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

   

      /**
   *------------ TECLADO -----------
  */
  const [tecladoDialog, setTecladoDialog] = useState(false);
  const openNew = () => {
    setTecladoDialog(true);
  }
  const tecladoHide = () => {
    setTecladoDialog(false);
  }

  const tecladoClose = () => {
    setTecladoDialog(false);
  };

  const tecladoPassword = num => {
    formik.values.usu_senha = num
    setTecladoDialog(false);
  };

  /**
   *--------- FIM TECLADO -----------
  */


  let emptyDados = {
    met_id:"",
    met_ano:"",
    met_mes:"",
    met_valor:"",
    est_razao_social:" ",
    met_est_id:"",
    mes:""
    };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados); 
  const [retornoDado, setRetornoDado] = useState(retornoDados);

  const [usuariosTipo, setUsuariosTipo] = useState(null);

  const[perfil, setPerfil] = useState(null);

  //const metasService = new MetasService();
  const metasService = new MetasService();

  const [anos, setAnos] = useState(null);
  const [meses, setMeses] = useState(null);



  useEffect(() => {
    SetLoadingSpinner(true);
    metasService
      .getAnos()
      .then((data) => setAnos(data), SetLoadingSpinner(false));

      SetLoadingSpinner(true);
    metasService
      .getMeses()
      .then((data) => setMeses(data), SetLoadingSpinner(false));
  }, []);


  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const metasService = new MetasService();
      metasService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });

    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
    console.log(modelDado)
  }, [modelDado]);

  useEffect(() => {
    toast.current.show({
      severity:"success",
      summary: "Estabelecimento selecionado",
      life: 3000,})
  }, [estSelecionado]);
  
  useEffect(() => {
    if (retornoDado.status !== null) {
      if (isset(retornoDado.code)) {
        history.push('semToken');
      }
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  
    const selectEst = est_id => {
    setModelDado(formik.values)
    SetEstSelecinado(est_id);
    return true
  }


  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      met_id: modelDado.met_id,
      met_ano: modelDado.met_ano,
      met_mes: modelDado.met_mes,
      met_valor: modelDado.met_valor,
      est_razao_social: modelDado.est_razao_social,
      met_est_id: estSelecionado == null ? modelDado.met_est_id: estSelecionado.id,
      mes:modelDado.mes
    },

    validationSchema: yup.object({
      met_ano: yup.string().required("O campo é obrigatório."),
      met_mes: yup.string().required("O campo é obrigatório."),
      met_est_id: yup.string().required("O campo é obrigatório."),
      met_valor: yup.number("Deve ser um número").min(0, "Valor de antecipação deve ser maior que 0").max(1000000,"Valor deve ser menor").required("O campo é obrigatório."),

    }),
    
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        metasService
          .postCadastrar(
            values.met_ano,
            values.met_mes,
            values.met_valor,
            values.met_est_id,
            )
          .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
          .catch((error) => {              
            history.push("/semToken");        
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              metasService
                .postAtualizar(
                  values.met_id,
                  values.met_ano,
                  values.met_mes,
                  values.met_valor,
                  values.met_est_id,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );


return (
  
  <div className="card p-fluid">
    <Toast ref={toast} />
    <form>
      {header}
      {buttonHeader}
      <h5>Dados Cadastrais</h5>
      
        <div className="grid">
        <div className="col-12 md:col-6">
            <div className="p-inputgroup">
            <span className="p-float-label  mt-5">
              <Button type="button" label="Selecione" onClick={toggle} className="p-button-sm" />
                <OverlayPanel ref={op} appendTo={document.body} showCloseIcon>
                    <SelectEst
                      onSelect={selectEst}
                    >
                    </SelectEst>
                </OverlayPanel>
                <InputText
                  disabled={true}
                  type="text"
                  id="met_est_id"
                  name="met_est_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={estSelecionado == null? formik.values.est_razao_social : estSelecionado.razaoSocial }
                  className={classNames({
                    "p-invalid":
                      formik.touched.met_est_id &&
                      formik.errors.met_est_id,
                  })}
                />

                <label htmlFor="met_est_id">Estabelecimento do Usuário</label>
            </span>
            </div>
            {formik.touched.met_est_id && formik.errors.met_est_id ? (
                    <div style={{ fontSize: 10, color: "red", marginLeft:"21%"}}>
                      {formik.errors.met_est_id}
                    </div>
                  ) : null}
          </div>

          <div className="col-12 md:col-3">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <Dropdown 
                    id="met_mes" 
                    value={formik.values.met_mes} 
                    onChange={formik.handleChange} 
                    options={meses} 
                    optionLabel="uf" 
                    optionValue="id" 
                    placeholder={formik.values.mes}
                    onBlur={formik.handleBlur}
                    disabled={campoHabilitado}
                    className={classNames({
                      "p-invalid":
                        formik.touched.met_mes &&
                        formik.errors.met_mes,
                    })}
                  />
                  {formik.touched.met_mes && formik.errors.met_mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.met_mes}
                  </div>
                  ) : null}
                  <label htmlFor="met_mes">Mês</label>
                </span>
              </div>
            </div>


            <div className="col-12 md:col-3">
            <div className="field">
            <span className="p-float-label  mt-5">
                  <Dropdown 
                    id="met_ano" 
                    value={formik.values.met_ano} 
                    onChange={formik.handleChange} 
                    options={anos} 
                    optionLabel="id" 
                    optionValue="id" 
                    placeholder={formik.values.met_ano}
                    onBlur={formik.handleBlur}
                    disabled={campoHabilitado}
                    className={classNames({
                      "p-invalid":
                        formik.touched.met_ano &&
                        formik.errors.met_ano,
                    })}
                  />
                  {formik.touched.met_ano && formik.errors.met_ano ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.met_ano}
                  </div>
                  ) : null}
                  <label htmlFor="met_ano">Ano</label>
            </span>
            </div>
          </div>

        <div className="col-12 md:col-4">
            <div className="field">
            <span className="p-float-label mt-5 p-input-icon-right">
              <i className="pi pi-dollar" />                   
              <InputNumber
                disabled={campoHabilitado}
                prefix="R$ "
                value={formik.values.met_valor} 
                onValueChange={formik.handleChange} 
                mode="decimal" 
                locale="de-DE" 
                id="met_valor"
                name="met_valor"
                currencyDisplay="code"
                maxFractionDigits={2}
                className={classNames({
                  "p-invalid":
                    formik.touched.met_valor &&
                    formik.errors.met_valor,
                })}
                /> 
                  {formik.touched.met_valor && formik.errors.met_valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.met_valor}
                  </div>
                ) : null}
                <label htmlFor="met_valor">Valor (R$)</label>
              </span>
            </div>
          </div>          
        </div>

        <Dialog visible={tecladoDialog} onHide={tecladoHide} >
            <Teclado maxDigitos={6} tecladoClose={tecladoClose} tecladoPassword={tecladoPassword} />
        </Dialog>

      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
