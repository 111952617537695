import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { UsuariosService } from "../../service/UsuariosService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Usuários";
  let _linkSistema_I = "/usuariosForm_I/";
  let _linkSistema_U = "/usuariosForm_U/";
  let _linkSistema_V = "/usuariosForm_V/";

  const [usuarios, setUsuarios] = useState(null);
  const [selectedUsuarios, setSelectedUsuarios] = useState(null);
  const usuariosService = new UsuariosService();

  useEffect(() => {
    if (retornoDado.status === false) {

      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    usuariosService
      .getAll()
      .then(
        (data) =>
         data.status === true ? setUsuarios(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/I";
    let _acesso_I = "adm/usuarios/incluir";

    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={permissaoAcesso(_acesso_I) && _link_I}>
            <Button
              label="Cadastrar"
              icon={permissaoAcesso(_acesso_I) ? "pi pi-plus" : "pi pi-lock"}
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "est_razao_social", header: "Estabelecimento" },
      { field: "usu_cpf", header: "Cpf" },
      { field: "usu_nome", header: "Nome" },
      { field: "usu_login", header: "Login" },
      { field: "usu_ativo", header: "Status" },
      { field: "per_nome", header: "Perfil" },
    ];
    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={usuarios} />
      </React.Fragment>
    );
  };

  const EmpresaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Empresa</span>
        {rowData.usuario_tipo.ust_descricao}
      </>
    );
  };

  const PerfilBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Perfil</span>
        {rowData.perfil.per_nome}
      </>
    );
  };

  const CpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CPF</span>
        {rowData.usu_cpf}
      </>
    );
  };

  const NomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.usu_nome}
      </>
    );
  };

  const LoginBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Login</span>
        {rowData.usu_login}
      </>
    );
  };

  const StatusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.usu_ativo ? "ATIVO" : "INATIVO"}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const actionBodyTemplate = (rowData) => {

    let _link_U = _linkSistema_U + rowData.usu_id + "/U";
    let _link_V = _linkSistema_V + rowData.usu_id + "/V";

    let _acesso_U = "adm/usuarios/alterar";
    let _acesso_V = "adm/usuarios/visualizar";

    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={usuarios}
            selection={selectedUsuarios}
            onSelectionChange={(e) => setSelectedUsuarios(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="usuario_tipo.ust_descricao"
              header="Empresa"
              sortable
              body={EmpresaBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_cpf"
              header="CPF"
              sortable
              body={CpfBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_nome"
              header="Nome"
              sortable
              body={NomeBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_login"
              header="Login"
              sortable
              body={LoginBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_ativo"
              header="Status"
              sortable
              body={StatusBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="perfil.per_nome"
              header="Perfil"
              sortable
              body={PerfilBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
