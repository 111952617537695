import api from "./Api";
 
export class ParametrosService {
  async get() {
    return await api
      .get("administrativo/parametros-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async postAtualizar(par_mre_id, par_mtr_id, par_email_bordero) {
    return await api
      .post(
        "/parametros-atualizar",
        {
          par_mre_id: par_mre_id,
          par_mtr_id: par_mtr_id,
          par_email_bordero: par_email_bordero,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async getMintransferencia() {
    return await api
      .get("/mintransferencia-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async getMinresgate() {
    return await api
      .get("/minresgate-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
}
