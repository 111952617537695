import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../components/LoadingSpinner";
import { RelatorioService } from "../../service/RelatorioService";
import { InputMask } from "primereact/inputmask";
import Exportar from "../../components/Exportar";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Assinaturas";

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [selectedCompras, setSelectedCompras] = useState(null);
  const relatorioService = new RelatorioService();

  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .assinaturasListar(
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString()),
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString())
      )
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });

  }, []);

  useEffect(() => {
    if (retornoDado.status === false) {
      setCompras('');
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  /*  Pesquisar   */
  const formik = useFormik({
    initialValues: {
      dataInicial: new Date(Date.now()).toLocaleDateString(),
      dataFinal: new Date(Date.now()).toLocaleDateString(),
    },
    validationSchema: yup.object({
      dataInicial: yup.string().required("O campo é obrigatório."),
      dataFinal: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      relatorioService
        .assinaturasListar(
          formataDataInicialFinal(values.dataInicial),
          formataDataInicialFinal(values.dataFinal)
        )
        .then(
          (data) =>
            data.status === true
              ? setCompras(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        )
        .catch((error) => {
          console.log(error)
          history.push("/semToken");
        });
    },
  });

  const leftToolbarTemplate = () => {
    const cols = [
      { field: "est_nome_fantasia", header: "Estabelecimento" },
      { field: "ser_descricao", header: "Plano" },
      { field: "trr_data_cadastro", header: "Assinado em" },
      { field: "ser_valor", header: "Valor" },
      { field: "trr_qtde_parcelas", header: "Fidelidade" },
      { field: "trr_data_fim_recorrencia", header: "Expira em" },
      { field: "trr_data_cancelamento", header: "Cancelado em" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_matricula", header: "Matrícula" },
      { field: "cav_nome", header: "Nome" },
      { field: "tes_descricao", header: "Status" },
    ];

    return (
      <React.Fragment>
        <form>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataInicial"
                    name="dataInicial"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataInicial}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataInicial && formik.errors.dataInicial,
                    })}
                  />
                  {formik.touched.dataInicial && formik.errors.dataInicial ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataInicial">Data Inicial</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataFinal"
                    name="dataFinal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataFinal}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataFinal && formik.errors.dataFinal,
                    })}
                  />
                  {formik.touched.dataFinal && formik.errors.dataFinal ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataFinal">Data Final</label>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Estabelecimento</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };

  const planoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Plano</span>
        {rowData.ser_descricao}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {rowData.ser_valor}
      </>
    );
  };

  const dataBodyTemplate = (rowData) => {

    const dataInicio = rowData.trr_data_cadastro.split(' ')[0].split('-');
    const dia = dataInicio[2];
    const mes = dataInicio[1];
    const ano = dataInicio[0];

    return (
      <>
        <span className="p-column-title">Data</span>
        {dia}/{mes}/{ano}
      </>
    );
  };

  const dataCancelamentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cancelamento</span>
        {rowData.trr_data_cancelamento == null ? '---' : rowData.trr_data_cancelamento}

      </>
    );
  };

  const parcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nº Parcelas</span>
        {rowData.trr_qtde_parcelas} meses
      </>
    );
  };

  const expiraBodyTemplate = (rowData) => {

    const dataFim = rowData.trr_data_fim_recorrencia.split(' ')[0].split('-');
    const dia = dataFim[2];
    const mes = dataFim[1];
    const ano = dataFim[0];

    return (
      <>
        <span className="p-column-title">Nº Parcelas</span>
        {dia}/{mes}/{ano}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.tes_descricao}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 250]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >

            <Column
              field="est_nome_fantasia"
              header="Estabelecimento"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="ser_descricao"
              header="Plano"
              sortable
              body={planoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "12rem" }}
            ></Column>

            <Column
              field="trr_data_cadastro"
              header="Assinado em"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "12rem" }}
            ></Column>

            <Column
              field="ser_valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
            ></Column>

            <Column
              field="trr_qtde_parcelas"
              header="Fidelidade"
              sortable
              body={parcelasBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="trr_data_fim_recorrencia"
              header="Expira em"
              sortable
              body={expiraBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "12rem" }}
            ></Column>

            <Column
              field="trr_data_cancelamento"
              header="Cancelado em"
              sortable
              body={dataCancelamentoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "30%", minWidth: "20rem" }}
            ></Column>

            <Column
              field="tes_descricao"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "8%", minWidth: "8rem" }}
            ></Column>

          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);