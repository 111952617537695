import api from "./Api";

export class SegurosService {


  async getAll(dataInicio, dataFinal,tokenGoogleo) {
    return await api
      .post(
        "administrativo/seguro/listar",
        {
          dataInicial: dataInicio,
          dataFinal: dataFinal,
          tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
  async getById(id) {
    return await api
      .post(
        "administrativo/seguro/visualizar",
        { id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getParametros() {
    return await api
      .post(
        "/seguros-parametros-entrada",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async  postCadastrar(uf, cpf, nome, dataNascimento, sexo, email, cep, rua, numero, complemento, bairro, cid_id, celular, tokenGoogleo) {
    return await api.post("administrativo/seguro/cadastrar", {
      uf: uf,
      cpf: cpf,
      nome: nome,
      dataNascimento: dataNascimento,
      sexo: sexo,
      email: email,
      cep: cep,
      rua: rua,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cid_id: cid_id,
      celular: celular,
      tokenGoogleo: tokenGoogleo,

    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data);
  }

  async baixarPdf(id) {
    return await api.post("administrativo/seguro/apolice", {
      id: id
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data);
  }

  async cancelarSeguro(id) {
    return await api.post("administrativo/seguro/cancelar-apolice", {
        id: id
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data);
  }


}
