import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputTextarea } from "primereact/inputtextarea";

import { LogSistemaService } from "../../service/LogSistemaService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/logSistema";
  const cabecalho_form = "Log do Sistema";

  let header = (
    // tipo === "I" ? (
    //   <h5>{cabecalho_form} - Cadastrar</h5>
    // ) : tipo === "U" ? (
    //   <h5>{cabecalho_form} - Alterar</h5>
    // ) : (
    <h5>{cabecalho_form} - Visualizar</h5>
  );

  let emptyDados = {
    log_id: "",
    log_data_cadastro: "",
    usu_cpf: "",
    usu_nome: "",
    est_cnpj: "",
    est_razao_social: "",
    log_model: "",
    log_action: "",
    log_idmodel: "",
    log_descricao: "",
    log_ip: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const logSistemaService = new LogSistemaService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const logSistemaService = new LogSistemaService();
      logSistemaService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false));
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      log_id: modelDado.log_id,
      log_data_cadastro: modelDado.log_data_cadastro,
      usu_cpf: modelDado.usu_cpf,
      usu_nome: modelDado.usu_nome,
      est_cnpj: modelDado.est_cnpj,
      est_razao_social: modelDado.est_razao_social,
      log_model: modelDado.log_model,
      log_action: modelDado.log_action,
      log_idmodel: modelDado.log_idmodel,
      log_descricao: modelDado.log_descricao,
      log_ip: modelDado.log_ip,
    },
  });

  const buttonHeader = (
    <div className="grid">
      {/* {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )} */}

      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      {header}
      {buttonHeader}
      <div className="col-12">
        <div className="card">
          <div className="p-fluid formgrid grid">
            <span className="p-float-label mt-5 lg:col-3">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="log_data_cadastro"
                name="log_data_cadastro"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_data_cadastro}
              />
              <label htmlFor="log_data_cadastro">Data e hora</label>
            </span>

            <span className="p-float-label mt-5 lg:col-3">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="est_cnpj"
                name="est_cnpj"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.est_cnpj}
              />
              <label htmlFor="est_cnpj">CNPJ</label>
            </span>

            <span className="p-float-label mt-5 lg:col-6">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="est_razao_social"
                name="est_razao_social"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.est_razao_social}
              />
              <label htmlFor="est_razao_social">Estabelecimento</label>
            </span>

            <span className="p-float-label mt-5 lg:col-2">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="usu_cpf"
                name="usu_cpf"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.usu_cpf}
              />
              <label htmlFor="usu_cpf">CPF</label>
            </span>

            <span className="p-float-label mt-5 lg:col-4">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="usu_nome"
                name="usu_nome"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.usu_nome}
              />
              <label htmlFor="usu_nome">Usuário</label>
            </span>

            <span className="p-float-label mt-5 lg:col-3">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="log_model"
                name="log_model"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_model}
              />
              <label htmlFor="log_model">Módulo</label>
            </span>

            <span className="p-float-label mt-5 lg:col-3">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="log_action"
                name="log_action"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_action}
              />
              <label htmlFor="log_model">Ação</label>
            </span>

            <span className="p-float-label mt-5 lg:col-6">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="log_idmodel"
                name="log_idmodel"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_idmodel}
              />
              <label htmlFor="log_model">N° Registro</label>
            </span>

            <span className="p-float-label mt-5 lg:col-6">
              <InputText
                disabled={campoHabilitado}
                type="text"
                id="log_ip"
                name="log_ip"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_ip}
              />
              <label htmlFor="log_model">Ip</label>
            </span>

            <span className="p-float-label mt-5 lg:col-12">
              <InputTextarea
                disabled={campoHabilitado}
                type="text"
                id="log_descricao"
                name="log_descricao"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.log_descricao}
              />
              <label htmlFor="log_model">Descrição</label>
            </span>
          </div>
        </div>
      </div>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
