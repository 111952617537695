import api from "./Api";
 
export class EstabelecimentosService {
  async getEmpresasAll() {
    return await api
      .get("administrativo/estabelecimento/listar-empresas", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  // Somente Processadoras
  async getProcessadorasAll() {
    return await api
      .get("administrativo/estabelecimento/listar-processadoras", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }


  async getCargoAll() {
    return await api
      .get("administrativo/estabelecimento/listarcargo", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }


  async   getById(id) {
    return await api
      .post(
        "administrativo/estabelecimento/visualizar",
        { id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postAtualizar(
    id,
    est_cnpj,
    est_razao_social,
    est_nome_fantasia,
    est_sta_id,
    est_tco_id,
    est_resp_nome,
    est_resp_email,
    est_resp_celular,     
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/estabelecimento/atualizar",
        {
          est_id: id,
          est_cnpj:est_cnpj,
          est_razao_social:est_razao_social,
          est_nome_fantasia:est_nome_fantasia,
          est_sta_id:est_sta_id,
          est_tco_id:est_tco_id,
          est_resp_nome:est_resp_nome,
          est_resp_email:est_resp_email,
          est_resp_celular:est_resp_celular,                      
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postCadastrar(     
    est_cnpj,
    est_razao_social,
    est_nome_fantasia,
    est_sta_id,
    est_tco_id,
    est_resp_nome,
    est_resp_email,
    est_resp_celular,        
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/estabelecimento/cadastrar",
        {
          est_cnpj:est_cnpj,
          est_razao_social:est_razao_social,
          est_nome_fantasia:est_nome_fantasia,
          est_sta_id:est_sta_id,
          est_tco_id:est_tco_id,
          est_resp_nome:est_resp_nome,
          est_resp_email:est_resp_email,
          est_resp_celular:est_resp_celular,                      
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data) ;     
  }
}
