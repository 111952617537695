import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { TipoEstabelecimentoService } from "../../service/TipoEstabelecimentoService";
import { EstabelecimentosService } from "../../service/EstabelecimentosService";
import { StatusService } from "../../service/StatusService";
import { EstabelecimentoTipoService } from "../../service/EstabelecimentoTipoService"; 
 

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/estabelecimentos";
  const cabecalho_form = "Empresas Parceiras";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    est_id : "",
    est_cnpj: "",
    est_razao_social: "",
    est_nome_fantasia: "",
    est_sta_id : "",
    est_tco_id: "",
    est_resp_nome : "",
    est_resp_email : "",
    est_resp_celular : ""
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState([]);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const estabelecimentosService = new EstabelecimentosService();

  const [tipoConvenios, setTipoConvenios] = useState(null);
  const tipoConvenioService = new TipoEstabelecimentoService();

  const [status, setStatus] = useState(null);
  const statusService = new StatusService();

  const [estabelecimentoTipo, setEstabelecimentoTipo] = useState(null);
  const estabelecimentoTipoService = new EstabelecimentoTipoService();

   
 

  useEffect(() => {
    SetLoadingSpinner(true);
    statusService
      .getAll()
      .then((data) => setStatus(data.dados), SetLoadingSpinner(false));

    SetLoadingSpinner(true);
    tipoConvenioService
      .getAll()
      .then((data) => setTipoConvenios(data.dados), SetLoadingSpinner(false));

    SetLoadingSpinner(true);
    estabelecimentoTipoService
      .getAll()
      .then(
        (data) => setEstabelecimentoTipo(data.dados),
        SetLoadingSpinner(false)
      ); 
  
  }, []);


  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);      
      estabelecimentosService
        .getById(id)
        .then((data) =>           
        setModelDado(data.dados),                  
        SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });          
    }  
          
  }, []);

  useEffect(() => {        
     formik.setValues( modelDado);       
  }, [modelDado]);



  
  useEffect(() => {
    if (retornoDado.status !== null) {
           toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  

  const formik = useFormik({
    
    initialValues: {
      est_id : modelDado.est_id ,
      est_cnpj:  modelDado.est_cnpj  ,
      est_razao_social: modelDado.est_razao_social,
      est_nome_fantasia:modelDado.est_nome_fantasia,
      est_sta_id:modelDado.est_sta_id ,
      est_tco_id:modelDado.est_tco_id ,
      est_resp_nome:modelDado.est_resp_nome ,
      est_resp_email:modelDado.est_resp_email ,
      est_resp_celular:modelDado.est_resp_celular,      
    },

    validationSchema: yup.object({
      est_razao_social: yup.string().required("O campo é obrigatório."),      
      est_tco_id: yup.string().required("O campo é obrigatório"),
      est_cnpj: yup.string().required("O campo é obrigatório"),
      est_nome_fantasia: yup.string().required("O campo é obrigatório"),
      est_sta_id: yup.string().required("O campo é obrigatório"),
      est_resp_nome: yup.string().required("O campo é obrigatório."),
      est_resp_email: yup
        .string()
        .required("O campo é obrigatório.")
        .email("Deve ser um e-mail"),
      est_resp_celular: yup.number().required("O campo é obrigatório."),   
      
    }),

    
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        estabelecimentosService
          .postCadastrar(
            values.est_cnpj,
            values.est_razao_social,
            values.est_nome_fantasia,
            values.est_sta_id,
            values.est_tco_id,
            values.est_resp_nome,
            values.est_resp_email,
            values.est_resp_celular
        
          )
          .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
          .catch((error) => {              
            history.push("/semToken");        
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              estabelecimentosService
                .postAtualizar(
                  values.est_id,
                  values.est_cnpj,
                  values.est_razao_social,
                  values.est_nome_fantasia,
                  values.est_sta_id,
                  values.est_tco_id,
                  values.est_resp_nome,
                  values.est_resp_email,
                  values.est_resp_celular ,          
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      }
    },
  });
  

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="est_tco_id"
                  value={formik.values.est_tco_id}
                  onChange={formik.handleChange}
                  options={tipoConvenios}
                  optionLabel="tco_descricao"
                  optionValue="tco_id"
                  placeholder={formik.values.tco_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_tco_id && formik.errors.est_tco_id,
                  })}
                />
                {formik.touched.est_tco_id && formik.errors.est_tco_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_tco_id}
                  </div>
                ) : null}
                <label htmlFor="est_tco_id">Tipo</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="99.999.999/9999-99"
                  unmask={true}
                  disabled={tipo === "U" || tipo === "V" ? true : false}
                  type="text"
                  id="est_cnpj"
                  name="est_cnpj"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.est_cnpj}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_cnpj && formik.errors.est_cnpj,
                  })}
                />
                {formik.touched.est_cnpj && formik.errors.est_cnpj ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_cnpj}
                  </div>
                ) : null}
                <label htmlFor="est_cnpj">CNPJ</label>
              </span>
            </div>
          </div>

        
        

          <div className="col-12 md:col-7">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_nome_fantasia"
                  name="est_nome_fantasia"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.est_nome_fantasia}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_nome_fantasia &&
                      formik.errors.est_nome_fantasia,
                  })}
                />
                {formik.touched.est_nome_fantasia &&
                formik.errors.est_nome_fantasia ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_nome_fantasia}
                  </div>
                ) : null}
                <label htmlFor="est_nome_fantasia">Nome fantasia</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-10">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_razao_social"
                  name="est_razao_social"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.est_razao_social}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_razao_social &&
                      formik.errors.est_razao_social,
                  })}
                />
                {formik.touched.est_razao_social &&
                formik.errors.est_razao_social ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_razao_social}
                  </div>
                ) : null}
                <label htmlFor="est_razao_social">Razão social</label>
              </span>
            </div>
          </div>



          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="est_sta_id"
                  value={formik.values.est_sta_id}
                  onChange={formik.handleChange}
                  options={status}
                  optionLabel="sta_descricao"
                  optionValue="sta_id"
                  placeholder={formik.values.est_sta_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_sta_id && formik.errors.est_sta_id,
                  })}
                />
                {formik.touched.est_sta_id && formik.errors.est_sta_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_sta_id}
                  </div>
                ) : null}
                <label htmlFor="est_sta_id">Status</label>
              </span>
            </div>
          </div>
 
           
        </div>
      </form>

      

        <form>
          <h5>Dados do responsável</h5>
          <div className="grid">
            <div className="col-12 md:col-5">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputText
                    disabled={campoHabilitado}
                    type="text"
                    id="est_resp_nome"
                    name="est_resp_nome"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.est_resp_nome}
                    className={classNames({
                      "p-invalid":
                        formik.touched.est_resp_nome &&
                        formik.errors.est_resp_nome,
                    })}
                  />
                  {formik.touched.est_resp_nome && formik.errors.est_resp_nome ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.est_resp_nome}
                    </div>
                  ) : null}
                  <label htmlFor="est_resp_nome">Nome</label>
                </span>
              </div>
            </div>

          

            <div className="col-12 md:col-4">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputText
                    disabled={campoHabilitado}
                    type="text"
                    id="est_resp_email"
                    name="est_resp_email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.est_resp_email}
                    className={classNames({
                      "p-invalid":
                        formik.touched.est_resp_email &&
                        formik.errors.est_resp_email,
                    })}
                  />
                  {formik.touched.est_resp_email &&
                  formik.errors.est_resp_email ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.est_resp_email}
                    </div>
                  ) : null}
                  <label htmlFor="est_resp_email">E-mail</label>
                </span>
              </div>
            </div>

            <div className="col-12 md:col-3">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask="(99) 99999-9999"
                    unmask={true}
                    disabled={campoHabilitado}
                    type="text"
                    id="est_resp_celular"
                    name="est_resp_celular"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.est_resp_celular}
                    className={classNames({
                      "p-invalid":
                        formik.touched.est_resp_celular &&
                        formik.errors.est_resp_celular,
                    })}
                  />
                  {formik.touched.est_resp_celular &&
                  formik.errors.est_resp_celular ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.est_resp_celular}
                    </div>
                  ) : null}
                  <label htmlFor="est_resp_celular">Celular</label>
                </span>
              </div>
            </div>         
          </div>
        </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
