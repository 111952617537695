import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

import { TipoEstabelecimentoService } from "../../service/TipoEstabelecimentoService";

import Calendar from "react-calendar";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";

  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/TipoEstabelecimento";
  const cabecalho_form = "Tipo de Empresas Parceiras";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    tco_id: null,
    tco_descricao: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const tipoEstabelecimentoService = new TipoEstabelecimentoService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const tipoEstabelecimentoService = new TipoEstabelecimentoService();
      tipoEstabelecimentoService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      tco_id: modelDado.tco_id,
      tco_descricao: modelDado.tco_descricao,
    },
    validationSchema: yup.object({
      tco_descricao: yup
        .string()
        .min(5, ({ min }) => `Descrição deve ter no mínimo ${min} caracteres`)
        .max(50, ({ max }) => `Descrição deve ter no máximo ${max} caracteres`)
        .required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              tipoEstabelecimentoService
                .postCadastrar(values.tco_descricao, token)
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              tipoEstabelecimentoService
                .postAtualizar(values.tco_id, values.tco_descricao, token)
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <br></br>
        <div className="field">
          <span className="p-float-label">
            <InputText
              disabled={campoHabilitado}
              type="text"
              id="tco_descricao"
              name="tco_descricao"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tco_descricao}
              className={classNames({
                "p-invalid":
                  formik.touched.tco_descricao && formik.errors.tco_descricao,
              })}
            />
            {formik.touched.tco_descricao && formik.errors.tco_descricao ? (
              <div style={{ fontSize: 10, color: "red" }}>
                {formik.errors.tco_descricao}
              </div>
            ) : null}
            <label htmlFor="tco_descricao">Descrição</label>
          </span>
        </div>
      </form>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
