import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContratoService } from "../../service/ContratoService";

const _Form = () => {
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const history = useHistory();
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/contrato";
  const cabecalho_form = "Histórico de alterações do contrato";

  let header = <h5>{cabecalho_form} </h5>;

  let emptyDados = {
    ctl_id: null,
    cnt_numero: "",
    cnt_taxa: "",
    cnt_tx_antecipa_1: "",
    cnt_tx_antecipa_2: "",
    cnt_tx_antecipa_3: "",
    cnt_data_contrato_ass: "",
    cnt_data_contrato_venc: "",
    cnt_data_contrato_ini: "",
    cnt_data_contrato_fim: "",
    cnt_valor_reajuste: "",
    cnt_usu_id: "",
    cnt_antecipa_mes: "",
    cnt_antecipa_vlr_minimo: "",
    cnt_antecipa_deposito_dias: "",
    cnt_data_adesao_ini: "",
    cnt_data_adesao_fim: "",
    cnt_adesao_valor: "",
    cnt_cnpj: "",
    cnt_razao_social: "",
    cnt_nome_fantasia: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const contratoService = new ContratoService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      contratoService
        .getLogById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
       
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      cnt_id: modelDado.cnt_id,
      cnt_numero: modelDado.cnt_numero,
      cnt_taxa: modelDado.cnt_taxa,
      cnt_tx_antecipa_1: modelDado.cnt_tx_antecipa_1,
      cnt_tx_antecipa_2: modelDado.cnt_tx_antecipa_2,
      cnt_tx_antecipa_3: modelDado.cnt_tx_antecipa_3,
      cnt_data_contrato_ass: modelDado.cnt_data_contrato_ass,
      cnt_data_contrato_venc: modelDado.cnt_data_contrato_venc,
      cnt_data_contrato_ini: modelDado.cnt_data_adesao_ini,
      cnt_data_contrato_fim: modelDado.cnt_data_contrato_fim,
      cnt_valor_reajuste: modelDado.cnt_valor_reajuste,
      cnt_usu_id: modelDado.cnt_usu_id,
      cnt_antecipa_mes: modelDado.cnt_antecipa_mes,
      cnt_antecipa_vlr_minimo: modelDado.cnt_antecipa_vlr_minimo,
      cnt_antecipa_deposito_dias: modelDado.cnt_antecipa_deposito_dias,
      cnt_data_adesao_ini: modelDado.cnt_data_adesao_ini,
      cnt_data_adesao_fim: modelDado.cnt_data_adesao_fim,
      cnt_adesao_valor: modelDado.cnt_adesao_valor,
      cnt_cnpj: modelDado.cnt_cnpj,
      cnt_razao_social: modelDado.cnt_razao_social,
      cnt_nome_fantasia: modelDado.cnt_nome_fantasia,
    },

    validationSchema: yup.object({}),
  });

  const buttonHeader = (
    <div className="grid">
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="99.999.999/9999-99"
                  unmask={true}
                  disabled={tipo === "U" || tipo === "V" ? true : false}
                  type="text"
                  id="cnt_cnpj"
                  name="cnt_cnpj"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_cnpj}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_cnpj && formik.errors.cnt_cnpj,
                  })}
                />
                {formik.touched.cnt_cnpj && formik.errors.cnt_cnpj ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_cnpj}
                  </div>
                ) : null}
                <label htmlFor="est_cnpj">CNPJ</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_numero"
                  name="cnt_numero"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_numero}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_numero && formik.errors.cnt_numero,
                  })}
                />
                {formik.touched.cnt_numero && formik.errors.cnt_numero ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_numero}
                  </div>
                ) : null}
                <label htmlFor="cnt_numero">Número de contrato</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_razao_social"
                  name="cnt_razao_social"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_razao_social}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_razao_social &&
                      formik.errors.cnt_razao_social,
                  })}
                />
                {formik.touched.cnt_razao_social &&
                formik.errors.cnt_razao_social ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_razao_social}
                  </div>
                ) : null}
                <label htmlFor="cnt_razao_social">Razão social</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-12">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_nome_fantasia"
                  name="cnt_nome_fantasia"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_nome_fantasia}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_nome_fantasia &&
                      formik.errors.cnt_nome_fantasia,
                  })}
                />
                {formik.touched.cnt_nome_fantasia &&
                formik.errors.cnt_nome_fantasia ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_nome_fantasia}
                  </div>
                ) : null}
                <label htmlFor="cnt_nome_fantasia">Nome fantasia</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Período</h5>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_ass"
                  name="cnt_data_contrato_ass"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_ass}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_ass &&
                      formik.errors.cnt_data_contrato_ass,
                  })}
                />
                {formik.touched.cnt_data_contrato_ass &&
                formik.errors.cnt_data_contrato_ass ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_ass}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_ass">Data Assinatura</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_ini"
                  name="cnt_data_contrato_ini"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_ini}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_ini &&
                      formik.errors.cnt_data_contrato_ini,
                  })}
                />
                {formik.touched.cnt_data_contrato_ini &&
                formik.errors.cnt_data_contrato_ini ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_ini}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_ini">Data Início</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_fim"
                  name="cnt_data_contrato_fim"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_fim}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_fim &&
                      formik.errors.cnt_data_contrato_fim,
                  })}
                />
                {formik.touched.cnt_data_contrato_fim &&
                formik.errors.cnt_data_contrato_fim ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_fim}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_fim">Data Fim</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_venc"
                  name="cnt_data_contrato_venc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_venc}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_venc &&
                      formik.errors.cnt_data_contrato_venc,
                  })}
                />
                {formik.touched.cnt_data_contrato_venc &&
                formik.errors.cnt_data_contrato_venc ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_venc}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_venc">Data Vencimento</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />{" "}
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_valor_reajuste"
                  name="cnt_valor_reajuste"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_valor_reajuste}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_valor_reajuste &&
                      formik.errors.cnt_valor_reajuste,
                  })}
                />
                {formik.touched.cnt_valor_reajuste &&
                formik.errors.cnt_valor_reajuste ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_valor_reajuste}
                  </div>
                ) : null}
                <label htmlFor="cnt_valor_reajuste">Reajuste (R$)</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Taxas</h5>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-percentage" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_taxa"
                  name="cnt_taxa"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_taxa}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_taxa && formik.errors.cnt_taxa,
                  })}
                />
                {formik.touched.cnt_taxa && formik.errors.cnt_taxa ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_taxa}
                  </div>
                ) : null}

                <label htmlFor="cnt_taxa">Administrativa %</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-percentage" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_tx_antecipa_1"
                  name="cnt_tx_antecipa_1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_tx_antecipa_1}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_tx_antecipa_1 &&
                      formik.errors.cnt_tx_antecipa_1,
                  })}
                />
                {formik.touched.cnt_tx_antecipa_1 &&
                formik.errors.cnt_tx_antecipa_1 ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_tx_antecipa_1}
                  </div>
                ) : null}

                <label htmlFor="cnt_tx_antecipa_1">Antecipação %</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_adesao_ini"
                  name="cnt_data_adesao_ini"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_adesao_ini}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_adesao_ini &&
                      formik.errors.cnt_data_adesao_ini,
                  })}
                />
                {formik.touched.cnt_data_adesao_ini &&
                formik.errors.cnt_data_adesao_ini ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_adesao_ini}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_adesao_ini">
                  Adesão - Data Início
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_adesao_fim"
                  name="cnt_data_adesao_fim"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_adesao_fim}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_adesao_fim &&
                      formik.errors.cnt_data_adesao_fim,
                  })}
                />
                {formik.touched.cnt_data_adesao_fim &&
                formik.errors.cnt_data_adesao_fim ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_adesao_fim}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_adesao_fim">Adesão - Data Fim</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_adesao_valor"
                  name="cnt_adesao_valor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_adesao_valor}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_adesao_valor &&
                      formik.errors.cnt_adesao_valor,
                  })}
                />
                {formik.touched.cnt_adesao_valor &&
                formik.errors.cnt_adesao_valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_adesao_valor}
                  </div>
                ) : null}
                <label htmlFor="cnt_adesao_valor">Adesão (R$)</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Parâmetros</h5>
        <div className="grid">
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_antecipa_mes"
                  name="cnt_antecipa_mes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_antecipa_mes}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_mes &&
                      formik.errors.cnt_antecipa_mes,
                  })}
                />
                {formik.touched.cnt_antecipa_mes &&
                formik.errors.cnt_antecipa_mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_mes}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_mes">
                  Qtde. Antecipações ao Mês
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_antecipa_vlr_minimo"
                  name="cnt_antecipa_vlr_minimo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_antecipa_vlr_minimo}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_vlr_minimo &&
                      formik.errors.cnt_antecipa_vlr_minimo,
                  })}
                />
                {formik.touched.cnt_antecipa_vlr_minimo &&
                formik.errors.cnt_antecipa_vlr_minimo ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_vlr_minimo}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_vlr_minimo">Adesão (R$)</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_antecipa_deposito_dias"
                  name="cnt_antecipa_deposito_dias"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_antecipa_deposito_dias}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_deposito_dias &&
                      formik.errors.cnt_antecipa_deposito_dias,
                  })}
                />
                {formik.touched.cnt_antecipa_deposito_dias &&
                formik.errors.cnt_antecipa_deposito_dias ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_deposito_dias}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_deposito_dias">
                  Prazo em dias para o depósito
                </label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
