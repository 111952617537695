import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { useFormik } from "formik";
import * as yup from "yup";
import { BrowserRouter as Router, useParams, Link, useHistory, Redirect } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { CidadesService } from "../../service/CidadesService";
import { SegurosService } from "../../service/SegurosService";
import { EstadoService } from "../../service/EstadoService";


const _Form = () => {
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [loadingSpinnerI, SetLoadingSpinnerI] = useState(false);
  const history = useHistory();
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  const [usuId, setUsuId] = useState(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/SegurosSulAmerica";
  const cabecalho_form = "Seguro";

  const sexos = [
    { seg_sexo: 'M' },
    { seg_sexo: 'F' },
  ];

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    seg_id: null,
    seg_est_id: null,
    seg_cpf: "",
    seg_nome: "",
    seg_data_nascimento: "",
    seg_sexo: "",
    seg_email: "",
    seg_celular: "",
    seg_rua: "",
    seg_numero: "",
    seg_complemento: "",
    seg_bairro: "",
    seg_cep: "",
    seg_uf: "",
    seg_cid_id: "",
    seg_valorpremio: "",
    seg_vigencia_inicio: "",
    seg_vigencia_fim: "",
    seg_tipoDocumento: "",
    seg_plano_id: "",
    seg_descricao_plano: "",
    seg_numoperacao: null,
    seg_numapolice: "",
    seg_numcertificado: "",
    seg_numsorteio: "",
    seg_seguro_gerado: "",
    seg_base64_certificado: "",
    seg_base64_cartabv: ""
  };

  const [modelDado, setModelDado] = useState(emptyDados);

  const [globalFilter, setGlobalFilter] = useState(null);

  const segurosService = new SegurosService();
  const [contratosDoc, setContratosDoc] = useState(null);
  const [selectedContratosDoc, setSelectedContratosDoc] = useState(null);

  const [estado, setEstado] = useState(null);
  const estadoService = new EstadoService();

  const [cidades, setCidades] = useState(null);
  const cidadesService = new CidadesService();

  const [bloqueioDuploClick, setBloqueioDuploClick] = useState(false);

   

  useEffect(() => {
    SetLoadingSpinner(true);
    estadoService
      .getAll()
      .then((data) => setEstado(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });


    if (tipo !== "I") {
      SetCampoHabilitado(true); 
      segurosService
        .getById(id)
        .then(
          (data) =>
            data.status === true
              ? setModelDado(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        )
        .catch((error) => {
          history.push("/semToken");
        });

      
    }
  }, [id, tipo]);


  useEffect(() => {
    formik.setValues(modelDado);
  
      cidadesService
        .getByUf(modelDado.seg_uf)
        .then((data) => setCidades(data.dados)
           );

  }, [modelDado]);


  useEffect(() => {
    if (retornoDado.status !== null) {
      SetLoadingSpinner(false)
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 5000      
      });

      if (retornoDado.status) { 
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }

      setBloqueioDuploClick(false);


    }
  }, [retornoDado]);



  //função para formatar a data para mostrar corretamente na mascara
  function formataDataPraMask(dataPraF) {
    if (dataPraF === null || dataPraF === "" || dataPraF === undefined) {
      return null;
    }
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(5, 7);
    const dia = dataPraF.toString().slice(8, 10);
    const f = dia + "/" + mes + "/" + ano;
    return f;
  }

  //função para formatar a data enviada
  function formataDataEnviada(dataPraF) {
    if (dataPraF === null || dataPraF === "" || dataPraF === undefined) {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(2, 4);
    const ano = dataPraF.toString().slice(4, 8);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  function buscaCidades() {
     
    cidadesService
      .getByUf(formik.values.seg_uf)
      .then((data) => setCidades(data.dados), 
        );
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      seg_id: modelDado.seg_id,
      seg_est_id: modelDado.seg_est_id,
      seg_cpf: modelDado.seg_cpf,
      seg_nome: modelDado.seg_nome,
      seg_data_nascimento: modelDado.seg_data_nascimento,
      seg_sexo: modelDado.seg_sexo,
      seg_email: modelDado.seg_email,
      seg_celular: modelDado.seg_celular,
      seg_rua: modelDado.seg_rua,
      seg_numero: modelDado.seg_numero,
      seg_complemento: modelDado.seg_complemento,
      seg_bairro: modelDado.seg_bairro,
      seg_cep: modelDado.seg_cep,
      seg_uf: modelDado.seg_uf,//=== ""? modelDado.seg_uf : modelDado.estado.est_nome,
      seg_cid_id: modelDado.seg_cid_id, // === "" ? modelDado.seg_cid_id : modelDado.cid_nome,     
      seg_valorpremio: modelDado.seg_valorpremio,
      seg_vigencia_inicio: modelDado.seg_vigencia_inicio,
      seg_vigencia_fim: modelDado.seg_vigencia_fim,
      seg_tipoDocumento: modelDado.seg_tipoDocumento,
      seg_plano_id: modelDado.seg_plano_id,
      seg_descricao_plano: modelDado.seg_descricao_plano,
      seg_numoperacao: modelDado.seg_numoperacao,
      seg_numapolice: modelDado.seg_numapolice,
      seg_numcertificado: modelDado.seg_numcertificado,
      seg_numsorteio: modelDado.seg_numsorteio,
      seg_seguro_gerado: modelDado.seg_seguro_gerado,
      seg_base64_certificado: modelDado.seg_base64_certificado,
      seg_base64_cartabv: modelDado.seg_base64_cartabv,
    },

    validationSchema: yup.object({
      seg_nome: yup
        .string()
        .required("O campo é obrigatório.")
        .max(60, "Deve ter menos que 60 dígitos"),
      seg_cpf: yup
        .string()
        .required("O campo é obrigatório."),
      seg_data_nascimento: yup
        .string()
        .required("O campo é obrigatório."),
        seg_sexo: yup
        .string()
        .required("O campo é obrigatório."),
      seg_email: yup
        .string()
        .email("Deve ser um e-mail")
        .required("O campo é obrigatório."),
      
      seg_celular: yup
        .string()
        .required("O campo é obrigatório."),
      seg_rua: yup
        .string()
        .required("O campo é obrigatório.")
        .max(60, "Deve ter menos que 60 dígitos"),
      seg_numero: yup
        .number("O campo deve ser um número")
        .required("O campo é obrigatório")
        .positive("O número deve ser positivo")
        .integer("O número deve ser inteiro")
        .max(10000, "Número deve ser menor que 10000")
        .typeError("Número inválido"),
      seg_complemento: yup
        .string()
        .max(60, "Deve ter menos que 60 dígitos")
        .required("O campo é obrigatório"),
      seg_bairro: yup
        .string()
        .required("O campo é obrigatório.")
        .max(60, "Deve ter menos que 60 dígitos"),
      seg_cep: yup
        .string()
        .required("O campo é obrigatório."),
      seg_uf: yup
        .string()
        .required("O campo é obrigatório."),
      seg_cid_id: yup
        .string()
        .required("O campo é obrigatório."),
    }),



    
    onSubmit: (values) => {      
      if (tipo === "I") {
        SetLoadingSpinner(true);    
        setBloqueioDuploClick(true);
            
          segurosService
          .postCadastrar(
            values.seg_uf,
            values.seg_cpf,
            values.seg_nome,
            formataDataEnviada(values.seg_data_nascimento),
            values.seg_sexo,
            values.seg_email,
            values.seg_cep,
            values.seg_rua,
            values.seg_numero,
            values.seg_complemento,
            values.seg_bairro,
            values.seg_cid_id,
            values.seg_celular,
          )
          .then((data) =>           
            setRetornoDado(data),          
          )
          .catch((error) => {
            history.push("/semToken");
          });

          
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            disabled={bloqueioDuploClick}
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );




  return (
    <div className="card p-fluid">
      <Toast ref={toast}/>
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_nome"
                  name="seg_nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_nome}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_nome && formik.errors.seg_nome,
                  })}
                />
                {formik.touched.seg_nome && formik.errors.seg_nome ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_nome}
                  </div>
                ) : null}
                <label htmlFor="seg_nome">Nome</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="999.999.999-99"
                  unmask={true}
                  disabled={tipo === "U" || tipo === "V" ? true : false}
                  type="text"
                  id="seg_cpf"
                  name="seg_cpf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_cpf}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_cpf && formik.errors.seg_cpf,
                  })}
                />
                {formik.touched.seg_cpf && formik.errors.seg_cpf ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_cpf}
                  </div>
                ) : null}
                <label htmlFor="seg_cpf">CPF</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  unmask={true}
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_data_nascimento"
                  name="seg_data_nascimento"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={tipo === "I" ? (formik.values.seg_data_nascimento) : formataDataPraMask(formik.values.seg_data_nascimento)}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_data_nascimento &&
                      formik.errors.seg_data_nascimento,
                  })}
                />
                {formik.touched.seg_data_nascimento && formik.errors.seg_data_nascimento ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_data_nascimento}
                  </div>
                ) : null}
                <label htmlFor="seg_data_nascimento">Data de Nascimento</label>
              </span>
            </div>
          </div>


          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <Dropdown
                  id="seg_sexo"
                  disabled={campoHabilitado}
                  value={formik.values.seg_sexo}
                  onChange={formik.handleChange}
                  options={sexos}
                  optionLabel="seg_sexo"
                  optionValue="seg_sexo"
                  placeholder={formik.values.seg_sexo}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_sexo &&
                      formik.errors.seg_sexo,
                  })}
                />
                {formik.touched.seg_sexo &&
                  formik.errors.seg_sexo ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_sexo}
                  </div>
                ) : null}
                <label htmlFor="seg_sexo">Sexo</label>
              </span>
            </div>
          </div>


          <div className="col-12 md:col-5">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_email"
                  name="seg_email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_email}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_email &&
                      formik.errors.seg_email,
                  })}
                />
                {formik.touched.seg_email &&
                  formik.errors.seg_email ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_email}
                  </div>
                ) : null}
                <label htmlFor="seg_email">Email</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-5">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask="(99)99999-9999"
                  unmask="true"
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_celular"
                  name="seg_celular"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_celular}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_celular &&
                      formik.errors.seg_celular,
                  })}
                />
                {formik.touched.seg_celular &&
                  formik.errors.seg_celular ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_celular}
                  </div>
                ) : null}
                <label htmlFor="seg_celular">Celular</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_rua"
                  name="seg_rua"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_rua}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_rua &&
                      formik.errors.seg_rua,
                  })}
                />
                {formik.touched.seg_rua &&
                  formik.errors.seg_rua ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_rua}
                  </div>
                ) : null}
                <label htmlFor="seg_rua">Rua</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_numero"
                  name="seg_numero"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_numero}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_numero &&
                      formik.errors.seg_numero,
                  })}
                />
                {formik.touched.seg_numero &&
                  formik.errors.seg_numero ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_numero}
                  </div>
                ) : null}
                <label htmlFor="seg_numero">N°</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_complemento"
                  name="seg_complemento"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_complemento}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_complemento &&
                      formik.errors.seg_complemento,
                  })}
                />
                {formik.touched.seg_complemento &&
                  formik.errors.seg_complemento ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_complemento}
                  </div>
                ) : null}
                <label htmlFor="seg_complemento">Complemento</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_bairro"
                  name="seg_bairro"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_bairro}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_bairro &&
                      formik.errors.seg_bairro,
                  })}
                />
                {formik.touched.seg_bairro &&
                  formik.errors.seg_bairro ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_bairro}
                  </div>
                ) : null}
                <label htmlFor="seg_bairro">Bairro</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask="99999-999"
                  unmask="true"
                  disabled={campoHabilitado}
                  type="text"
                  id="seg_cep"
                  name="seg_cep"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seg_cep}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_cep &&
                      formik.errors.seg_cep,
                  })}
                />
                {formik.touched.seg_cep &&
                  formik.errors.seg_cep ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_cep}
                  </div>
                ) : null}
                <label htmlFor="seg_cep">CEP</label>
              </span>
            </div>
          </div>


          {/* renderizacao para mostrar ou não combo de estado e cidade */}



          
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <span className="p-float-label mt-5">
                        <Dropdown
                          id="seg_uf"
                          value={formik.values.seg_uf}
                          disabled={campoHabilitado}
                          onChange={formik.handleChange}
                          onHide={buscaCidades}
                          options={estado}
                          optionLabel="est_nome"
                          optionValue="est_id"
                          placeholder={formik.values.est_uf}
                          onBlur={formik.handleBlur}
                          className={classNames({
                            "p-invalid":
                              formik.touched.cid_estado_est_id &&
                              formik.errors.cid_estado_est_id,
                          })}
                        />
                        {formik.touched.cid_estado_est_id &&
                          formik.errors.cid_estado_est_id ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.cid_estado_est_id}
                          </div>
                        ) : null}
                        <label htmlFor="cid_estado_est_id">UF</label>
                      </span>
                    </div>
                  </div>







                  <div className="col-12 md:col-4">
                    <div className="field">
                      <span className="p-float-label  mt-5">
                        <Dropdown
                          id="seg_cid_id"
                          value={formik.values.seg_cid_id}
                          onChange={formik.handleChange}
                          options={cidades}
                          optionLabel="cid_nome"
                          optionValue="cid_id"
                          placeholder={
                            (formik.values.cid_id === ""
                              ? "Selecione"
                              : formik.values.cid_nome)
                          }
                          disabled={campoHabilitado}
                          emptyMessage={"UF deve ser selecionado"}
                          className={classNames({
                            "p-invalid":
                              formik.touched.seg_cid_id && formik.errors.seg_cid_id,
                          })}
                        />
                        {formik.touched.seg_cid_id && formik.errors.seg_cid_id ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.seg_cid_id}
                          </div>
                        ) : null}
                        <label htmlFor="seg_cid_id">Cidade</label>
                      </span>
                    </div>
                  </div>


         
       
           
         
        </div>
      </form>

      <form>
        <h5>Dados do seguro</h5>
        <div className="grid">

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={true}
                  type="text"
                  id="seg_vigencia_inicio"
                  name="seg_vigencia_inicio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formataDataPraMask(formik.values.seg_vigencia_inicio)}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_vigencia_inicio &&
                      formik.errors.seg_vigencia_inicio,
                  })}
                />
                {formik.touched.seg_vigencia_inicio && formik.errors.seg_vigencia_inicio ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_vigencia_inicio}
                  </div>
                ) : null}
                <label htmlFor="seg_vigencia_inicio">Data Início</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={true}
                  type="text"
                  id="seg_vigencia_fim"
                  name="seg_vigencia_fim"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formataDataPraMask(formik.values.seg_vigencia_fim)}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_vigencia_fim &&
                      formik.errors.seg_vigencia_fim,
                  })}
                />
                {formik.touched.seg_vigencia_fim && formik.errors.seg_vigencia_fim ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_vigencia_fim}
                  </div>
                ) : null}
                <label htmlFor="seg_vigencia_fim">Data Fim</label>
              </span>
            </div>
          </div>


          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={true}
                  type="text"
                  id="seg_valorpremio"
                  name="seg_valorpremio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={"R$: " + formik.values.seg_valorpremio}
                  className={classNames({
                    "p-invalid":
                      formik.touched.seg_valorpremio &&
                      formik.errors.seg_valorpremio,
                  })}
                />
                {formik.touched.seg_valorpremio &&
                  formik.errors.seg_valorpremio ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.seg_valorpremio}
                  </div>
                ) : null}
                <label htmlFor="seg_valorpremio">Valor do Prêmio</label>
              </span>
            </div>
          </div>





          {/* 
      renderizacao  condicional para mostrar campos gerados pela sulamerica apenas apos a geracao do seguro
      */}
          {(() => {
            if (tipo === "I") {
              return <div></div>;
            } else {
              return (
                <>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label  mt-5">
                        <InputText
                          disabled={true}
                          type="text"
                          id="seg_numoperacao"
                          name="seg_numoperacao"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.seg_numoperacao}
                          className={classNames({
                            "p-invalid":
                              formik.touched.seg_numoperacao &&
                              formik.errors.seg_numoperacao,
                          })}
                        />
                        {formik.touched.seg_numoperacao &&
                          formik.errors.seg_numoperacao ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.seg_numoperacao}
                          </div>
                        ) : null}
                        <label htmlFor="seg_numoperacao">N° Operação</label>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label  mt-5">
                        <InputText
                          disabled={true}
                          type="text"
                          id="seg_numapolice"
                          name="seg_numapolice"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.seg_numapolice}
                          className={classNames({
                            "p-invalid":
                              formik.touched.seg_numapolice &&
                              formik.errors.seg_numapolice,
                          })}
                        />
                        {formik.touched.seg_numapolice &&
                          formik.errors.seg_numapolice ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.seg_numapolice}
                          </div>
                        ) : null}
                        <label htmlFor="seg_numapolice">N° Apólice</label>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label  mt-5">
                        <InputText
                          disabled={true}
                          type="text"
                          id="seg_numcertificado"
                          name="seg_numcertificado"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.seg_numcertificado}
                          className={classNames({
                            "p-invalid":
                              formik.touched.seg_numcertificado &&
                              formik.errors.seg_numcertificado,
                          })}
                        />
                        {formik.touched.seg_numcertificado &&
                          formik.errors.seg_numcertificado ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.seg_numcertificado}
                          </div>
                        ) : null}
                        <label htmlFor="seg_numcertificado">N° Certificado</label>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label  mt-5">
                        <InputText
                          disabled={true}
                          type="text"
                          id="seg_numsorteio"
                          name="seg_numsorteio"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.seg_numsorteio}
                          className={classNames({
                            "p-invalid":
                              formik.touched.seg_numsorteio &&
                              formik.errors.seg_numsorteio,
                          })}
                        />
                        {formik.touched.seg_numsorteio &&
                          formik.errors.seg_numsorteio ? (
                          <div style={{ fontSize: 10, color: "red" }}>
                            {formik.errors.seg_numsorteio}
                          </div>
                        ) : null}
                        <label htmlFor="seg_numsorteio">N° Sorteio</label>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 md:col-12">
                    <div className="field-checkbox">
                      <Checkbox
                        inputId="seg_seguro_gerado"
                        name="seg_seguro_gerado"
                        checked={formik.values.seg_seguro_gerado}
                        onChange={formik.handleChange}
                        disabled={campoHabilitado}
                      />
                      <label htmlFor="seg_seguro_gerado">Seguro Gerado</label>
                    </div>
                  </div>

                </>
              );
            }
          })()}

        </div>
        <LoadingSpinner visualiza={loadingSpinner} />
      </form>
      
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
