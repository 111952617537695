import api from "./Api";
 
export class TipoPlanoService {
  async getById(id) {
    return await api
      .post(
        "administrativo/plano/visualizar",
        { ser_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async getByIdEstabelecimento() {
    return await api
      .get(
        "administrativo/plano/listarByEstabelecimento", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }


  async getAll() {
    return await api
      .get("administrativo/plano/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async postAtualizar(
    id,
    ser_ativo,
    ser_descricao,
    ser_est_id,
    ser_id_de_para,
    ser_valor,
    ser_qtd_meses,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/plano/atualizar",
        {
          ser_id: id,
          ser_ativo: ser_ativo,
          ser_descricao: ser_descricao,
          ser_est_id: ser_est_id,
          ser_id_de_para: ser_id_de_para,
          ser_valor: ser_valor,
          ser_qtd_meses: ser_qtd_meses,
          tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postCadastrar(
    ser_descricao,
    ser_est_id,
    ser_ativo,
    ser_id_de_para,
    ser_valor,
    ser_qtd_meses,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/plano/cadastrar",
        {
          ser_descricao: ser_descricao,
          ser_est_id: ser_est_id,
          ser_ativo: ser_ativo,
          ser_id_de_para: ser_id_de_para,
          ser_valor: ser_valor,

          ser_qtd_meses: ser_qtd_meses,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
