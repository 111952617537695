import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../../components/LoadingSpinner.js";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService.js";

import Exportar from "../../../components/Exportar.js";
import { useParams, Link, useHistory } from "react-router-dom";
import { Funcoes } from "../../../utils/funcoes.js";

const _Index = () => {

  const funcoes = new Funcoes();
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef(null);
  const dt = useRef(null);
  
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const _header = "Assinaturas";
  let { id } = useParams();
  let { trr_id } = useParams();
  const link_voltar = "/assinaturasIndex/" + id;

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });
  const [selectedCompras, setSelectedCompras] = useState(null);

  const relatorioService = new CartaoVirtualService();

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .planosEmprestimoParcelasServidor(trr_id)
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      );

  }, []);


  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      setCompras('');
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });


      setTimeout(() => {
        history.push(link_voltar);
      }, 3000);

    }
  }, [retornoDado]);

  const leftToolbarTemplate = () => {
    const cols = [
      { field: "est_nome_fantasia", header: "Estabelecimento" },
      { field: "cav_nome", header: "Servidor" },
      { field: "tep_data_vencimento", header: "Vencimento" },
      { field: "tep_data_pagamento", header: "Pago em" },
      { field: "tep_parcela", header: "Nº parcela" },
      { field: "tep_parcela_qtde", header: "Total parcelas" },
      { field: "tep_valor", header: "Valor" },
      { field: "status", header: "Status" },
      { field: "tep_motivo", header: "Motivo" },
    ];

    return (
      <React.Fragment>

        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Link to={link_voltar}>
              <Button type="submit" label="Voltar" className="p-button-raised" />
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
          </div>
        </div>

      </React.Fragment >
    );
  };

  const nomeFantasiaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Fantasia</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };

  const servidorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.cav_nome}
      </>
    );
  };

  const dataVencimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_data_vencimento}
      </>
    );
  };

  const dataPagamentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_data_pagamento}
      </>
    );
  };

  const parcelaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_parcela}
      </>
    );
  };


  const parcelaQtdeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_parcela_qtde}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_valor}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.status}
      </>
    );
  };

  const motivoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.tep_motivo}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  // path="/planosEmprestimoParcelasForm_U/:id/:trr_id/:tipo"

  const actionBodyTemplate = (rowData) => {
    let _link_U = "/assinaturaParcelasForm_U/" + id + "/" + trr_id + "/" + rowData.tep_id + "/U";
    let _acesso_U = "adm/servidor/quitar";
    return (
      <div className="grid">
        <div className="col-3 md:col-3">
          
          {!rowData.tep_origem_status && rowData.habilita ?
            <Link to={funcoes.permissaoAcesso(_acesso_U) && _link_U}>
              <Button
                icon={funcoes.permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
                className="p-button-rounded p-button-success "
                tooltip={"Alterar status"}
              />
            </Link>
            : null}
        </div>
      </div >
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={60}
            rowsPerPageOptions={[60, 100, 250]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_nome_fantasia"
              header="Estabelecimento"
              sortable
              body={nomeFantasiaBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="cav_nome"
              header="Servidor"
              body={servidorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="tep_data_vencimento"
              header="Vencimento"
              sortable
              body={dataVencimentoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="tep_data_pagamento"
              header="Pago em"
              sortable
              body={dataPagamentoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="tep_parcela"
              header="Nº parcela"
              sortable
              body={parcelaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="tep_parcela_qtde"
              header="Total parcelas"
              sortable
              body={parcelaQtdeBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>




            <Column
              field="tep_valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="status"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="tep_motivo"
              header="Motivo"
              sortable
              body={motivoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações"
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "15rem" }}

            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
