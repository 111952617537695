import React, { useEffect } from "react";
import "./_Index.css";
import { useHistory } from "react-router-dom";

export default function _Index() {
 

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.clear();
      window.location.href = "/";
    }, 3000);
  }, []);

  return (
    <div className="center-form">
      <div className="card p-fluid">
        <img
          src="assets/layout/images/logo.png"
          alt="Logo"
          height="60"
          className="mr-4"
        />
        <h1 className="titulo">TOKEN EXPIRADO</h1>
      </div>
    </div>
  );
}
