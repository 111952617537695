import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContratoService } from "../../service/ContratoService";
import formataData from "../../components/formataData";

import { triggerBase64Download } from "common-base64-downloader-react";

const _Form = () => {
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const history = useHistory();
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  const [usuId, setUsuId] = useState(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/contrato";
  const cabecalho_form = "Contrato";

  let _linkLog = "/contratoLogForm/";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    cnt_id: null,
    cnt_numero: "",
    cnt_taxa: null,
    cnt_tx_antecipa_1: "",
    cnt_tx_antecipa_2: "",
    cnt_tx_antecipa_3: "",
    cnt_data_contrato_ass: "",
    cnt_data_contrato_venc: "",
    cnt_data_contrato_ini: "",
    cnt_data_contrato_fim: "",
    cnt_valor_reajuste: null,
    cnt_usu_id: "",
    cnt_antecipa_mes: "",
    cnt_antecipa_vlr_minimo: "",
    cnt_antecipa_deposito_dias: "",
    cnt_data_adesao_ini: "",
    cnt_data_adesao_fim: "",
    cnt_adesao_valor: null,
    cnt_cnpj: "",
    cnt_razao_social: "",
    cnt_nome_fantasia: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);

  const [globalFilter, setGlobalFilter] = useState(null);

  const contratoService = new ContratoService();
  const [contratosDoc, setContratosDoc] = useState(null);
  const [selectedContratosDoc, setSelectedContratosDoc] = useState(null);

  const [contratosLog, setContratosLog] = useState(null);
  const [selectedContratosLog, setSelectedContratosLog] = useState(null);

  useEffect(() => {
    if (retornoDado.status === false) {
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    contratoService
      .getDocsById(id)
      .then(
        (data) =>
          data.status === true
            ? setContratosDoc(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });

    SetLoadingSpinner(true);
    contratoService
      .getContratoslogById(id)
      .then(
        (data) =>
          data.status === true
            ? setContratosLog(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });
  }, []);

  //seta o id do usuário pra passar no formulário quando um contrato é cadastrado.
  useEffect(() => {
    const id = sessionStorage.getItem("dados").slice(1).trim().split(",");
    setUsuId(id[0]);
  }, []);

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      contratoService
        .getById(id)
        .then(
          (data) =>
            data.status === true
              ? setModelDado(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        )
        .catch((error) => {              
          history.push("/semToken");        
      });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  //função para formatar a data para este caso específico. (mostrar na grid)
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(5, 7);
    const dia = dataPraF.toString().slice(8, 10);
    const hora = dataPraF.toString().slice(11, 19);
    const f = dia + "/" + mes + "/" + ano + " " + hora;
    return f;
  }

  //função para formatar a data enviada
  function formataDataEnviada(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    if (isset(retornoDado.code)) {
      history.push("/semToken");
    }
  }

  const formik = useFormik({
    initialValues: {
      cnt_id: modelDado.cnt_id,
      cnt_numero: modelDado.cnt_numero,
      cnt_taxa: modelDado.cnt_taxa,
      cnt_tx_antecipa_1: modelDado.cnt_tx_antecipa_1,
      cnt_tx_antecipa_2: modelDado.cnt_tx_antecipa_2,
      cnt_tx_antecipa_3: modelDado.cnt_tx_antecipa_3,
      cnt_data_contrato_ass: modelDado.cnt_data_contrato_ass,
      cnt_data_contrato_venc: modelDado.cnt_data_contrato_venc,
      cnt_data_contrato_ini: modelDado.cnt_data_adesao_ini,
      cnt_data_contrato_fim: modelDado.cnt_data_contrato_fim,
      cnt_valor_reajuste: modelDado.cnt_valor_reajuste,
      cnt_usu_id: modelDado.cnt_usu_id,
      cnt_antecipa_mes: modelDado.cnt_antecipa_mes,
      cnt_antecipa_vlr_minimo: modelDado.cnt_antecipa_vlr_minimo,
      cnt_antecipa_deposito_dias: modelDado.cnt_antecipa_deposito_dias,
      cnt_data_adesao_ini: modelDado.cnt_data_adesao_ini,
      cnt_data_adesao_fim: modelDado.cnt_data_adesao_fim,
      cnt_adesao_valor: modelDado.cnt_adesao_valor,
      cnt_cnpj: modelDado.cnt_cnpj,
      cnt_razao_social: modelDado.cnt_razao_social,
      cnt_nome_fantasia: modelDado.cnt_nome_fantasia,
    },

    validationSchema: yup.object({
      cnt_taxa: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(100, "Deve ser menor que 100")
        .min(0, "Deve ser maior que 0")
        .test(
          "maxDigitsAfterDecimal",
          "O valor não pode ter mais de dois digitos após a vírgula.",
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
      cnt_numero: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(999, "Deve ter três dígitos ou menos"),
      cnt_tx_antecipa_1: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(100, "Deve ser menor que 100")
        .min(0, "Deve ser maior que 0")
        .test(
          "maxDigitsAfterDecimal",
          "O valor não pode ter mais de dois digitos após a vírgula.",
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
      cnt_data_contrato_ass: yup.string().required("O campo é obrigatório."),
      cnt_data_contrato_venc: yup.string().required("O campo é obrigatório."),
      cnt_data_contrato_ini: yup.string().required("O campo é obrigatório."),
      cnt_data_contrato_fim: yup.string().required("O campo é obrigatório."),
      cnt_valor_reajuste: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(1000000, "Valor deve ser menor")
        .test(
          "maxDigitsAfterDecimal",
          "O valor não pode ter mais de dois digitos após a vírgula.",
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
      cnt_data_adesao_ini: yup.string().required("O campo é obrigatório."),
      cnt_data_adesao_fim: yup.string().required("O campo é obrigatório."),
      cnt_cnpj: yup.string().required("O campo é obrigatório."),
      cnt_razao_social: yup
        .string()
        .required("O campo é obrigatório.")
        .max(60, "Deve ter menos que 60 dígitos"),
      cnt_nome_fantasia: yup
        .string()
        .required("O campo é obrigatório.")
        .max(60, "Deve ter menos que 60 dígitos"),
      cnt_adesao_valor: yup
        .number()
        .required("O campo é obrigatório.")
        .max(1000000, "Valor deve ser menor")
        .typeError("Deve ser um número")
        .test(
          "maxDigitsAfterDecimal",
          "O valor não pode ter mais de dois digitos após a vírgula.",
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
      cnt_antecipa_vlr_minimo: yup
        .number()
        .required("O campo é obrigatório.")
        .max(1000000, "Valor deve ser menor")
        .typeError("Deve ser um número")
        .test(
          "maxDigitsAfterDecimal",
          "O valor não pode ter mais de dois digitos após a vírgula.",
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
      cnt_antecipa_mes: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(99, "Deve ter dois dígitos ou menos"),
      cnt_antecipa_deposito_dias: yup
        .number()
        .required("O campo é obrigatório.")
        .typeError("Deve ser um número")
        .max(99, "Deve ter dois dígitos ou menos"),
    }),

    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        contratoService
          .postCadastrar(
            values.cnt_numero,
            values.cnt_taxa,
            values.cnt_tx_antecipa_1,
            values.cnt_tx_antecipa_2,
            values.cnt_tx_antecipa_3,
            formataDataEnviada(values.cnt_data_contrato_ass),
            formataDataEnviada(values.cnt_data_contrato_venc),
            formataDataEnviada(values.cnt_data_contrato_ini),
            formataDataEnviada(values.cnt_data_contrato_fim),
            values.cnt_valor_reajuste,
            usuId, //tem que por o id do usuario nesse parametro
            values.cnt_antecipa_mes,
            values.cnt_antecipa_vlr_minimo,
            values.cnt_antecipa_deposito_dias,
            formataDataEnviada(values.cnt_data_adesao_ini),
            formataDataEnviada(values.cnt_data_adesao_fim),
            values.cnt_adesao_valor,
            values.cnt_cnpj,
            values.cnt_razao_social,
            values.cnt_nome_fantasia
          )
          .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              contratoService
                .postAtualizar(
                  values.cnt_id,
                  values.cnt_numero,
                  values.cnt_taxa,
                  values.cnt_tx_antecipa_1,
                  values.cnt_tx_antecipa_2,
                  values.cnt_tx_antecipa_3,
                  formataDataEnviada(values.cnt_data_contrato_ass),
                  formataDataEnviada(values.cnt_data_contrato_venc),
                  formataDataEnviada(values.cnt_data_contrato_ini),
                  formataDataEnviada(values.cnt_data_contrato_fim),
                  values.cnt_valor_reajuste,
                  usuId,
                  values.cnt_antecipa_mes,
                  values.cnt_antecipa_vlr_minimo,
                  values.cnt_antecipa_deposito_dias,
                  formataDataEnviada(values.cnt_data_adesao_ini),
                  formataDataEnviada(values.cnt_data_adesao_fim),
                  values.cnt_adesao_valor,
                  values.cnt_razao_social,
                  values.cnt_nome_fantasia,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  const filesElement = useRef(null);
  const sendFile = async () => {
    for (const file of filesElement.current.files) {
      contratoService
        .postPdf(file, formik.values.cnt_id)
        .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
    }
  };

  const actionBodyTemplate = (rowData) => {
    let id = rowData.cta_id;
    let nomeDoc = rowData.cta_arquivo_nome;
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Button
            icon={"pi pi-eye"}
            className="p-button-rounded p-button-warning mt-12"
            onClick={() => baixarPdf(id, nomeDoc)}
          />
        </div>
      </div>
    );
  };

  const baixarPdf = (id, nomeDoc) => {
    SetLoadingSpinner(true);
    contratoService
      .baixarPdf(id)
      .then(
        (data) => triggerBase64Download(data, nomeDoc),
        SetLoadingSpinner(false)
      );
  };

  const actionBodyTemplateLog = (rowData) => {
    let _link_V = _linkLog + rowData.ctl_id + "/V";
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={_link_V}>
            <Button
              icon={"pi pi-eye"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const contDocdataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cadastro</span>
        {formataDataInicialFinal(rowData.cta_data_cadastro)}
      </>
    );
  };
  const contDocArquivoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Arquivo</span>
        {rowData.cta_arquivo_nome}
      </>
    );
  };
  const dataAlteracaoDoclogBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Alteracao</span>
        {formataDataInicialFinal(rowData.ctl_data_cadastro)}
      </>
    );
  };

  const dataVencDoclogBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data contrato vencimento</span>
        {formataData(rowData.cnt_data_contrato_venc)}
      </>
    );
  };

  const dtIniDoclogBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data contrato vencimento</span>
        {formataData(rowData.cnt_data_contrato_ini)}
      </>
    );
  };

  const dtFimDoclogBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data contrato vencimento</span>
        {formataData(rowData.cnt_data_contrato_fim)}
      </>
    );
  };

  const usuDoclogBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuário</span>
        {rowData.usu_nome}
      </>
    );
  };

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="99.999.999/9999-99"
                  unmask={true}
                  disabled={tipo === "U" || tipo === "V" ? true : false}
                  type="text"
                  id="cnt_cnpj"
                  name="cnt_cnpj"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_cnpj}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_cnpj && formik.errors.cnt_cnpj,
                  })}
                />
                {formik.touched.cnt_cnpj && formik.errors.cnt_cnpj ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_cnpj}
                  </div>
                ) : null}
                <label htmlFor="est_cnpj">CNPJ</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_numero"
                  name="cnt_numero"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_numero}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_numero && formik.errors.cnt_numero,
                  })}
                />
                {formik.touched.cnt_numero && formik.errors.cnt_numero ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_numero}
                  </div>
                ) : null}
                <label htmlFor="cnt_numero">Número de contrato</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_razao_social"
                  name="cnt_razao_social"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_razao_social}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_razao_social &&
                      formik.errors.cnt_razao_social,
                  })}
                />
                {formik.touched.cnt_razao_social &&
                formik.errors.cnt_razao_social ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_razao_social}
                  </div>
                ) : null}
                <label htmlFor="cnt_razao_social">Razão social</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-12">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_nome_fantasia"
                  name="cnt_nome_fantasia"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_nome_fantasia}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_nome_fantasia &&
                      formik.errors.cnt_nome_fantasia,
                  })}
                />
                {formik.touched.cnt_nome_fantasia &&
                formik.errors.cnt_nome_fantasia ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_nome_fantasia}
                  </div>
                ) : null}
                <label htmlFor="cnt_nome_fantasia">Nome fantasia</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Período</h5>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_ass"
                  name="cnt_data_contrato_ass"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_ass}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_ass &&
                      formik.errors.cnt_data_contrato_ass,
                  })}
                />
                {formik.touched.cnt_data_contrato_ass &&
                formik.errors.cnt_data_contrato_ass ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_ass}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_ass">Data Assinatura</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_ini"
                  name="cnt_data_contrato_ini"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_ini}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_ini &&
                      formik.errors.cnt_data_contrato_ini,
                  })}
                />
                {formik.touched.cnt_data_contrato_ini &&
                formik.errors.cnt_data_contrato_ini ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_ini}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_ini">Data Início</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_fim"
                  name="cnt_data_contrato_fim"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_fim}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_fim &&
                      formik.errors.cnt_data_contrato_fim,
                  })}
                />
                {formik.touched.cnt_data_contrato_fim &&
                formik.errors.cnt_data_contrato_fim ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_fim}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_fim">Data Fim</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_contrato_venc"
                  name="cnt_data_contrato_venc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_contrato_venc}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_contrato_venc &&
                      formik.errors.cnt_data_contrato_venc,
                  })}
                />
                {formik.touched.cnt_data_contrato_venc &&
                formik.errors.cnt_data_contrato_venc ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_contrato_venc}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_contrato_venc">Data Vencimento</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />
                <InputNumber
                  disabled={campoHabilitado}
                  prefix="R$ "
                  value={formik.values.cnt_valor_reajuste}
                  onValueChange={formik.handleChange}
                  mode="decimal"
                  locale="de-DE"
                  id="cnt_valor_reajuste"
                  name="cnt_valor_reajuste"
                  currencyDisplay="code"
                  maxFractionDigits={2}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_valor_reajuste &&
                      formik.errors.cnt_valor_reajuste,
                  })}
                />
                {formik.touched.cnt_valor_reajuste &&
                formik.errors.cnt_valor_reajuste ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_valor_reajuste}
                  </div>
                ) : null}
                <label htmlFor="cnt_valor_reajuste">Reajuste (R$)</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Taxas</h5>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-percentage" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_taxa"
                  name="cnt_taxa"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_taxa}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_taxa && formik.errors.cnt_taxa,
                  })}
                />
                {formik.touched.cnt_taxa && formik.errors.cnt_taxa ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_taxa}
                  </div>
                ) : null}

                <label htmlFor="cnt_taxa">Administrativa %</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-percentage" />
                <InputText
                  disabled={campoHabilitado}
                  type="number"
                  id="cnt_tx_antecipa_1"
                  name="cnt_tx_antecipa_1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_tx_antecipa_1}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_tx_antecipa_1 &&
                      formik.errors.cnt_tx_antecipa_1,
                  })}
                />
                {formik.touched.cnt_tx_antecipa_1 &&
                formik.errors.cnt_tx_antecipa_1 ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_tx_antecipa_1}
                  </div>
                ) : null}

                <label htmlFor="cnt_tx_antecipa_1">Antecipação %</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_adesao_ini"
                  name="cnt_data_adesao_ini"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_adesao_ini}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_adesao_ini &&
                      formik.errors.cnt_data_adesao_ini,
                  })}
                />
                {formik.touched.cnt_data_adesao_ini &&
                formik.errors.cnt_data_adesao_ini ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_adesao_ini}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_adesao_ini">
                  Adesão - Data Início
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_data_adesao_fim"
                  name="cnt_data_adesao_fim"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_data_adesao_fim}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_data_adesao_fim &&
                      formik.errors.cnt_data_adesao_fim,
                  })}
                />
                {formik.touched.cnt_data_adesao_fim &&
                formik.errors.cnt_data_adesao_fim ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_data_adesao_fim}
                  </div>
                ) : null}
                <label htmlFor="cnt_data_adesao_fim">Adesão - Data Fim</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />
                <InputNumber
                  disabled={campoHabilitado}
                  prefix="R$ "
                  value={formik.values.cnt_adesao_valor}
                  onValueChange={formik.handleChange}
                  mode="decimal"
                  locale="de-DE"
                  id="cnt_adesao_valor"
                  name="cnt_adesao_valor"
                  currencyDisplay="code"
                  maxFractionDigits={2}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_adesao_valor &&
                      formik.errors.cnt_adesao_valor,
                  })}
                />
                {formik.touched.cnt_adesao_valor &&
                formik.errors.cnt_adesao_valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_adesao_valor}
                  </div>
                ) : null}
                <label htmlFor="cnt_adesao_valor">Adesão (R$)</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Parâmetros</h5>
        <div className="grid">
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_antecipa_mes"
                  name="cnt_antecipa_mes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_antecipa_mes}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_mes &&
                      formik.errors.cnt_antecipa_mes,
                  })}
                />
                {formik.touched.cnt_antecipa_mes &&
                formik.errors.cnt_antecipa_mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_mes}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_mes">
                  Qtde. Antecipações ao Mês
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <i className="pi pi-dollar" />
                <InputNumber
                  disabled={campoHabilitado}
                  prefix="R$ "
                  value={formik.values.cnt_antecipa_vlr_minimo}
                  onValueChange={formik.handleChange}
                  mode="decimal"
                  locale="de-DE"
                  id="cnt_antecipa_vlr_minimo"
                  name="cnt_antecipa_vlr_minimo"
                  currencyDisplay="code"
                  maxFractionDigits={2}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_vlr_minimo &&
                      formik.errors.cnt_antecipa_vlr_minimo,
                  })}
                />
                {formik.touched.cnt_antecipa_vlr_minimo &&
                formik.errors.cnt_antecipa_vlr_minimo ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_vlr_minimo}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_vlr_minimo">
                  Valor mínimo para Antecipação
                </label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="cnt_antecipa_deposito_dias"
                  name="cnt_antecipa_deposito_dias"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnt_antecipa_deposito_dias}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cnt_antecipa_deposito_dias &&
                      formik.errors.cnt_antecipa_deposito_dias,
                  })}
                />
                {formik.touched.cnt_antecipa_deposito_dias &&
                formik.errors.cnt_antecipa_deposito_dias ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cnt_antecipa_deposito_dias}
                  </div>
                ) : null}
                <label htmlFor="cnt_antecipa_deposito_dias">
                  Prazo em dias para o depósito
                </label>
              </span>
            </div>
          </div>
        </div>
      </form>

      {/* renderizacao condicional:  */}
      {(() => {
        if (tipo === "I") {
          return <div></div>;
        } else {
          return (
            <>
              <div className="grid">
                {tipo === "U" ? (
                  <div className="grid card col-12">
                    <div className="col-12">
                      <div className="col-12">
                        <h5>Incluir Documentos</h5>
                        <input
                          type="file"
                          multiple
                          ref={filesElement}
                          id="upload"
                        />
                      </div>

                      <div className="col-3">
                        <Button
                          type="submit"
                          label="Enviar"
                          className="p-button-rounded mr-1 mb-1 "
                          onClick={sendFile}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="grid card">
                <div className="col-4">
                  <h5>Documentos do contrato</h5>
                  <DataTable
                    value={contratosDoc}
                    selection={selectedContratosDoc}
                    onSelectionChange={(e) => setSelectedContratosDoc(e.value)}
                    dataKey="cta_id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
                    globalFilter={globalFilter}
                    emptyMessage="Não há dados."
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="cta_data"
                      header="Data Cadastro"
                      sortable
                      body={contDocdataBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>
                    <Column
                      field="cta_arquivo_nome"
                      header="Arquivo"
                      sortable
                      body={contDocArquivoBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>

                    <Column header="Ações" body={actionBodyTemplate}></Column>
                  </DataTable>
                </div>

                <div className="col-8">
                  <h5>Histórico de Alterações do Contrato</h5>
                  <DataTable
                    //ref={dt}
                    value={contratosLog}
                    selection={selectedContratosLog}
                    onSelectionChange={(e) => setSelectedContratosLog(e.value)}
                    dataKey="ctl_id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
                    globalFilter={globalFilter}
                    emptyMessage="Não há dados."
                    //header={header}
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="ctl_data_cadastro"
                      header="Data alteração"
                      sortable
                      body={dataAlteracaoDoclogBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>
                    <Column
                      field="cnt_data_contrato_venc"
                      header="Data Vencimento"
                      sortable
                      body={dataVencDoclogBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>

                    <Column
                      field="cnt_data_contrato_ini"
                      header="Data inicio"
                      sortable
                      body={dtIniDoclogBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>

                    <Column
                      field="cnt_data_contrato_fim"
                      header="Data fim"
                      sortable
                      body={dtFimDoclogBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>

                    <Column
                      field="usu_nome"
                      header="Usuário que efetuou a alteração"
                      sortable
                      body={usuDoclogBodyTemplate}
                      headerStyle={{ width: "100%", minWidth: "10rem" }}
                    ></Column>

                    <Column
                      header="Ações"
                      body={actionBodyTemplateLog}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </>
          );
        }
      })()}

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
