import api from "./Api";
 
export class EstabelecimentoTipoService {
  async getAll() {
    return await api
      .get("administrativo/estabelecimento/listartipo", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
}
