
import api from "./Api";
 

export class StatusService {  
 
  async getAll() {
    return await api.get("administrativo/status/listar", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }
}