import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { UsuariosService } from "../../service/UsuariosService";
import SelectEst from "../../components/SelecionarEstabelecimento/SelectEst";
import { PerfilService } from "../../service/PerfilService";
import Teclado from "../../components/Teclado/Teclado";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [estSelecionado, SetEstSelecinado] = useState(null);
  const [login, setLogin] = useState(null);
  const [tipoUsu, setTipoUsu] = useState(null);

  const toggle = (event) => {
    op.current.toggle(event);
  };
  const op = useRef(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/usuarios";
  const cabecalho_form = "Usuário";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  /**
   *------------ TECLADO -----------
   */
  const [tecladoDialog, setTecladoDialog] = useState(false);
  const openNew = () => {
    setTecladoDialog(true);
  };
  const tecladoHide = () => {
    setTecladoDialog(false);
  };

  const tecladoClose = () => {
    setTecladoDialog(false);
  };

  const tecladoPassword = (num) => {
    formik.values.usu_senha = num;
    setLogin(num)
    setTecladoDialog(false);
  };

  /**
   *--------- FIM TECLADO -----------
   */

  let emptyDados = {
    usu_est_id: "",
    usu_senha: "",
    usu_per_id: "",
    usu_per_nome: "",
    usu_id: "",
    usu_nome: "",
    usu_login: "",
    usu_ativo: true,
    usu_email: "",
    usu_ust_id: "",
    password: "",
  };

  let retornoDados = {
    status: null,
    mensagem: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);




  const [empresaid, setEmpresaId] = useState(null);
  const [perfil, setPerfil] = useState(null);
  const [tipoUsuario, SetTipoUsuario] = useState(null);

  const usuariosService = new UsuariosService();
  const perfilService = new PerfilService();

  useEffect(() => {

    usuariosService
      .getTipoUsuario()
      .then((data) =>        
        SetTipoUsuario(data.dados)
        , SetLoadingSpinner(false));


  }, []);

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const usuariosService = new UsuariosService();
      usuariosService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {
          history.push("/semToken");
        });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);


  function buscaPerfil() {
    SetLoadingSpinner(true);
    perfilService
      .getByEmpresa(formik.values.usu_ust_id)
      .then((data) => setPerfil(data.dados), SetLoadingSpinner(false));
      setEmpresaId(formik.values.usu_ust_id)
  }

  useEffect(() => {
    formik.setValues(modelDado);
    setEmpresaId(modelDado.usu_ust_id)
  }, [modelDado]);


  useEffect(() => {
    buscaPerfil()
  }, [empresaid]);

  useEffect(() => {
    toast.current.show({
      severity: "success",
      summary: "Estabelecimento selecionado",
      life: 3000,
    });
  }, [estSelecionado]);

  useEffect(() => {
    if (retornoDado.status !== null) {

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      usu_id: modelDado.usu_id,
      usu_cpf: modelDado.usu_cpf,
      usu_nome: modelDado.usu_nome,
      usu_login: modelDado.usu_login,
      usu_ativo: modelDado.usu_ativo,
      usu_email: modelDado.usu_email,
      usu_senha: login == null ? modelDado.usu_senha : login,
      usu_per_id: modelDado.usu_per_id,
      usu_ust_id: modelDado.usu_ust_id
    },

    validationSchema: yup.object({
      usu_nome: yup.string().required("O campo é obrigatório."),
      usu_login: yup.string().required("O campo é obrigatório."),
      usu_email: yup
        .string()
        .email("Deve ser um e-mail")
        .required("O campo é obrigatório."),
    }),

    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              usuariosService
                .postCadastrar(
                  values.usu_cpf,
                  values.usu_nome,
                  values.usu_login,
                  values.usu_ativo,
                  values.usu_email,
                  values.usu_senha,
                  values.usu_per_id,
                  values.usu_ust_id,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {
                  history.push("/semToken");
                });
            });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              usuariosService
                .postAtualizar(
                  values.usu_id,
                  values.usu_cpf,
                  values.usu_nome,
                  values.usu_login,
                  values.usu_ativo,
                  values.usu_email,
                  values.usu_senha,
                  values.usu_per_id,
                  values.usu_ust_id,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {
                  alert(JSON.stringify(error));
                  history.push("/semToken");
                });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="usu_ust_id"
                  value={formik.values.usu_ust_id}
                  onChange={formik.handleChange}
                  options={tipoUsuario}
                  optionLabel="ust_descricao"
                  optionValue="ust_id"
                  onHide={buscaPerfil}
                  placeholder={formik.values.usu_ust_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_ust_id &&
                      formik.errors.usu_ust_id,
                  })}
                />
                {formik.touched.usu_ust_id &&
                  formik.errors.usu_ust_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_ust_id}
                  </div>
                ) : null}
                <label htmlFor="usu_ust_id">Empresa</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 ">
            <div className="field-checkbox">
              <span className="p-float-label  mt-6">
                <Checkbox
                  inputId="usu_ativo"
                  name="usu_ativo"
                  checked={formik.values.usu_ativo}
                  onChange={formik.handleChange}
                  disabled={campoHabilitado}
                />

              </span>
              <span className="p-float-label  mt-6">
                <label htmlFor="usu_ativo">Ativo</label>
              </span>
            </div>
          </div>


          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputMask
                  mask="999.999.999-99"
                  unmask={true}
                  disabled={tipo == "U" ? true : campoHabilitado}
                  type="text"
                  id="usu_cpf"
                  name="usu_cpf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usu_cpf}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_cpf && formik.errors.usu_cpf,
                  })}
                />
                {formik.touched.usu_cpf && formik.errors.usu_cpf ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_cpf}
                  </div>
                ) : null}
                <label htmlFor="usu_cpf">CPF</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="usu_nome"
                  name="usu_nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usu_nome}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_nome && formik.errors.usu_nome,
                  })}
                />
                {formik.touched.usu_nome && formik.errors.usu_nome ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_nome}
                  </div>
                ) : null}
                <label htmlFor="usu_nome">Nome do Usuário</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="usu_login"
                  name="usu_login"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usu_login}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_login && formik.errors.usu_login,
                  })}
                />
                {formik.touched.usu_login && formik.errors.usu_login ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_login}
                  </div>
                ) : null}
                <label htmlFor="usu_login">Login do Usuário</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="usu_email"
                  name="usu_email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usu_email}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_email && formik.errors.usu_email,
                  })}
                />
                {formik.touched.usu_email && formik.errors.usu_email ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_email}
                  </div>
                ) : null}
                <label htmlFor="usu_email">E-mail do Usuário</label>
              </span>
            </div>
          </div>







          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="usu_per_id"
                  value={formik.values.usu_per_id}
                  onChange={formik.handleChange}
                  options={perfil}
                  optionLabel="per_nome"
                  optionValue="per_id"
                  placeholder={formik.values.usu_per_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_per_id &&
                      formik.errors.usu_per_id,
                  })}
                />
                {formik.touched.usu_per_id &&
                  formik.errors.usu_per_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_per_id}
                  </div>
                ) : null}
                <label htmlFor="usu_per_id">Perfil do Usuário</label>
              </span>
            </div>
          </div>
          {/* renderizacao conndicional para mostrar ou estabelecimentos para selecionar ou perfil */}


          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  onClick={openNew}
                  id="usu_senha"
                  type="password"
                  placeholder=""
                  maxLength={6}
                  value={formik.values.usu_senha}
                  onChange={formik.handleChange}
                  disabled={campoHabilitado}
                  name="usu_senha"
                  className={classNames({
                    "p-invalid":
                      formik.touched.usu_senha && formik.errors.usu_senha,
                  })}
                />
                {formik.touched.usu_senha && formik.errors.usu_senha ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.usu_senha}
                  </div>
                ) : null}
                <label htmlFor="usu_senha">Senha</label>
              </span>
            </div>
          </div>
        </div>
        {/* <Dialog visible={tecladoDialog} onHide={tecladoHide}>
          <Teclado
            maxDigitos={6}
            tecladoClose={tecladoClose}
            tecladoPassword={tecladoPassword}
          />
        </Dialog> */}
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
