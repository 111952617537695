import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";
import formataData from "../../../components/formataData";
import { InputMask } from "primereact/inputmask";
import Exportar from "../../../components/Exportar";
import { useParams, Link, useHistory } from "react-router-dom";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

/* Pesquisar */

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Empréstimos";
  let { id } = useParams();
  const link_voltar = "/servidorForm_V/" + id + "/V";


  let retornoDados = {
    status: null,
    msg: "",
  };

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [selectedCompras, setSelectedCompras] = useState(null);
  const relatorioService = new CartaoVirtualService();

  const exportCSV = () => {
    dt.current.exportCSV();
  };





  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .planosEmprestimoServidor(id)
      .then(
        (data) =>
          data.status === true
            ? setCompras(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      );

  }, []);


  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      setCompras('');
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });


      setTimeout(() => {
        history.push(link_voltar);
      }, 3000);

    }
  }, [retornoDado]);



  const leftToolbarTemplate = () => {
    const cols = [
      { field: "est_nome_fantasia", header: "Estabelecimento" },
      { field: "trr_data_cadastro", header: "Data" },
      { field: "trr_data_fim_recorrencia", header: "Vigência até" },
      { field: "trr_data_cancelamento", header: "Cancelado em" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_matricula", header: "Matrícula" },
      { field: "trr_qtde_parcelas", header: "Nº Parcelas" },
      { field: "valor_total", header: "Valor Liberado" },
      { field: "tes_descricao", header: "Status" },
    ];

    return (
      <React.Fragment>

        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Link to={link_voltar}>
              <Button type="submit" label="Voltar" className="p-button-raised" />
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-4 mr-6">
          <div className="field">
            <Exportar titulo={_header} dt={dt} cols={cols} dadosService={compras} />
          </div>
        </div>


      </React.Fragment >
    );
  };


  const actionBodyTemplate = (rowData) => {
    let link_parcelas = "/planosEmprestimoParcelasIndex/" + id + "/" + rowData.trr_id;
    return (
      <div className="grid">
        <div className="col-3 md:col-3">
          <Link to={link_parcelas}>
            <Button
              icon={"pi pi-money-bill"}
              className="p-button-rounded p-button-success "
              tooltip={"Parcelas"}
            />
          </Link>
        </div>        
      </div >
    );
  };


  const cnpjBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CNPJ</span>
        {rowData.est_cnpj}
      </>
    );
  };

  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.trr_data_cadastro}
      </>
    );
  };

  const dataFimBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Fim</span>
        {rowData.trr_data_fim_recorrencia == null ? '---' : rowData.trr_data_fim_recorrencia}
      </>
    );
  };

  const dataCancelamentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cancelamento</span>
        {rowData.trr_data_cancelamento == null ? '---' : rowData.trr_data_cancelamento}

      </>
    );
  };



  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.tes_descricao}
      </>
    );
  };

  const parcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nº Parcelas</span>
        {rowData.trr_qtde_parcelas}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor total</span>
        {rowData.valor_total}
      </>
    );
  };

  const valorMontanteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor Montante</span>
        {rowData.valor_montante}
      </>
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };
  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 250]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_nome_fantasia"
              header="Estabelecimento"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="trr_data_cadastro"
              header="Data"
              body={dataBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="trr_data_fim_recorrencia"
              header="Vigência Até"
              sortable
              body={dataFimBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="trr_data_cancelamento"
              header="Cancelado em"
              sortable
              body={dataCancelamentoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "30%", minWidth: "20rem" }}
            ></Column>


            <Column
              field="trr_qtde_parcelas"
              header="Nº Parcelas"
              sortable
              body={parcelasBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="valor_total"
              header="Valor Liberado"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="valor_montante"
              header="Valor Montante"
              sortable
              body={valorMontanteBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="tes_descricao"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column header="Ações"
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "15rem" }}

            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
