import api from "./Api";
 
export class TipoEstabelecimentoService {
  async getAll() {
    return await api
      .get("administrativo/tipoconvenio/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async getById(id) {
    return await api
      .post(
        "administrativo/tipoconvenio/visualizar",
        { tco_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postAtualizar(id, descricao, tokenGoogleo) {
    return await api
      .post(
        "administrativo/tipoconvenio/atualizar",
        {
          tco_id: id,
          tco_descricao: descricao,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postCadastrar(descricao, tokenGoogleo) {
    return await api
      .post(
        "administrativo/tipoconvenio/cadastrar",
        {
          tco_descricao: descricao,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
