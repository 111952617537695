import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SimularService } from "../../service/SimularService";
import { Dropdown } from "primereact/dropdown";
import { OperadoraService } from "../../service/OperadoraService";
import { ConvenioService } from "../../service/ConvenioService";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import "./styles.css";
const _Form = () => {
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const history = useHistory();
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [parcelasOptions, setParcelasOptions] = useState();

  // let { id } = useParams();
  // let { tipo } = useParams();
  // const link_voltar = "/convenio";
  const cabecalho_form = "Saque fácil";

  let header = <h5>{cabecalho_form} - Simular</h5>;

  let emptyDados = {
    operadora: null,
    convenio: null,
    cpf: "",
    matricula: "",
    dataNascimento: "",
    tipoSimulacao: "",
    valor: null,
    quantidadeParcelas: null,
  };

  let retornoDados = {
    status: null,
    msg: "",
    dados: {},
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [operadora, setOperadora] = useState(null);
  const [convenio, setConvenio] = useState(null);
  const [showDados, setShowDados] = useState(false);
  const [control, setControl] = useState(false);
  const [dadosSimulacao, setDadosSimulacao] = useState({});

  const simularService = new SimularService();
  const operadoraService = new OperadoraService();
  const convenioService = new ConvenioService();

  useEffect(() => {
    operadoraService
      .getAll()
      .then((data) => setOperadora(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
      SetLoadingSpinner(false);
      setShowDados(true);
    }
  }, [retornoDado]);

  function buscaConvenios() {
    convenioService
      .getByOperadora(formik.values.operadora)
      .then((data) => setConvenio(data.dados), SetLoadingSpinner(false));
  }

  function buscaParcelasConvenio() {
    simularService
      .getParcelas(formik.values.operadora, formik.values.convenio)
      .then((data) => {
        const novoFormato = data.dados.map((valor) => {
          return { id: parseInt(valor), label: parseInt(valor) };
        });
        setParcelasOptions(novoFormato);
        SetLoadingSpinner(false);
      })
      .catch((error) => {});
  }

  const formik = useFormik({
    initialValues: {
      operadora: null,
      convenio: null,
      cpf: "",
      matricula: "",
      dataNascimento: false,
      tipoSimulacao: "",
      valor: null,
      quantidadeParcelas: null,
    },
    validationSchema: yup.object({
      operadora: yup
        .number("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      convenio: yup
        .number("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      cpf: yup
        .number("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      matricula: yup
        .string("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      dataNascimento: yup
        .string("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      tipoSimulacao: yup
        .string("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      valor: yup
        .number("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
      quantidadeParcelas: yup
        .number("O campo é obrigatório.")
        .required("O campo é obrigatório.")
        .typeError("O campo é obrigatório"),
    }),
    onSubmit: (values) => {
      // if (tipo === "I") {
      // SetLoadingSpinner(true);
      // convenioService
      //   .postCadastrar(values.tco_descricao)
      //   .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
      // } else {
      setShowDados(false);
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            simularService
              .postSimular(
                values.operadora,
                values.convenio,
                values.cpf,
                values.matricula,
                values.dataNascimento,
                values.tipoSimulacao,
                values.valor,
                values.quantidadeParcelas,
                token
              )
              .then((data) => setRetornoDado(data));
            // .catch((error) => {
            //   history.push("/semToken");
            // });
          });
      });
      // }
    },
  });

  // useEffect(() => {
  //   setShowDados(!showDados)
  // }, [control]);

  const buttonHeader = (
    <div className="grid">
      {/* {tipo !== "V" && ( */}
      <div className="mr-2 mb-2">
        <Button
          type="submit"
          label="Simular"
          className="p-button-raised p-button-success "
          onClick={formik.handleSubmit}
        />
      </div>
      {/* )} */}
      <div className="p-button-raised mr-2 mb-2">
        {/* <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link> */}
      </div>
    </div>
  );

  const simulacaoOptions = [
    {
      sml_id: "Parcela",
      sml_nome: "Valor da parcela",
    },
    {
      sml_id: "Total",
      sml_nome: "Valor total",
    },
  ];

  function converterData(data) {
    const dataFormatada = new Date(data);

    const dia = String(dataFormatada.getDate()).padStart(2, "0");
    const mes = String(dataFormatada.getMonth() + 1).padStart(2, "0");
    const ano = dataFormatada.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  let resultadoSimulacao;
  console.log(retornoDado);
  if (retornoDado.errors) {
    resultadoSimulacao = (
      <>
        <h5>Valores da simulação:</h5>

        <h6>Não foi possível realizar esta simulação!</h6>
        <p>
          <strong>Crítica: </strong>
          {retornoDado.mensagem}
        </p>
      </>
    );
  } else if (typeof retornoDado.dados === "string") {
    resultadoSimulacao = (
      <>
        <h5>Valores da simulação:</h5>

        <h6>Não foi possível realizar esta simulação!</h6>
        <p>
          <strong>Crítica: </strong>
          {retornoDado.dados}
        </p>
      </>
    );
  } else {
    /* If retornoDado.dados is not a string */
    /* Maybe handle other types here */
    resultadoSimulacao = (
      <>
        <h5>Valores da simulação:</h5>

        <div className="grid-container">
          <div className="column">
            <p>
              <strong>Valor Liberado:</strong> R$:{" "}
              {retornoDado.dados.ValorSolicitado || ""}
            </p>
            <p>
              <strong>Valor Parcela:</strong> R$:{" "}
              {retornoDado.dados.ValorParcela || ""}
            </p>
            <p>
              <strong>Quantidade de Parcelas:</strong>{" "}
              {retornoDado.dados.QuantidadeParcelas || ""}
            </p>
            <p>
              <strong>Data Primeiro Vencimento:</strong>{" "}
              {converterData(retornoDado.dados.DataPrimeiroVencimento) || ""}
            </p>
          </div>
          <div className="column">
            <p>
              <strong>Data Último Vencimento:</strong>{" "}
              {converterData(retornoDado.dados.DataUltimoVencimento) || ""}
            </p>
            <p>
              <strong>Taxa CET AM:</strong> {retornoDado.dados.TaxaCETAM || ""}{" "}
              %
            </p>
            <p>
              <strong>Valor IOF:</strong> {retornoDado.dados.ValorIOF || ""}
            </p>
            <p>
              <strong>Taxa CET AA:</strong> {retornoDado.dados.TaxaCETAA || ""}{" "}
              %
            </p>
          </div>

          <div className="column">
            <p>
              <strong>Taxa Nominal AM:</strong>{" "}
              {retornoDado.dados.TaxaNominalAM || ""} %
            </p>
            <p>
              <strong>Taxa Nominal AA:</strong>{" "}
              {retornoDado.dados.TaxaNominalAA || ""} %
            </p>
            {/* <p>
              <strong>Valor Principal:</strong> R$:{" "}
              {retornoDado.dados.ValorPrincipal || ""}
            </p> */}

            {/* <p>
              <strong>Valor Bruto:</strong> R$:{" "}
              {retornoDado.dados.ValorBruto || ""}
            </p> */}

            {/* <p>
              <strong>Valor Liquido:</strong> R$:{" "}
              {retornoDado.dados.ValorLiquido || ""}
            </p>
            <p>
              <strong>Valor Cliente:</strong> R$:{" "}
              {retornoDado.dados.ValorCliente || ""}
            </p> */}
          </div>
          {/* <p>
              <strong>Taxa AP AM:</strong> {retornoDado.dados.TaxaAPAM || ""} %
            </p> */}
          {/* <p>
              <strong>Taxa AP AA:</strong> {retornoDado.dados.TaxaAPAA || ""} %
            </p> */}
          {/* <p>
              <strong>Taxa CL AM:</strong> {retornoDado.dados.TaxaCLAM || ""} %
            </p> */}
          {/* <p>
              <strong>Taxa CL AA:</strong> {retornoDado.dados.TaxaCLAA || ""} %
            </p> */}
        </div>
      </>
    );
  }

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados do servidor</h5>
        <div className="grid">
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="operadora"
                  value={formik.values.operadora}
                  onChange={formik.handleChange}
                  options={operadora}
                  optionLabel="ope_nome"
                  optionValue="ope_id"
                  onHide={buscaConvenios}
                  placeholder={formik.values.operadora}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.operadora && formik.errors.operadora,
                  })}
                />
                {formik.touched.operadora && formik.errors.operadora ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.operadora}
                  </div>
                ) : null}
                <label htmlFor="operadora">Processadora</label>
              </span>
            </div>
          </div>
          <div className={"col-12 md:col-4"}>
            <div className="field">
              <span className="p-float-label  mt-2">
                <Dropdown
                  id="convenio"
                  value={formik.values.convenio}
                  onChange={formik.handleChange}
                  options={convenio}
                  optionLabel="con_nome_fantasia"
                  optionValue="con_id"
                  onHide={buscaParcelasConvenio}
                  placeholder={
                    formik.values.convenio == "" ? "" : formik.values.convenio
                  }
                  emptyMessage={
                    "Selecione a operadora para exibir a lista de convênios"
                  }
                  className={classNames({
                    "p-invalid":
                      formik.touched.convenio && formik.errors.convenio,
                  })}
                />

                {formik.touched.convenio && formik.errors.convenio ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.convenio}
                  </div>
                ) : null}
                <label htmlFor="convenio">Convênio *</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-2">
                <InputMask
                  mask="999.999.999-99"
                  unmask={true}
                  type="text"
                  id="cpf"
                  name="cpf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpf}
                  className={classNames({
                    "p-invalid": formik.touched.cpf && formik.errors.cpf,
                  })}
                />
                {formik.touched.cpf && formik.errors.cpf ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cpf}
                  </div>
                ) : null}
                <label htmlFor="cpf">CPF *</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label  mt-2">
                <InputText
                  type="text"
                  id="matricula"
                  name="matricula"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.matricula}
                  className={classNames({
                    "p-invalid":
                      formik.touched.matricula && formik.errors.matricula,
                  })}
                />
                {formik.touched.matricula && formik.errors.matricula ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.matricula}
                  </div>
                ) : null}
                <label htmlFor="matricula">Matrícula *</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-2">
                <InputMask
                  disabled={campoHabilitado}
                  mask="99/99/9999"
                  unmask={true}
                  type="text"
                  id="dataNascimento"
                  name="dataNascimento"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dataNascimento}
                  className={classNames({
                    "p-invalid":
                      formik.touched.dataNascimento &&
                      formik.errors.dataNascimento,
                  })}
                />
                {formik.touched.dataNascimento &&
                formik.errors.dataNascimento ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.dataNascimento}
                  </div>
                ) : null}
                <label htmlFor="dataNascimento">Data Nascimento *</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-12">
            <h5>Dados da simulação</h5>
          </div>
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="tipoSimulacao"
                  value={formik.values.tipoSimulacao}
                  onChange={formik.handleChange}
                  options={simulacaoOptions}
                  optionLabel="sml_nome"
                  optionValue="sml_id"
                  placeholder={formik.values.tipoSimulacao}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.tipoSimulacao &&
                      formik.errors.tipoSimulacao,
                  })}
                />
                {formik.touched.tipoSimulacao && formik.errors.tipoSimulacao ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.tipoSimulacao}
                  </div>
                ) : null}
                <label htmlFor="tipoSimulacao">Tipo simulação</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-5">
            <div className="field">
              <span className="p-float-label mt-2">
                <InputNumber
                  disabled={campoHabilitado}
                  prefix="R$ "
                  value={formik.values.valor}
                  onValueChange={formik.handleChange}
                  mode="decimal"
                  locale="de-DE"
                  id="valor"
                  name="valor"
                  currencyDisplay="code"
                  maxFractionDigits={2}
                  className={classNames({
                    "p-invalid": formik.touched.valor && formik.errors.valor,
                  })}
                />

                {formik.touched.valor && formik.errors.valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.valor}
                  </div>
                ) : null}
                <label htmlFor="valor">Valor</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="quantidadeParcelas"
                  value={formik.values.quantidadeParcelas}
                  onChange={formik.handleChange}
                  options={parcelasOptions}
                  optionLabel="label"
                  optionValue="id"
                  placeholder={formik.values.quantidadeParcelas}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.quantidadeParcelas &&
                      formik.errors.quantidadeParcelas,
                  })}
                  emptyMessage={
                    "Selecione o convênio para exibir as opções de parcelamento"
                  }
                />
                {formik.touched.quantidadeParcelas &&
                formik.errors.quantidadeParcelas ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.quantidadeParcelas}
                  </div>
                ) : null}
                <label htmlFor="quantidadeParcelas">
                  Quantidade de parcelas
                </label>
              </span>
            </div>
          </div>
        </div>
      </form>
      {showDados ? resultadoSimulacao : <></>}
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
