
import api from "./Api";
 
export class CidadesService {
    getByUf(id_uf) { 
    return   api
      .post(
        "administrativo/cidade/listarporuf",
        { id: id_uf },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
