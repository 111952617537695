import React, { useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import PropTypes from "prop-types";
import Papa from "papaparse";

export default function Exportar({ dt, cols, dadosService = [], titulo, inadimplente }) {
  const [visibleRight, setVisibleRight] = useState(false);
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const inadimplentes = inadimplente
    ? (dadosService || []).filter(
        (item) =>
          item.rzp_descricao === "Inadimplente" || item.rep_status_parcela === "Inadimplente"
      )
    : dadosService || [];

  const exportCSV = () => {
    const csvData = inadimplente ? inadimplentes : dadosService;

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `dados_export_${new Date().getTime()}.csv`;
    link.click();
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(inadimplentes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "dados");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l", 0);

        doc.autoTable(exportColumns, inadimplentes, {
          startY: 70,
          margin: { horizontal: 10 },
          styles: { overflow: "linebreak" },
          bodyStyles: { valign: "top" },
          theme: "striped",
          showHead: "everyPage",
          didDrawPage: function (data) {
            const currentTimestamp = Date.now();
            const date = new Intl.DateTimeFormat("pt-BR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(currentTimestamp);

            // Header
            doc.setFontSize(20);
            doc.setTextColor(40);
            const pageNumber = doc.internal.getNumberOfPages();
            const marginHeader = pageNumber === 1 ? 15 : 9;
            doc.text(
              "Relatório " + titulo + " - Emitido em: " + date,
              data.settings.margin.left,
              marginHeader
            );

            // Footer
            const str = "Página " + doc.internal.getNumberOfPages();
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
        doc.save("dados.pdf");
      });
    });
  };

  const linkExportar = () => {
    return (
      <div>
        <div className="my-2">
          <Button
            label="CSV"
            icon="pi pi-file"
            className="p-button mr-2 mb-2"
            onClick={exportCSV}
            tooltip="CSV"
          />
        </div>
        <div className="my-2">
          <Button
            label="PDF"
            icon="pi pi-file-pdf"
            onClick={exportPdf}
            className="p-button mr-2 mb-2"
            tooltip="PDF"
          />
        </div>
        <div className="my-2">
          <Button
            label="XlS"
            icon="pi pi-file-excel"
            onClick={exportExcel}
            className="p-button mr-2"
            tooltip="XLS"
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Button
          label={inadimplente ? "Inadimplentes" : "Exportar"}
          type="button"
          icon="pi pi-file"
          onClick={() => setVisibleRight(true)}
          className="p-button mr-2"
          disabled={inadimplente ? inadimplentes.length === 0 : (dadosService || []).length === 0}
        />
      </div>

      <Sidebar
        visible={visibleRight}
        onHide={() => setVisibleRight(false)}
        baseZIndex={1000}
        position="right"
      >
        <h1 style={{ fontWeight: "bolder" }}>Exportar</h1>
        {linkExportar()}
      </Sidebar>
    </React.Fragment>
  );
}

Exportar.propTypes = {
  dt: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  dadosService: PropTypes.array,
  titulo: PropTypes.string.isRequired,
  inadimplente: PropTypes.bool,
};

Exportar.defaultProps = {
  dadosService: [],
  inadimplente: false,
};
