import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { LogSistemaService } from "../../service/LogSistemaService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Log do Sistema";
  let _linkSistema_V = "/logSistemaForm_V/";

  const [logSistema, setLogSistema] = useState(null);
  const [selectedLogSistema, setSelectedLogSistema] = useState(null);
  const logSistemaService = new LogSistemaService();

  useEffect(() => {
    if (retornoDado.status === false) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    logSistemaService
      .getAll()
      .then(
        (data) =>
          data.status === true
            ? setLogSistema(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      );
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // const leftToolbarTemplate = () => {
  //   let _link_I = _linkSistema+"0/I";
  //   return (
  //     <React.Fragment>
  //       <div className="my-2">
  //         <Link to={_link_I}>
  //           <Button
  //             label="Cadastrar"
  //             icon="pi pi-plus"
  //             className="p-button-success mr-2"
  //           />
  //         </Link>
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "log_data_cadastro", header: "Data" },
      { field: "est_razao_social", header: "Estabelecimento" },
      { field: "usu_cpf", header: "Cpf" },
      { field: "usu_nome", header: "Usuário" },
      { field: "log_action", header: "Ação" },
    ];

    return (
      <React.Fragment>
        <Exportar titulo ={_header}  dt={dt} cols={cols} dadosService={logSistema} />
      </React.Fragment>
    );
  };
  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.log_id}
      </>
    );
  };
  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.log_data_cadastro}
      </>
    );
  };

  const CnpjBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CNPJ</span>
        {rowData.est_cnpj}
      </>
    );
  };
  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Estabelecimento</span>
        {rowData.est_razao_social}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CPF</span>
        {rowData.usu_cpf}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuário</span>
        {rowData.usu_nome}
      </>
    );
  };

  const moduloBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Módulo</span>
        {rowData.log_model}
      </>
    );
  };

  const acaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Ação</span>
        {rowData.log_action}
      </>
    );
  };

  const nRegistroBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">N° Registro</span>
        {rowData.log_idmodel}
      </>
    );
  };

  const descricaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descrição</span>
        {rowData.log_descricao}
      </>
    );
  };

  const ipBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Ip</span>
        {rowData.log_ip}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const actionBodyTemplate = (rowData) => {
    // let _link_U = _linkSistema + rowData.tco_id + "/U";
    let _link_V = _linkSistema_V + rowData.log_id + "/V";
    // let _acesso_U = "adm/tipos/convenio/alterar";
    let _acesso_V = "adm/log/logSistema/visualizar";
    return (
      <div className="grid">
        {/* <div className="col-6 md:col-6">
          <Link to={!permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-lock" : "pi pi-pencil"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div> */}
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            //left={leftToolbarTemplate}
            left={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={logSistema}
            selection={selectedLogSistema}
            onSelectionChange={(e) => setSelectedLogSistema(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="log_id"
              header="Id"
              sortable
              body={idBodyTemplate}
              headerStyle={{ width: "3%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="log_data_cadastro"
              header="Data"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "20%", minWidth: "10rem" }}
            ></Column>
            {/* <Column
              field="est_cnpj"
              header="CNPJ"
              sortable
              body={CnpjBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column> */}
            <Column
              field="est_razao_social"
              header="Estabelecimento"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "20%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_cpf"
              header="CPF"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "15%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="usu_nome"
              header="Usuário"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "15%", minWidth: "10rem" }}
            ></Column>
            {/* <Column
              field="log_model"
              header="Módulo"
              sortable
              body={moduloBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column> */}
            <Column
              field="log_action"
              header="Ação"
              sortable
              body={acaoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column>
            {/* <Column
              field="log_idmodel"
              header="N° Registro"
              sortable
              body={nRegistroBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column> */}
            {/* <Column
              field="log_descricao"
              header="Descrição"
              sortable
              body={descricaoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column> */}
            {/* <Column
              field="log_ip"
              header="Ip"
              sortable
              body={ipBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "1rem" }}
            ></Column> */}
            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
