import api from "./Api";


export class RepasseService {
  async getByRepasse(ope_id, con_id, ano, mes) {

    return await api
      .post("administrativo/repasseprocessadoras/neo-listar",
        {
          ope_id: ope_id,
          con_id: con_id,
          ano: ano,
          mes: mes
          // tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      .then((res) => res.data);
  }

  async postFile(file,extension,token) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("extension", extension);
    formData.append("tokenGoogleo", token);

    return await api.post("administrativo/repasseprocessadoras/neo-processar", formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

  async getByRepasseZetra(ope_id, con_id, ano, mes) {

    return await api
      .post("administrativo/repasseprocessadoras/zetra-listar",
        {
          ope_id: ope_id,
          con_id: con_id,
          ano: ano,
          mes: mes
          // tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      .then((res) => res.data);
  }

  async postFileZetra(file, convenio,token) {
    let formData = new FormData();
    formData.append("arquivo", file);
    formData.append("con_id", convenio);
    formData.append("ope_id", 2);
    formData.append("tokenGoogleo", token);

    return await api.post("administrativo/repasseprocessadoras/zetra-processar", formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

  async getByRepasseParquetec(ope_id, con_id, ano, mes) {

    return await api
      .post("administrativo/repasseprocessadoras/parquetec-listar",
        {
          ope_id: ope_id,
          con_id: con_id,
          ano: ano,
          mes: mes
          // tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      .then((res) => res.data);
  }

  async postFileParquetec(file, convenio,token) {
    let formData = new FormData();
    formData.append("arquivo", file);
    formData.append("con_id", convenio);
    formData.append("ope_id", 4);
    formData.append("tokenGoogleo", token);

    return await api.post("administrativo/repasseprocessadoras/parquetec-processar", formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

}
