import api from "./Api";



export class AgendaCorteService {
  async getAll() {
    return await api
      .get("/administrativo/agendacorte/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(con_id,con_ope_id) {
    return await api
      .post(
        "/administrativo/agendacorte/ByConvenio",
        {
          con_id: con_id,
          con_ope_id: con_ope_id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async getAllAnoMes() {
    return await api
      .get(
        "/administrativo/agendacorte/by-ano-mes",              
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async postAtualizar(id, dias, tokenGoogleo) {
    return await api
      .post(
        "/agenda-corte-atualizar",
        {
          con_id: id,
          con_proposta_dias_aguardo_aceite: dias,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getByConvenio(id_convenio,id_operadora) {
    return await api
      .post(
        "administrativo/agendacorte/byConvenio",
        { 
          con_id: id_convenio,
          con_ope_id : id_operadora
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getAgendaById(id) {
    return await api
      .post(
        "/agenda-id",
        { agc_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async postAgendaCadastrar(
    agc_con_id,
    agc_ope_id,
    agc_data_corte,    
    tokenGoogleo
  ) {
    return await api
      .post(
        "/administrativo/agendacorte/cadastrar",
        {
          agc_con_id: agc_con_id,
          agc_ope_id: agc_ope_id,                    
          agc_data_corte: agc_data_corte,          
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getGerarDadosCorte(id) {
    return await api
      .post(
        "administrativo/agendacorte/processar",
        { id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
