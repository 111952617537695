import api from "./Api";


export class ConvenioService {
  async getAll() {

    return await api
      .get("administrativo/convenio/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id,id_processadora) {
    return await api
      .post(
        "administrativo/convenio/visualizar",
        {
          con_id: id,
          con_ope_id: id_processadora
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getByOperadora(id) {
    return await api
      .post(
        "administrativo/convenio/listarConvenio",
        { con_ope_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }



  async postAtualizar(id,id_processadora,dias, diaRepasse, tokenConsulta, averbacaoToken,tokenGoogleo) {
    return await api
      .post(
        "administrativo/convenio/atualizar",
        {
          con_id: id,
          con_ope_id: id_processadora,
          con_proposta_dias_aguardo_aceite: dias,
          con_dia_repasse: diaRepasse,
          param_consulta_token: tokenConsulta,
          param_averbacao: averbacaoToken,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
