import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { UsuariosService } from "../../service/UsuariosService";
import SelectEst from "../../components/SelecionarEstabelecimento/SelectEst";

import { InformativosService } from "../../service/InformativosService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [estSelecionado, SetEstSelecinado] = useState(null);
  const [login, setLogin] = useState(emptyDados);
  const [tipoUsu, setTipoUsu] = useState(null);

  const toggle = (event) => {
    op.current.toggle(event);
  };
  const op = useRef(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/informativo";
  const cabecalho_form = "Informativos";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    inf_id: "",
    inf_descricao: "",
    inf_est_id: "",
    inf_data_cadastro: "",
    est_razao_social: "Todos",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const informativosService = new InformativosService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const informativosService = new InformativosService();
      informativosService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {              
          history.push("/semToken");        
      });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    toast.current.show({
      severity: "success",
      summary: "Estabelecimento selecionado",
      life: 3000,
    });
  }, [estSelecionado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const selectEst = (est_id) => {
    setModelDado(formik.values);
    SetEstSelecinado(est_id);
    return true;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inf_id: modelDado.inf_id,
      inf_descricao: modelDado.inf_descricao,
      inf_est_id:
        estSelecionado == null ? modelDado.inf_est_id : estSelecionado.id,
      inf_data_cadastro: modelDado.inf_data_cadastro,
      est_razao_social: modelDado.est_razao_social,
    },

    validationSchema: yup.object({
      inf_descricao: yup.string().required("O campo é obrigatório."),
    }),

    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        informativosService
          .postCadastrar(values.inf_est_id, values.inf_descricao)
          .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
          .catch((error) => {              
            history.push("/semToken");        
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              informativosService
                .postAtualizar(
                  values.inf_id,
                  values.inf_est_id,
                  values.inf_descricao,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>

        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-inputgroup">
              <span className="p-float-label  mt-5">
                <Button
                  type="button"
                  label="Selecione"
                  onClick={toggle}
                  className="p-button-sm"
                />
                <OverlayPanel ref={op} appendTo={document.body} showCloseIcon>
                  <SelectEst onSelect={selectEst}></SelectEst>
                </OverlayPanel>
                <InputText
                  disabled={true}
                  type="text"
                  id="inf_est_id"
                  name="inf_est_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    estSelecionado == null
                      ? formik.values.est_razao_social
                      : estSelecionado.razaoSocial
                  }
                  className={classNames({
                    "p-invalid":
                      formik.touched.inf_est_id && formik.errors.inf_est_id,
                  })}
                />

                <label htmlFor="inf_est_id">Estabelecimento do Usuário</label>
              </span>
            </div>
            {formik.touched.inf_est_id && formik.errors.inf_est_id ? (
              <div style={{ fontSize: 10, color: "red", marginLeft: "21%" }}>
                {formik.errors.inf_est_id}
              </div>
            ) : null}
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label mt-5 p-input-icon-right">
                <InputTextarea
                  disabled={campoHabilitado}
                  type="number"
                  id="inf_descricao"
                  name="inf_descricao"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.inf_descricao}
                  className={classNames({
                    "p-invalid":
                      formik.touched.inf_descricao &&
                      formik.errors.inf_descricao,
                  })}
                />
                {formik.touched.inf_descricao && formik.errors.inf_descricao ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.inf_descricao}
                  </div>
                ) : null}
                <label htmlFor="inf_descricao">Descrição</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
