import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { MetasService } from "../../service/MetasService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Metas";
  let _linkSistema_I = "/metasForm_I/";
  let _linkSistema_U = "/metasForm_U/";
  let _linkSistema_V = "/metasForm_V/";

  const [metas, setMetas] = useState(null);
  const [selectedMetas, setSelectedMetas] = useState(null);
  const metasService = new MetasService();

  useEffect(() => {
    if (retornoDado.status === false) {
       
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    metasService
      .getAll()
      .then(
        (data) =>
          data.status === true ? setMetas(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });
  }, []);

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/I";
    let _acesso_I = "adm/metas/incluir";

    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={permissaoAcesso(_acesso_I) && _link_I}>
            <Button
              label="Cadastrar"
              icon={permissaoAcesso(_acesso_I) ? "pi pi-plus" : "pi pi-lock"}
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "est_cnpj", header: "Cnpj" },
      { field: "est_razao_social", header: "Razão Social" },
      { field: "met_ano", header: "Ano" },
      { field: "met_mes", header: "Mês" },
      { field: "met_valor", header: "Valor" },
    ];
    return (
      <React.Fragment>
        <Exportar titulo ={_header}  dt={dt} cols={cols} dadosService={metas} />
      </React.Fragment>
    );
  };

  const cnpjBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cnpj</span>
        {rowData.est_cnpj}
      </>
    );
  };
  const razaoSocialbodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Razão Social</span>
        {rowData.est_razao_social}
      </>
    );
  };

  const anoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Ano</span>
        {rowData.met_ano}
      </>
    );
  };
  const mesBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Mês</span>
        {rowData.met_mes}
      </>
    );
  };
  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {"R$: " + rowData.met_valor.replace(".", ",")}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  }; 

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.met_id + "/U";
    let _link_V = _linkSistema_V + rowData.met_id + "/V";
    let _acesso_U = "adm/metas/alterar";
    let _acesso_V = "adm/metas/visualizar";
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={metas}
            selection={selectedMetas}
            onSelectionChange={(e) => setSelectedMetas(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_cnpj"
              header="CNPJ"
              sortable
              body={cnpjBodyTemplate}
              headerStyle={{ width: "27%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="est_razao_social"
              header="Razão Social"
              sortable
              body={razaoSocialbodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="met_ano"
              header="Ano"
              sortable
              body={anoBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="met_mes"
              header="Mês"
              sortable
              body={mesBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="met_valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
