import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { Column } from "primereact/column";
import formataData from "../../../components/formataData";
import { Toolbar } from "primereact/toolbar";
import { InputMask } from "primereact/inputmask";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { saveAs } from 'file-saver';

import { CidadesService } from "../../../service/CidadesService";
import { EstadoService } from "../../../service/EstadoService";
import { CorrespondenteService } from "../../../service/Servidor/CorrespondenteService";
import { triggerBase64Download } from "common-base64-downloader-react";

const Resgate_Form = () => {

  const [cidades, setCidades] = useState(null);
  const cidadesService = new CidadesService();
  const [estado, setEstado] = useState(null);
  const estadoService = new EstadoService();
  const [status_sexo, setStatusSexo] = useState(['M', 'F']);
  const [endereco_tipo, setEnderecoTipo] = useState(['Comercial', 'Residencial']);
  const [lista_uf, setListaUf] = useState(['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']);
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [arquivoDown, setArquivoDown] = useState(null);
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedResgate, setSelectedResgate] = useState(null);
  const [resgate, setResgate] = useState(null);
  const [parcelas, setParcelas] = useState([]);




  const dt = useRef(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/correspondente/";
  const cabecalho_form = "Correspondente";

  let header = <h5>{cabecalho_form} - Averbar Saque</h5>

  let emptyDados = {
    cav_cpf: null,
    cav_ope_id: null,
    cav_matricula: "",
    cav_nome: "",
    cav_saldo: "",

  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const correspondenteService = new CorrespondenteService();

  useEffect(() => {
    SetLoadingSpinner(true);
    correspondenteService
      .getResgateEmprestimoById(id)
      .then(
        (data) =>      
          data.status === true ? setResgate(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      );
  }, []);

  useEffect(() => {
    SetLoadingSpinner(true);
    const correspondenteService = new CorrespondenteService();
    correspondenteService
      .getById(id)
      .then((data) =>
        setModelDado(data.dados[0]),
        SetLoadingSpinner(false));

  }, []);

  useEffect(() => {
    formik.setValues(modelDado);

    if (formik.values.parcelasPagar != "") {
      let qtdeParcelas = modelDado['con_qtd_parcelas']
      setParcelas(qtdeParcelas.split("#"))
    }
  }, [modelDado]);




  useEffect(() => {
    SetLoadingSpinner(false)
    if (retornoDado.status !== null) {
      if (isset(retornoDado.code)) {
        history.push("/semToken");
      }
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });


      if (retornoDado.status) {
        setTimeout(() => {
          history.push("/correspondente");
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({

    enableReinitialize: true,
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_cpf: modelDado.cav_cpf,
      cav_nome: modelDado.cav_nome,
      cav_matricula: modelDado.cav_matricula,
      parcelasPagar: "",
      valor_resgate: 0.00,
      arquivo: null
    },
    validationSchema: yup.object().shape({
      valor_resgate: yup.string().required("O campo é obrigatório.").nullable(),
      parcelasPagar: yup.string().required("O campo é obrigatório.").nullable(),
      arquivo: yup.mixed()
        .required('O campo é obrigatório')
        .test('fileType', 'Tipo de arquivo inválido', (value) => {
          return value && ['application/pdf'].includes(value.type);
        }),
    }),
    onSubmit: (values) => {

      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {

            correspondenteService
              .postResgatarEmprestimo(
                values.cav_id,
                values.valor_resgate,
                values.arquivo,
                values.parcelasPagar,
                token
              )
              .then((data) => setRetornoDado(data));
          });
      });
    },
  });

  const buttonHeader = (
    <div className="grid">
      <div className="mr-2 mb-2">
        <Button
          type="submit"
          label="Averbar"
          className="p-button-raised p-button-success "
          onClick={formik.handleSubmit}
        />
      </div>

      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>

    </div>
  );


  const leftToolbarTemplate = () => {
    const cols = [
      { field: "data", header: "Data" },
      { field: "valor", header: "Valor" },
      { field: "status", header: "Status" },
    ];
  }



  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {formataData(rowData.data)}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.matricula}
      </>
    );
  };



  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {rowData.valor}
      </>
    );
  };

  const qtdeParcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.eml_qtdeparcelas}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.tra_credito ? 'Crédito' : 'Débito'}
      </>
    );
  };


  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cpf}
      </>
    );
  };

  const usuarioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Usuar'io</span>
        {rowData.usuario}
      </>
    );
  };


  const creditoUtilizadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.utilizado_credito}
      </>
    );
  };


  function checkValue(event) {
    formik.values.valor_resgate = handleDecimalsOnValue(event.target.value);

  }

  function handleDecimalsOnValue(value) {
    // Remove todos os caracteres que não são dígitos
    const sanitizedValue = value.replace(/[^0-9]/g, '');


    // Verifica se o valor está vazio ou não é um número válido
    if (!sanitizedValue) {
      return '';
    }

    // Divide o valor em parte inteira e parte decimal
    const integerPart = sanitizedValue.slice(0, -2);
    const decimalPart = sanitizedValue.slice(-2);

    // Combine a parte inteira e a parte decimal com uma vírgula
    const resultValue = `${integerPart},${decimalPart}`;

    return resultValue;
  }


  const handleChangeDecimal = (e) => {
    const { name, value } = e.target;
    const formattedValue = handleDecimalsOnValue(value);
    formik.setFieldValue(name, formattedValue);

  };


  const actionBodyTemplate = (rowData) => {
    let id = rowData.tra_id;
    let nomeDoc = rowData.tra_comprovante_averbacao;
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Button
            icon={"pi pi-download"}
            className="p-button-rounded p-button-warning mt-12"
            onClick={() => baixarPdf(id, nomeDoc)}
          />
        </div>
      </div>
    );
  };

  const baixarPdf = (id, nomeDoc) => {
    SetLoadingSpinner(true);
    correspondenteService
      .getDownloadById(id)
      .then(
        (data) => downloadPDF(data.dados),
        SetLoadingSpinner(false)
      );
  };


  function downloadPDF(pdf) {
    const linkSource = pdf;
    const downloadLink = document.createElement("a");
    const fileName = id + "__comprovante_averbacao_emprestimo.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      {header}
      {buttonHeader}
      <TabView>



        <TabPanel header="Dados Pessoais">
          <form encType="multipart/form-data" >
            <div className="grid">



              <div className="col-12 md:col-3">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputMask
                      mask="999.999.999-99"
                      unmask={true}
                      disabled={true}
                      type="text"
                      id="cav_cpf"
                      name="cav_cpf"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_cpf}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_cpf && formik.errors.cav_cpf,
                      })}
                    />
                    {formik.touched.cav_cpf && formik.errors.cav_cpf ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_cpf}
                      </div>
                    ) : null}
                    <label htmlFor="cav_cpf">CPF</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      disabled={true}
                      type="text"
                      id="cav_matricula"
                      name="cav_matricula"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_matricula}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_matricula &&
                          formik.errors.cav_matricula,
                      })}
                    />
                    {formik.touched.cav_matricula &&
                      formik.errors.cav_matricula ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_matricula}
                      </div>
                    ) : null}
                    <label htmlFor="cav_matricula">Matrícula</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-7">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <InputText
                      disabled={true}
                      type="text"
                      id="cav_nome"
                      name="cav_nome"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cav_nome}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_nome &&
                          formik.errors.cav_nome,
                      })}
                    />
                    {formik.touched.cav_nome &&
                      formik.errors.cav_nome ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_nome}
                      </div>
                    ) : null}
                    <label htmlFor="cav_nome">Nome</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-4">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <InputText
                      id="valor_resgate"
                      name="valor_resgate"
                      disabled={campoHabilitado}
                      maxLength={8}
                      type="text"
                      value={formik.values.valor_resgate}
                      onChange={handleChangeDecimal}
                    />

                    {formik.touched.valor_resgate && formik.errors.valor_resgate ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.valor_resgate}
                      </div>
                    ) : null}
                    <label htmlFor="valor_resgate">Digite o valor a averbar *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="parcelasPagar"
                      value={formik.values.parcelasPagar}
                      disabled={campoHabilitado}
                      onChange={formik.handleChange}
                      options={parcelas}
                      placeholder={formik.values.parcelasPagar}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.parcelasPagar &&
                          formik.errors.parcelasPagar,
                      })}
                    />
                    {formik.touched.parcelasPagar &&
                      formik.errors.parcelasPagar ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.parcelasPagar}
                      </div>
                    ) : null}
                    <label htmlFor="parcelasPagar">Parcelas *</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="arquivo">Obrigatório comprovante da Averbação ( .pdf ) *</label>
                <div className="field">
                  <span className="p-float-label mt-2">

                    <input
                      id="arquivo"
                      name="arquivo"
                      type="file"
                      onChange={(event) => formik.setFieldValue('arquivo', event.target.files[0])}
                    />
                    {formik.touched.arquivo && formik.errors.arquivo ? (
                      <div style={{ color: 'red' }}>{formik.errors.arquivo}</div>
                    ) : null}

                  </span>
                </div>
              </div>


            </div>

          </form>
        </TabPanel>
        <TabPanel header="Histórico">
          <div className="card">
            <DataTable
              ref={dt}
              value={resgate}
              selection={selectedResgate}
              onSelectionChange={(e) => setSelectedResgate(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
              globalFilter={globalFilter}
              emptyMessage="Não há dados."
              responsiveLayout="scroll"
            >
              <Column
                field="utilizado_credito"
                header="Saque"
                sortable
                body={creditoUtilizadoBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "14rem" }}
              ></Column>

              <Column
                field="data"
                header="Data"
                sortable
                body={dataBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "14rem" }}
              ></Column>
              <Column
                field="eml_qtdeparcelas"
                header="Nº Parcelas"
                sortable
                body={qtdeParcelasBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "2rem" }}
              ></Column>
              <Column
                field="valor"
                header="Valor"
                sortable
                body={valorBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "2rem" }}
              ></Column>
              <Column
                field="usuario"
                header="Usuário"
                sortable
                body={usuarioBodyTemplate}
                headerStyle={{ width: "10%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="cpf"
                header="Cpf"
                sortable
                body={cpfBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="tra_credito"
                header="Status"
                sortable
                body={statusBodyTemplate}
                headerStyle={{ width: "1%", minWidth: "2rem" }}
              ></Column>
              <Column header="Ações"
                body={actionBodyTemplate}
                headerStyle={{ minWidth: "15rem" }}

              ></Column>
            </DataTable>

          </div>
        </TabPanel>
      </TabView>

      {loadingSpinner ? <LoadingSpinner visualiza={loadingSpinner} /> : ''}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Resgate_Form, comparisonFn);
