import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

import { ConvenioService } from "../../service/ConvenioService";

const _Form = () => {
  const isset = (ref) => typeof ref !== 'undefined'
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const history = useHistory();
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams();
  let { id_processadora } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/convenio";
  const cabecalho_form = "Convênio";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    con_id: null,
    con_cnpj: "",
    con_razao_social: "",
    con_ativo: false,
    con_proposta_dias_aguardo_aceite: 0,
    con_dia_repasse: null,
    prc_libera_saque: undefined,
    prc_libera_cartao: undefined,
    prc_token_consulta: undefined,
    prc_token_averbacao: undefined,
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);

  const [tokenConsulta, setTokenConsulta] = useState(false);
  const [averbacaoToken, setAverbacaoToken] = useState(false);

  const convenioService = new ConvenioService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const convenioService = new ConvenioService();
   
      convenioService
        .getById(id,id_processadora)
        .then((data) => 
          setModelDado(data.dados), SetLoadingSpinner(false),
        )
        .catch((error) => {              
          history.push("/semToken");        
      });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
       
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      con_id: modelDado.con_id,
      con_cnpj: modelDado.con_cnpj,
      con_razao_social: modelDado.con_razao_social,
      con_ativo: modelDado.con_ativo,
      con_proposta_dias_aguardo_aceite: modelDado.con_proposta_dias_aguardo_aceite,
      con_dia_repasse: modelDado.con_dia_repasse,
    },
    validationSchema: yup.object({
      con_proposta_dias_aguardo_aceite: yup
        .number()
        .integer(({ numero }) => `Prazo em dias deve ser positivo.`)
        .max(30, ({ max }) => `Prazo no máximo ${max} dias.`)
        .default(1)
        .positive(({ prazo }) => `Prazo em dias deve ser positivo.`)
        .required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        // SetLoadingSpinner(true);
        // convenioService
        //   .postCadastrar(values.tco_descricao)
        //   .then((data) => setRetornoDado(data), SetLoadingSpinner(false));
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              convenioService
                .postAtualizar(
                  values.con_id,
                  id_processadora,
                  Number(values.con_proposta_dias_aguardo_aceite),
                  Number(values.con_dia_repasse),
                  tokenConsulta,
                  averbacaoToken,
                  token
                )
                .then((data) => setRetornoDado(data),SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  const setInitialCheckbox = (consulta, averbacao) => {

    const checkboxesConsulta = document.querySelectorAll('.checkbox_consulta');
    checkboxesConsulta.forEach(checkbox => {
      checkbox.addEventListener('change', function () {
        if (this.checked) {
          checkboxesConsulta.forEach(cb => {
            if (cb !== this) {
              cb.checked = false;
            }
          });
        } else if(!this.checked){
          checkboxesConsulta.forEach(cb => {
            if (cb !== this) {
              cb.checked = true;
            }
          });
        }
      });
    });

    const checkboxesAverbacao = document.querySelectorAll('.checkbox_averbacao');
    checkboxesAverbacao.forEach(checkbox => {
      checkbox.addEventListener('change', function () {
        if (this.checked) {
          checkboxesAverbacao.forEach(cb => {
            if (cb !== this) {
              cb.checked = false;
            }
          });
        } else if(!this.checked){
          checkboxesAverbacao.forEach(cb => {
            if (cb !== this) {
              cb.checked = true;
            }
          });
        }
      });
    });

    if(consulta){
      document.getElementById("consulta-2").checked = true
      setTokenConsulta(true)
    } else {
      document.getElementById("consulta-1").checked = true
      setTokenConsulta(false)
    }

    if(averbacao){
      document.getElementById("averbacao-2").checked = true
      setAverbacaoToken(true)
    } else {
      document.getElementById("averbacao-1").checked = true
      setAverbacaoToken(false)
    }

  }

  useEffect(() => {

    const convenioService = new ConvenioService();
   
    convenioService
      .getById(id,id_processadora)
      .then((data) => {
        setModelDado(data.dados)
        SetLoadingSpinner(false)
        setInitialCheckbox(data.dados.prc_token_consulta, data.dados.prc_token_averbacao)
      })
      .catch((error) => {              
        history.push("/semToken");        
    });

  }, []);

  return (
    <>
      <form>
        <div className="card p-fluid">
          <Toast ref={toast} />
          {header}
          {buttonHeader}
          <div className="grid">
            <div className="col-12 md:col-3">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputText
                    disabled={true}
                    type="text"
                    id="con_cnpj"
                    name="con_cnpj"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.con_cnpj}
                    className={classNames({
                      "p-invalid":
                        formik.touched.con_cnpj && formik.errors.con_cnpj,
                    })}
                  />
                  {formik.touched.con_cnpj && formik.errors.con_cnpj ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.con_cnpj}
                    </div>
                  ) : null}
                  <label htmlFor="con_cnpj">CNPJ</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-7">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputText
                    disabled={true}
                    type="text"
                    id="con_razao_social"
                    name="con_razao_social"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.con_razao_social}
                    className={classNames({
                      "p-invalid":
                        formik.touched.con_razao_social &&
                        formik.errors.con_razao_social,
                    })}
                  />
                  <label htmlFor="con_razao_social">Razão Social</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-2">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputText
                    disabled={campoHabilitado}
                    type="text"
                    id="con_proposta_dias_aguardo_aceite"
                    name="con_proposta_dias_aguardo_aceite"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.con_proposta_dias_aguardo_aceite}
                    className={classNames({
                      "p-invalid":
                        formik.touched.con_proposta_dias_aguardo_aceite &&
                        formik.errors.con_proposta_dias_aguardo_aceite,
                    })}
                  />
                  {formik.touched.con_proposta_dias_aguardo_aceite &&
                  formik.errors.con_proposta_dias_aguardo_aceite ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.con_proposta_dias_aguardo_aceite}
                    </div>
                  ) : null}
                  <label htmlFor="con_proposta_dias_aguardo_aceite">
                    Dias proposta
                  </label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-2">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputText
                    disabled={campoHabilitado}
                    type="text"
                    id="con_dia_repasse"
                    name="con_dia_repasse"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.con_dia_repasse}
                    className={classNames({
                      "p-invalid":
                        formik.touched.con_dia_repasse &&
                        formik.errors.con_dia_repasse,
                    })}
                  />
                  {formik.touched.con_dia_repasse &&
                  formik.errors.con_dia_repasse ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.con_dia_repasse}
                    </div>
                  ) : null}
                  <label htmlFor="con_dia_repasse">
                    Dia Repasse
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-fluid">
          <h4><strong>Parâmetros</strong></h4>
          <div className="grid">
            <div className="p-2 mt-4">
              <h5>
                Consulta via API (Consulta de margem)
              </h5>
              <div className="flex flex-column gap-3">
                <div>
                  <input type="checkbox" name="prc_token_consulta" id="consulta-1" className="checkbox_consulta"
                    onChange={()=>setTokenConsulta(false)}
                  /> 
                  <span>Não utiliza token</span>
                </div>
                <div>
                  <input type="checkbox" name="prc_token_consulta_token" id="consulta-2" className="checkbox_consulta"
                    onChange={()=>setTokenConsulta(true)}
                  /> 
                  <span>Token</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="p-2 mt-4">
              <h5>
                Averbação
              </h5>
              <div className="flex flex-column gap-3">
                <div>
                  <input type="checkbox" name="prc_token_averbacao_senha" id="averbacao-1" className="checkbox_averbacao"
                    onChange={()=>setAverbacaoToken(false)}
                  /> 
                  <span>
                    Senha de Consignação
                  </span>
                </div>
                <div>
                  <input type="checkbox" name="prc_token_averbacao_token" id="averbacao-2" className="checkbox_averbacao"
                    onChange={()=>setAverbacaoToken(true)}
                  /> 
                  <span>
                    Token
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingSpinner visualiza={loadingSpinner} />
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
