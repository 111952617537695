import React, { useState, useEffect, useRef, useCallback } from "react";
import { Chart } from 'primereact/chart';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PropTypes from "prop-types";


export function GraficoLogEstatisticoApp({labels,data1,data2,data3,data4,data5,data6,data7,data8,data9,ano}) {


    const data = {
        labels: labels,
        datasets: [
            {
                label: data1.title,
                data: data1.value,
                fill: true,
                borderColor: '#91c145',
                color: '#91c145',
                backgroundColor: "#91c145",
                stack: 'Stack 0',
            },
            {
                label: data2.title,
                data: data2.value,
                fill: true,
                borderColor: '#7fa63c',
                color: '#7fa63c',
                backgroundColor: "#7fa63c",
                stack: 'Stack 0',

            },
            {
                label: data3.title,
                data: data3.value,
                fill: true,
                borderColor: '#6d8e34',
                color: '#6d8e34',
                backgroundColor: "#6d8e34",
                stack: 'Stack 0',
            },
            {
                label: data4.title,
                data: data4.value,
                fill: true,
                borderColor: '#5b772c',
                color: '#5b772c',
                backgroundColor: "#5b772c"
            },
            {
                label: data5.title,
                data: data5.value,
                fill: true,
                borderColor: '#495f24',
                color: '#495f24',
                backgroundColor: "#495f24",
                stack: 'Stack 1',
            },
            {
                label: data6.title,
                data: data6.value,
                fill: true,
                borderColor: '#37471c',
                color: '#37471c',
                backgroundColor: "#37471c",
                stack: 'Stack 1',
            },
            {
                label: data7.title,
                data: data7.value,
                fill: true,
                borderColor: '#263f1a',
                color: '#263f1a',
                backgroundColor: "#263f1a",
                stack: 'Stack 1',
            },
            {
                label: data8.title,
                data: data8.value,
                fill: true,
                borderColor: '#1a2e14',
                color: '#1a2e14',
                backgroundColor: "#1a2e14",
                stack: 'Stack 2',
            },
            {
                label: data9.title,
                data: data9.value,
                fill: true,
                borderColor: '#0d170a',
                color: '#0d170a',
                backgroundColor: "#0d170a",
                stack: 'Stack 2',
            },
        ]
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Estatística geral de ações realizadas no APP ",
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'bottom'
            }
        }
    }

    return (
        <Chart type="bar" data={data} options={options} />
     )
}