import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../components/LoadingSpinner";
import { RelatorioService } from "../../service/RelatorioService";
import { InputMask } from "primereact/inputmask";
import Exportar from "../../components/Exportar";
import { useHistory } from "react-router-dom";
/* Pesquisar */
import { useFormik } from "formik";
import * as yup from "yup";

import classNames from "classnames";

/* Pesquisar */

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Empréstimos";

  const retornoDados = {
    status: null,
    msg: "",
  };

  const [compras, setCompras] = useState(null);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [selectedCompras, setSelectedCompras] = useState(null);
  const relatorioService = new RelatorioService();

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const dia = dataPraF.toString().slice(0, 2);
    const mes = dataPraF.toString().slice(3, 5);
    const ano = dataPraF.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  useEffect(() => {
    SetLoadingSpinner(true);
    relatorioService
      .emprestimoListar(
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString()),
        formataDataInicialFinal(new Date(Date.now()).toLocaleDateString()),
        ""
      )
      .then(
        (data) =>
          data.status === true ? setCompras(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  useEffect(() => {
    if (retornoDado.status === false) {
      setCompras(null);
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  /*  Pesquisar   */
  const formik = useFormik({
    initialValues: {
      dataInicial: new Date(Date.now()).toLocaleDateString(),
      dataFinal: new Date(Date.now()).toLocaleDateString(),
    },
    validationSchema: yup.object({
      dataInicial: yup.string().required("O campo é obrigatório."),
      dataFinal: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      relatorioService
        .emprestimoListar(
          formataDataInicialFinal(values.dataInicial),
          formataDataInicialFinal(values.dataFinal),
          values.cnpj
        )
        .then(
          (data) =>
            data.status === true
              ? setCompras(data.dados)
              : setRetornoDado(data),
          SetLoadingSpinner(false)
        )
        .catch((error) => {
          history.push("/semToken");
        });
    },
  });
  /* Pesquisar */

  const leftToolbarTemplate = () => {
    const cols = [
      { field: "est_nome_fantasia", header: "Estabelecimento" },
      { field: "ope_nome", header: "Processadora" },
      { field: "con_nome_fantasia", header: "Convênio" },
      { field: "trr_proposta_numero", header: "Número proposta" },
      { field: "trr_data_cadastro", header: "Cadastrado em" },
      { field: "trr_data_fim_recorrencia", header: "Vigência Até" },
      { field: "trr_data_cancelamento", header: "Cancelado em" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_matricula", header: "Matrícula" },
      { field: "cav_nome", header: "Nome" },
      { field: "cav_celular", header: "Celular" },
      { field: "cav_email", header: "Email" },
      { field: "trr_qtde_parcelas", header: "Nº Parcelas" },
      { field: "trr_valor", header: "Valor Parcelas" },
      { field: "valor_total", header: "Valor Liberado" },
      { field: "valor_montante", header: "Valor Montante" },
      { field: "tes_descricao", header: "Status" },
    ];
    return (
      <React.Fragment>
        <form>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <span className="p-float-label mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataInicial"
                    name="dataInicial"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataInicial}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataInicial && formik.errors.dataInicial,
                    })}
                  />
                  {formik.touched.dataInicial && formik.errors.dataInicial ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataInicial">Data Inicial</label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <span className="p-float-label  mt-5">
                  <InputMask
                    mask={"99/99/9999"}
                    type="text"
                    id="dataFinal"
                    name="dataFinal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataFinal}
                    className={classNames({
                      "p-invalid":
                        formik.touched.dataFinal && formik.errors.dataFinal,
                    })}
                  />
                  {formik.touched.dataFinal && formik.errors.dataFinal ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.dataInicial}
                    </div>
                  ) : null}
                  <label htmlFor="dataFinal">Data Final</label>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-4">
              <div className="field">
                <Button
                  type="submit"
                  label="Pesquisar"
                  icon="pi pi-search"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="field">
                <Exportar
                  titulo={_header}
                  dt={dt}
                  cols={cols}
                  dadosService={compras}
                />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };

  const opeNomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Processadora</span>
        {rowData.ope_nome}
      </>
    );
  };

  const convenioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Processadora</span>
        {rowData.con_nome_fantasia}
      </>
    );
  };

  const dataBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.trr_data_cadastro}
      </>
    );
  };

  const propostaNumeroTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data</span>
        {rowData.trr_proposta_numero}
      </>
    );
  };

  const celularBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Celular</span>
        {rowData.cav_celular}
      </>
    );
  };

  const emailBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">E-mail</span>
        {rowData.cav_email}
      </>
    );
  };

  const dataFimBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Fim</span>
        {rowData.trr_data_fim_recorrencia == null
          ? "---"
          : rowData.trr_data_fim_recorrencia}
      </>
    );
  };

  const dataCancelamentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cancelamento</span>
        {rowData.trr_data_cancelamento == null
          ? "---"
          : rowData.trr_data_cancelamento}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        {rowData.tes_descricao}
      </>
    );
  };

  const parcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nº Parcelas</span>
        {rowData.trr_qtde_parcelas}
      </>
    );
  };

  const valorParcelaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor parcela</span>
        {rowData.trr_valor}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor Liberado</span>
        {rowData.valor_total}
      </>
    );
  };

  const valorMontanteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor Montante</span>
        {rowData.valor_montante}
      </>
    );
  };

  const estabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Sistema</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={compras}
            selection={selectedCompras}
            onSelectionChange={(e) => setSelectedCompras(e.value)}
            dataKey="id"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 250, 5000]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_nome_fantasia"
              header="Empresa"
              sortable
              body={estabelecimentoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="ope_nome"
              header="Processadora"
              sortable
              body={opeNomeBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="con_nome_fantasia"
              header="Convênio"
              sortable
              body={convenioBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="trr_proposta_numero"
              header="Proposta número"
              sortable
              body={propostaNumeroTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="trr_data_cadastro"
              header="Data"
              sortable
              body={dataBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "14rem" }}
            ></Column>
            <Column
              field="trr_data_fim_recorrencia"
              header="Vigência Até"
              sortable
              body={dataFimBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="trr_data_cancelamento"
              header="Cancelado em"
              sortable
              body={dataCancelamentoBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "1%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "3%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_celular"
              header="Celular"
              sortable
              body={celularBodyTemplate}
              headerStyle={{ width: "3%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="cav_email"
              header="E-mail"
              sortable
              body={emailBodyTemplate}
              headerStyle={{ width: "3%", minWidth: "2rem" }}
            ></Column>
            <Column
              field="trr_qtde_parcelas"
              header="Nº Parcelas"
              sortable
              body={parcelasBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "2rem" }}
            ></Column>

            <Column
              field="trr_valor"
              header="Valor da parcela"
              sortable
              body={valorParcelaBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="valor_total"
              header="Valor Liberado"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="valor_montante"
              header="Montante"
              sortable
              body={valorMontanteBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="tes_descricao"
              header="Status"
              sortable
              body={statusBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
