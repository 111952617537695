 
import { RotatingLines } from "react-loader-spinner";

export default function LoadingSpinner({ visualiza }) {
  return (
    // <div className="card ">
      <div className="center-form" style={{zIndex: 10000}}>    
        <RotatingLines
          strokeColor="blue"
          strokeWidth="2"
          animationDuration="1.0"
          width="300"
          visible={visualiza}
        />

      </div>
    // </div>
  );
}
