import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { CartaoVirtualService } from "../../service/Servidor/CartaoVirtualService";
import { TipoEstabelecimentoService } from "../../service/TipoEstabelecimentoService";
import { CorrespondenteService } from "../../service/Servidor/CorrespondenteService";
import { TipoPlanoService } from "../../service/TipoPlanoService";

import Calendar from "react-calendar";

const _Form = () => {

  let emptyDados = {
    cav_id: null,
    cav_nome: "",
    cav_cpf: "",
    cav_matricula: "",
    cav_saldo: null
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  let { id } = useParams();

  const link_voltar = "/correspondente";
  const cabecalho_form = "Seguros";
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";

  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [tipoPlano, setTipoPlano] = useState(null);
  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const tipoEstabelecimentoService = new TipoEstabelecimentoService();
  const cartaoVirtualService = new CartaoVirtualService();
  const tipoPlanoService = new TipoPlanoService();
  const [camposJSX, setCamposJSX] = useState([]);
  const link_PlanosServidor = "/correspondenteEmprestimo_Index/" + id;
  const correspondenteService = new CorrespondenteService();



  let header =

    <h5>{cabecalho_form}</h5>


  useEffect(() => {
    cartaoVirtualService
      .getById(id)
      .then((data) =>
        setModelDado(data.dados[0]),
        SetLoadingSpinner(false));

    tipoPlanoService
      .getByIdEstabelecimento()
      .then((data) => setTipoPlano(data.dados),
        SetLoadingSpinner(false))
      .catch((error) => {
        history.push("/semToken");
      });

  }, []);


  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);


  const formik = useFormik({
    initialValues: {
      cav_id: modelDado.cav_id,
      cav_nome: modelDado.cav_nome,
      cav_matricula: modelDado.cav_matricula,
      cav_saldo: modelDado.cav_saldo,
      ser_id: ""
    },
    validationSchema: yup.object({
      ser_id: yup.string().required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            correspondenteService
              .getComprarSeguros(values.cav_id, values.ser_id, token)
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });

    },
  });


  const handleSalvarClick = () => {
    const confirmacao = window.confirm('Você tem certeza de que deseja comprar o plano?');

    if (confirmacao) {
      formik.handleSubmit();
    } else {
      formik.setFieldValue(formik.values.ser_id, null);
      return true
    }
  }


  const buttonHeader = (
    <div className="grid">

      <div className="mr-2 mb-2">
        <Button
          type="button"
          label="Comprar"
          className="p-button-raised p-button-success "
          onClick={handleSalvarClick}
        />
      </div>


      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_PlanosServidor}>
          <Button type="submit" label="Histórico de Compras" className="p-button-raised" />
        </Link>
      </div>
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>

    </div>
  );

  function apresentaPlanos() {
    const objetoEncontrado = tipoPlano.find(item => item.ser_id === formik.values.ser_id);

    if (objetoEncontrado == null) {
      return;
    }
    const ser_coberturas = objetoEncontrado.ser_coberturas;
    const campos = ser_coberturas.split("#");

    // Mapear os campos para criar elementos JSX
    const elementosJSX = campos.map((campo, index) => (
      <div className="grid">


        {index % 2 === 1 ?
          <div className="col-12 md:col-6">
            <div className="field">
              <label key={index}>{campo.trim()} </label>
            </div>
          </div>
          :
          <div className="col-12 md:col-6">
            <div className="field">
              <h5 key={index}>{campo.trim()}</h5>
            </div>
          </div>

        }
      </div >

    ));
    // Atualize o estado com os elementos JSX
    setCamposJSX(elementosJSX);
  }


  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        <br></br>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label">
                <InputText
                  disabled={true}
                  value={formik.values.cav_cpf}
                />
                <label htmlFor="cav_cpf">CPF</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label">
                <InputText
                  disabled={true}
                  value={formik.values.cav_matricula}
                />
                <label htmlFor="cav_matricula">Matrícula</label>
              </span>

            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="field">
              <span className="p-float-label">
                <InputText
                  disabled={true}
                  value={formik.values.cav_nome}
                />
                <label htmlFor="cav_nome">Nome</label>
              </span>
            </div>
          </div>
          {modelDado.cav_ope_id != 1 ?
            <div className="col-12 md:col-2">
              <div className="field">
                <span className="p-float-label">
                  <InputText
                    disabled={true}
                    value={formik.values.cav_saldo}
                  />
                  <label htmlFor="cav_saldo">Saldo</label>
                </span>
              </div>
            </div>
            : null}
        </div>



        {buttonHeader}
        <br></br>
        <div className="field">
          <span className="p-float-label mt-2">
            <Dropdown
              id="ser_id"
              value={formik.values.ser_id}
              onChange={formik.handleChange}
              options={tipoPlano}
              optionLabel={(option) => `${option.ser_descricao} - Valor do plano: R$ ${option.ser_valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
              optionValue="ser_id"
              onHide={apresentaPlanos}
              placeholder={formik.values.ser_id}
              onBlur={formik.handleBlur}
              className={classNames({
                "p-invalid":
                  formik.touched.ser_id &&
                  formik.errors.ser_id,
              })}
            />
            {formik.touched.ser_id &&
              formik.errors.ser_id ? (
              <div style={{ fontSize: 10, color: "red" }}>
                {formik.errors.ser_id}
              </div>
            ) : null}
            <label htmlFor="ser_id">Planos</label>
          </span>
        </div>
        <div className="field">
          {camposJSX}
        </div>
      </form>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
