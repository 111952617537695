import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Dropdown } from "primereact/dropdown";

import { CartaoVirtualService, cartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";


const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";

  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [tipoStatus, SetTipoStatus] = useState([
    { item: true, descricao: "Quitado" },
    { item: false, descricao: "Pendente de Pagamento" },
  ]);

  let { id } = useParams();
  let { tep_id } = useParams();
  let { trr_id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/planosEmprestimoParcelasIndex/" + id + "/" + trr_id;
  const cabecalho_form = "Parcelas";

  let header =
    <h5>{cabecalho_form} - Alterar</h5>

  let emptyDados = {
    tep_motivo: "",
    tep_status: true
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState([]);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const cartaoVirtualService = new CartaoVirtualService();

  useEffect(() => {
    SetLoadingSpinner(true);
    const cartaoVirtualService = new CartaoVirtualService();
    cartaoVirtualService
      .planosEmprestimoVisualizarParcelaServidor(tep_id)
      .then((data) =>
        setModelDado(data.dados[0])
       )
      .catch((error) => {
        history.push("/semToken");
      });


  }, [id, tipo]);

  useEffect(() => {    
      formik.setValues(modelDado);

  }, [modelDado]);

  useEffect(() => {
    SetLoadingSpinner(false)
    if (retornoDado.status !== null) {

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      tep_motivo: modelDado.tep_motivo,
      tep_status: modelDado.tep_status,
    },
    validationSchema: yup.object({
      tep_motivo: yup
        .string()
        .min(5, ({ min }) => `Descrição deve ter no mínimo ${min} caracteres`)
        .max(100, ({ max }) => `Descrição deve ter no máximo ${max} caracteres`)
        .required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            cartaoVirtualService
              .planosEmprestimoAtualizarParcelaServidor(tep_id, values.tep_motivo, values.tep_status, token)
              .then((data) => setRetornoDado(data))
              // , SetLoadingSpinner(false))
              .catch((error) => {
                // history.push("/semToken");        
              });
          });
      });

    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <br></br>

        <div className="field">
          <span className="p-float-label  mt-5  col-6 md:col-4">
            <Dropdown
              id="tep_status"
              value={formik.values.tep_status}
              onChange={formik.handleChange}
              options={tipoStatus}
              optionLabel="descricao"
              optionValue="item"
              placeholder={formik.values.tep_status}
              onBlur={formik.handleBlur}
              disabled={campoHabilitado}
              className={classNames({
                "p-invalid":
                  formik.touched.tep_status &&
                  formik.errors.tep_status,
              })}
            />
            {formik.touched.tep_status &&
              formik.errors.tep_status ? (
              <div style={{ fontSize: 10, color: "red" }}>
                {formik.errors.tep_status}
              </div>
            ) : null}
            <label htmlFor="tep_status">Status</label>
          </span>
        </div>

        <div className="field ">
          <span className="p-float-label   col-12 md:col-12">
            <InputText
              disabled={campoHabilitado}
              type="text"
              id="tep_motivo"
              name="tep_motivo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tep_motivo}
              className={classNames({
                "p-invalid":
                  formik.touched.tep_motivo && formik.errors.tep_motivo,
              })}
            />
            {formik.touched.tep_motivo && formik.errors.tep_motivo ? (
              <div style={{ fontSize: 10, color: "red" }}>
                {formik.errors.tep_motivo}
              </div>
            ) : null}
            <label htmlFor="tep_motivo">Motivo da alteração</label>
          </span>
        </div>

        { formik.values.usu_nome != null ?
        <div className="field ">
          <span className="p-float-label   col-12 md:col-12">
            <InputText
              disabled={true}
              type="text"
              id="usu_nome"
              name="usu_nome"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.usu_nome}
              className={classNames({
                "p-invalid":
                  formik.touched.usu_nome && formik.errors.usu_nome,
              })}
            />
            {formik.touched.usu_nome && formik.errors.usu_nome ? (
              <div style={{ fontSize: 10, color: "red" }}>
                {formik.errors.usu_nome}
              </div>
            ) : null}
            <label htmlFor="usu_nome">Usuário responsável pela alteração</label>
          </span>
        </div>
        : null}

      </form>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
