import api from "./Api";
 
export class LogSistemaService {
  async getAll() {
    return await api
      .get("administrativo/log-sistema-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async getById(id) {
    return await api
      .post(
        "administrativo/log-sistema-id",
        { log_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
