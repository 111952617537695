import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import formataDataEnviada from "../../components/formataDataEnviada";

import { AgendaCorteService } from "../../service/AgendaCorteService";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);  
  const [campoHabilitado, SetCampoHabilitado] = useState(false);

  let { id } = useParams(); 
  let { tipo } = useParams();
  let { id_convenio } = useParams();
  let { id_operadora } = useParams();
  const link_voltar = "/agendaCorteIndex" + "/" + id_convenio+"/"+id_operadora;
  const cabecalho_form = "Agenda de corte";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {   
    agc_con_id: "",    
    agc_data_corte: "",
    
  };

  let retornoDados = {
    status: null,
    mensagem: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const agendaCorteService = new AgendaCorteService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const agendaCorteService = new AgendaCorteService();
      agendaCorteService
        .getAgendaById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false));
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {      
      agc_con_id: modelDado.agc_con_id,           
    },
    validationSchema: yup.object({
      agc_data_corte: yup.string().required("O campo é obrigatório."),      
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              agendaCorteService
                .postAgendaCadastrar(
                  id_convenio,  
                  id_operadora,                
                  formataDataEnviada(values.agc_data_corte),                  
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              agendaCorteService
                .postAgendaAtualizar(
                  values.agc_id,
                  values.agc_ano_mes,
                  values.agc_dia,
                  formataDataEnviada(values.agc_data_inicial),
                  formataDataEnviada(values.agc_data_final),
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {              
                  history.push("/semToken");        
              });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}

        <div className="grid">
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask={"99/99/9999"}
                  disabled={campoHabilitado}
                  type="text"
                  id="agc_data_corte"
                  name="agc_data_corte"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.agc_data_corte}
                  className={classNames({
                    "p-invalid":
                      formik.touched.agc_data_corte &&
                      formik.errors.agc_data_corte,
                  })}
                />
                {formik.touched.agc_data_corte &&
                formik.errors.agc_data_corte ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.agc_data_corte}
                  </div>
                ) : null}
                <label htmlFor="agc_data_corte">Data de Corte</label>
              </span>
            </div>
          </div>          
        </div>
      </form>

       

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
