
import api from "./Api";
 
export class CorreiosService {
    getCep(cep) { 
    return   api
      .post(
        "/v1/getCep",
        { cep: cep },        
      )
      .then((res) =>  res.data);
  }
}
