import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Sidebar } from "primereact/sidebar";

export const AppTopbarPrisma = (props) => {
  const [visibleRight, setVisibleRight] = useState(false);
  const isHomologation = window.location.hostname === 'homapi.usedigi.com.br';
  const isLocal = window.location.hostname === 'localhost';
  const textStyle = {
    color: 'red',
    textAlign: 'center',
    marginLeft: '50px', // Ajuste o valor conforme necessário
    fontWeight: 'bold',
    fontSize: '32px', // Ajuste o valor conforme necessário
  };

  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const heProducao = () => {

    return <div>
      <h1 style={textStyle}>
        {isHomologation ?
          'Ambiente de Homologação' :
          (isLocal ?
            'Ambiente Local' :
            "")}</h1>
    </div>

  }


  const dadosUsuario = () => {
    let teste = sessionStorage
      .getItem("dados")
      .replace('"', "")
      .replace('"', "");


    const dados = teste.trim().split(",");




    return (
      <div className="card p-fluid">
        <div className="col-12 md:col-6">
          <div className="field">{dados}</div>
        </div>

        <div className="col-12 md:col-6">
          <button
            className="p-link layout-topbar-button"
            onClick={() => logout()}
            style={{ marginRight: ".25em" }}
          >
            <span>Sair</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="layout-topbar">
      <a href="https://usedigiconsig.com.br/" >
        <img
          src={
            props.layoutColorMode === "light"
              ? "assets/layout/images/prisma.png"
              : "assets/layout/images/prisma.png"
          }
          alt="logo"
          width={150}
        />
      </a>
      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>


      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <div className="grid">
        <div className="col-12 md:col-12">
          <p>{heProducao()}</p>
        </div>
      </div>


      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <Sidebar
            visible={visibleRight}
            onHide={() => setVisibleRight(false)}
            baseZIndex={1000}
            position="right"
          >
            <h1 style={{ fontWeight: "bolder" }}>Perfil</h1>

            {dadosUsuario()}

          </Sidebar>
          <button
            className="p-link layout-topbar-button"
            onClick={() => setVisibleRight(true)}
            style={{ marginRight: ".25em" }}
          >
            <i className="pi pi-user" />
            <span>Perfil</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
