import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingSpinner from "../../../components/LoadingSpinner";

import { CorrespondenteService } from "../../../service/Servidor/CorrespondenteService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== 'undefined'
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);

  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [idServidor, setIdServidor] = useState(0);
  const [idProcesadora, setIdprocessadora] = useState(0);
  const [nomeServidor, setNomeServidor] = useState('');
  const [servidorStatus, setServidorStatus] = useState('');
  const [nomeOperadora, setNomeOperadora] = useState('');
  const [servidorBloqueado, setServidorBloqueado] = useState(false);
  const [matriculaServidor, setMatriculaServidor] = useState('');
  const handleFocus = () => {
    setIdServidor(0);
    setMatriculaServidor('');
    setNomeServidor('');
    setNomeOperadora('');
    setServidorStatus('');
    setServidorBloqueado(false);
  };

  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Correspondente Bancário - Servidor";

  let _linkSistema_I = "/correspondenteForm_I/";
  let _linkSistema_U = "/correspondenteForm_U/";
  let _linkSistema_R = "/correspondenteResgate_form/";
  let _linkSistema_E = "/correspondenteEmprestimo_form/";
  let _linkSistema_S = "/correspondenteForm_P/"



  const [correspondentes, setCorrespondentes] = useState(null);
  const [selectedCorrespondentes, setSelectedCorrespondentes] = useState(null);
  const correspondenteService = new CorrespondenteService();

  useEffect(() => {
    if (retornoDado.status === false) {

      if (isset(retornoDado.code)) {
        history.push('semToken');
      }
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);



  const formik = useFormik({
    initialValues: {
      cav_cpf: '',
    },
    validationSchema: yup.object({
      cav_cpf: yup.string().required("O campo é obrigatório.").nullable(),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            SetLoadingSpinner(true);
            correspondenteService
              .getByCpf(values.cav_cpf, token)
              .then(
                (data) =>
                  data.status === true ? setCorrespondentes(data.dados) : setRetornoDado(data),
                SetLoadingSpinner(false)
              )
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });

    },
  });

  useEffect(() => {
    if (correspondentes != null) {
      setNomeOperadora(correspondentes['operadora']['ope_nome'])
      setIdServidor(correspondentes['cav_id'])
      setIdprocessadora(correspondentes['cav_ope_id'])
      setNomeServidor(correspondentes['cav_nome'])
      setMatriculaServidor(correspondentes['cav_matricula'])
      setServidorBloqueado(correspondentes['cav_cancelado'] || correspondentes['cav_bloqueado'])
      if (correspondentes['cav_cancelado'] || correspondentes['cav_bloqueado']) {
        setServidorStatus('BLOQUEADO')
      } else {
        setServidorStatus('ATIVO')
      }

    } else {
      setIdServidor(0)
      setIdprocessadora(0)
      setNomeServidor('')
      setNomeOperadora('')
      setServidorBloqueado(false)
      setMatriculaServidor('')
      setServidorStatus('')
    }
  }, [correspondentes]);




  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/I";
    let _link_U = _linkSistema_U + idServidor + "/U";
    let _link_R = _linkSistema_R + idServidor;
    let _link_E = _linkSistema_E + idServidor;
    let _link_S = _linkSistema_S + idServidor;


    let _acesso_I = "corr/servidor/incluir";
    let _acesso_U = "corr/servidor/alterar";
    let _acesso_R = "corr/servidor/averbarcartao";
    let _acesso_E = "corr/servidor/averbaremprestimo";
    let _acesso_S = "corr/servidor/seguros";


    return (
      <React.Fragment>
        {!servidorBloqueado && (
          <div className="grid">
            {idServidor == 0 ?
              <Link to={permissaoAcesso(_acesso_I) && _link_I}>
                <Button
                  label="Cadastrar"
                  icon={permissaoAcesso(_acesso_I) ? "pi pi-lock-open" : "pi pi-lock"}
                  className="p-button-raised mr-2"
                />
              </Link>
              : null}

            {idServidor != 0 ?
              <Link to={permissaoAcesso(_acesso_U) && _link_U}>
                <Button
                  label="Alterar"
                  icon={permissaoAcesso(_acesso_U) ? "pi pi-lock-open" : "pi pi-lock"}
                  className="p-button-raised mr-2"
                />
              </Link>
              : null}

            {/* {idServidor != 0 && idProcesadora != 1 ?
              <Link to={permissaoAcesso(_acesso_R) && _link_R}>
                <Button
                  label="Averbar Cartão Benefício"
                  icon={permissaoAcesso(_acesso_R) ? "pi pi-lock-open" : "pi pi-lock"}
                  className="p-button-raised mr-2"

                />
              </Link>
              : null} */}

            {idServidor != 0 && idProcesadora != 1 ?
              <Link to={permissaoAcesso(_acesso_E) && _link_E}>
                <Button
                  label="Averbar Saque"
                  icon={permissaoAcesso(_acesso_E) ? "pi pi-lock-open" : "pi pi-lock"}
                  className="p-button-raised mr-2"

                />
              </Link>
              : null}

            {/* {idServidor != 0 && idProcesadora == 1 ?
              <Link to={permissaoAcesso(_acesso_S) && _link_S}>
                <Button
                  label="Seguros"
                  icon={permissaoAcesso(_acesso_S) ? "pi pi-lock-open" : "pi pi-lock"}
                  className="p-button-raised"
                />
              </Link>
              : null} */}
          </div>
        )}
      </React.Fragment>
    );
  };

  const leftToolbarCpf = () => {
    return (
      <React.Fragment>
        <div className="grid">
          <div className="col-12 md:col-12">
            <form>
              <div className="field">
                <span className="p-float-label mt-2">
                  <InputMask
                    mask="999.999.999-99"
                    unmask={true}
                    type="text"
                    id="cav_cpf"
                    name="cav_cpf"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={handleFocus}
                    value={formik.values.cav_cpf}
                    className={classNames({
                      "p-invalid":
                        formik.touched.cav_cpf && formik.errors.cav_cpf,
                    })}
                  />
                  {formik.touched.cav_cpf && formik.errors.cav_cpf ? (
                    <div style={{ fontSize: 10, color: "red" }}>
                      {formik.errors.cav_cpf}
                    </div>
                  ) : null}
                  <label htmlFor="cav_cpf">CPF *</label>
                </span>
              </div>

            </form>
          </div>
        </div>

      </React.Fragment>
    );
  };

  const rightToolbarCpf = () => {
    return (
      <React.Fragment>
        <Button
          type="submit"
          label="Pesquisar"
          className="p-button-raised p-button-success "
          onClick={formik.handleSubmit}
        />
      </React.Fragment>
    );
  };







  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };





  const header = (

    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
    </div>

  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
          ></Toolbar>

          <Toolbar
            className="mb-4"
            left={leftToolbarCpf}
            right={rightToolbarCpf}
          ></Toolbar>

          <div className="grid">
            <div className="col-2 md:col-4">

              <span className="p-float-label mt-2">
                <InputText
                  disabled={true}
                  type="text"
                  value={nomeOperadora}
                />
                <label htmlFor="cav_cpf">Processadora</label>
              </span>

            </div>
            <div className="col-12 md:col-4">

              <span className="p-float-label mt-2">
                <InputText
                  disabled={true}
                  type="text"
                  id="cav_nome"
                  name="cav_nome"
                  value={nomeServidor}
                />
                <label htmlFor="cav_cpf">Nome</label>
              </span>

            </div>
            <div className="col-12 md:col-4">

              <span className="p-float-label mt-2">
                <InputText
                  disabled={true}
                  type="text"
                  id="cav_nome"
                  name="cav_nome"
                  value={matriculaServidor}
                />
                <label htmlFor="cav_cpf">Matrícula</label>
              </span>

            </div>
            <div className="col-12 md:col-2">

              <span className="p-float-label mt-2">
                <InputText
                  disabled={true}
                  type="text"
                  id="cav_nome"
                  name="cav_nome"
                  value={servidorStatus}
                />
                <label htmlFor="cav_cpf">Status</label>
              </span>

            </div>
          </div>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div >
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
