import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { Funcoes } from "../../utils/funcoes";
import * as yup from "yup";
import classNames from "classnames";
import { ConvenioService } from "../../service/ConvenioService";
import { OperadoraService } from "../../service/OperadoraService";
import { ComprasFacajusService } from "../../service/ComprasFacajusService";

const _Index = () => {
  const funcoes = new Funcoes();
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    mensagem: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Compras FaçaJus";
  let _linkSistema_U = "/comprasFacajusForm_U/";

  const [perfil, setPerfil] = useState(null);

  const [selectedPerfil, setSelectedPerfil] = useState(null);
  const convenioService = new ConvenioService();
  const [convenio, setConvenio] = useState([
    { con_id: 0, con_nome_fantasia: "Selecione" },
  ]);

  const [modelDado, setModelDado] = useState(null);
  const [ano, setAno] = useState([]);
  const [mes, setMes] = useState([]);
  const [operadora, setOperadora] = useState([]);
  const [opeId, SetOpeID] = useState(null);
  const operadoraService = new OperadoraService();
  const comprasFacajusService = new ComprasFacajusService();

  useEffect(() => {
    buscaConvenios();
  }, [opeId]);

  useEffect(() => {
    formik.values.cav_codigo_convenio = 0;
  }, [convenio]);

  useEffect(() => {
    if (retornoDado.status === false) {
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    setAno(funcoes.geraArrayAno());
    setMes(funcoes.geraArrayApenasMeses());
    operadoraService
      .getAll()
      .then((data) => setOperadora(data.dados))
      .catch((error) => {
        history.push("/semToken");
      });

    SetLoadingSpinner(false);
  }, []);

  function buscaConvenios() {
    SetLoadingSpinner(true);
    convenioService
      .getByOperadora(formik.values.cav_ope_id)
      .then((data) =>
        setConvenio([
          { con_id: 0, con_nome_fantasia: "Selecione" },
          ...data.dados,
        ])
      );
    SetLoadingSpinner(false);
  }

  const formik = useFormik({
    initialValues: {
      cav_codigo_convenio: 0,
      cav_ope_id: 1,
      ano: funcoes.retornaAno(),
      mes: null,
    },
    validationSchema: yup.object({
      cav_ope_id: yup.number().required("O campo é obrigatório.").nullable(),
      cav_codigo_convenio: yup
        .number()
        .required("O campo é obrigatório.")
        .min(1, "Selecione o Convênio")
        .nullable(),
      ano: yup.string().required("O campo é obrigatório.").nullable(),
      mes: yup.string().required("O campo é obrigatório.").nullable(),
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            comprasFacajusService
              .buscarCompras(
                formik.values.ano,
                formik.values.mes + "",
                formik.values.cav_ope_id,
                formik.values.cav_codigo_convenio,
                token
              )
              .then(
                (data) => setModelDado(data.dados),
                SetLoadingSpinner(false)
              )
              .catch((error) => {
                console.log(error);
                // history.push("/semToken");
              });
          });
      });
    },
  });

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const CpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Empresa</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const nomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.per_nome}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const valorBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Valor</span>
        {rowData.prc_valor}
      </>
    );
  };

  const contagemBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Numero parcela</span>
        {rowData.prc_numero}
      </>
    );
  };

  const idContratoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">IdContrato</span>
        {rowData.trr_id_contrato}
      </>
    );
  };

  const numeroContratoTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">IdContrato</span>
        {rowData.trr_numero_contrato}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.prc_id + "/U";
    let _acesso_U = "ger/relatorio/comprasFacajus/alterar";
    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
      </div>
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <form>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="ano"
                      value={formik.values.ano}
                      disabled={false}
                      onChange={formik.handleChange}
                      options={ano}
                      optionLabel="ano"
                      optionValue="id"
                      placeholder={formik.values.ano}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid": formik.touched.ano && formik.errors.ano,
                      })}
                    />
                    {formik.touched.ano && formik.errors.ano ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.ano}
                      </div>
                    ) : null}
                    <label htmlFor="ano">Ano</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="mes"
                      value={formik.values.mes}
                      disabled={false}
                      onChange={formik.handleChange}
                      options={mes}
                      optionLabel="mes"
                      optionValue="id"
                      placeholder={formik.values.mes}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid": formik.touched.mes && formik.errors.mes,
                      })}
                    />
                    {formik.touched.mes && formik.errors.mes ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.mes}
                      </div>
                    ) : null}
                    <label htmlFor="mes">Mês</label>
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-3">
                <div className="field">
                  <span className="p-float-label mt-2">
                    <Dropdown
                      id="cav_ope_id"
                      value={formik.values.cav_ope_id}
                      disabled={false}
                      onChange={formik.handleChange}
                      options={operadora}
                      optionLabel="ope_nome"
                      optionValue="ope_id"
                      onHide={buscaConvenios}
                      placeholder={formik.values.cav_ope_id}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_ope_id && formik.errors.cav_ope_id,
                      })}
                    />
                    {formik.touched.cav_ope_id && formik.errors.cav_ope_id ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_ope_id}
                      </div>
                    ) : null}
                    <label htmlFor="cav_ope_id">Processadora</label>
                  </span>
                </div>
              </div>

              <div className="col-6 md:col-5">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Dropdown
                      id="cav_codigo_convenio"
                      disabled={false}
                      value={formik.values.cav_codigo_convenio}
                      onChange={formik.handleChange}
                      options={convenio}
                      optionLabel="con_nome_fantasia"
                      optionValue="con_id"
                      placeholder={
                        formik.values.cav_codigo_convenio === ""
                          ? "0"
                          : formik.values.cav_codigo_convenio
                      }
                      emptyMessage={"Convênio deve ser selecionado"}
                      className={classNames({
                        "p-invalid":
                          formik.touched.cav_codigo_convenio &&
                          formik.errors.cav_codigo_convenio,
                      })}
                    />

                    {formik.touched.cav_codigo_convenio &&
                    formik.errors.cav_codigo_convenio ? (
                      <div style={{ fontSize: 10, color: "red" }}>
                        {formik.errors.cav_codigo_convenio}
                      </div>
                    ) : null}
                    <label htmlFor="cav_codigo_convenio">Convênio *</label>
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className="field">
                  <span className="p-float-label  mt-2">
                    <Button
                      type="submit"
                      label="Buscar"
                      className="p-button-raised mr-2"
                      onClick={formik.handleSubmit}
                    />
                  </span>
                </div>
              </div>
            </div>
          </form>
          <DataTable
            ref={dt}
            value={modelDado}
            selection={selectedPerfil}
            onSelectionChange={(e) => setSelectedPerfil(e.value)}
            dataKey="prc_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="cav_cpf"
              header="CPF"
              sortable
              body={CpfBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="per_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matricula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="prc_valor"
              header="Valor"
              sortable
              body={valorBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="prc_numero"
              header="Número da parcela"
              sortable
              body={contagemBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="trr_id_contrato"
              header="ID contrato"
              sortable
              body={idContratoBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="trr_numero_contrato"
              header="Numero contrato"
              sortable
              body={numeroContratoTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
