import api from "./Api";
 
export class LogEstatisticoService {
  getAll() {
    return api.get("administrativo/lista-log-estatistico",
    {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  JSON.stringify(res.data));
  }

  buscaLog(mes,ano) {
    return api
      .post(
        "/busca-log-estatistico",
        { mes : mes,
          ano: ano
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
  getAnos() {
    return api
      .get("/anos-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
  getMeses() {
    return api
      .get("/meses-listar-log", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }
}