export default function formataDataEnviada(dataPraF){

  //transforma  25/02/2023 para 2022-09-08 00:00:00
  if (dataPraF != ""){
  const ano = (dataPraF).toString().slice(6,11)
  const mes = (dataPraF).toString().slice(3,5)
  const dia = (dataPraF).toString().slice(0,2)

  const hora = "00:00:00"

  const f = (ano+"-"+mes+"-"+dia+" "+hora)

  return(f)
  
  
  }else{
      return(null) 
    }

}