import api from "./Api";
 
export class FeriadosService {
  async getByConvenio(id) {
    return await api
      .post(
        "administrativo/feriados-listar",
        { fer_con_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async getById(id) {
    return await api
      .post(
        "/feriado-id",
        { fer_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postCadastrar(fer_con_id, fer_data) {
    return await api
      .post(
        "/feriado-cadastrar",
        { fer_con_id: fer_con_id, fer_data: fer_data },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }

  async postAtualizar(fer_id, fer_con_id, fer_data) {
    return await api
      .post(
        "/feriado-atualizar",
        { fer_id: fer_id, fer_con_id: fer_con_id, fer_data: fer_data },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) =>  res.data);
  }
}
