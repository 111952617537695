import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../components/LoadingSpinner";
import { EstabelecimentosService } from "../../service/EstabelecimentosService";

export default function SelectEst({onSelect}) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [convenios, setConvenios] = useState(null);
  const [selectedConvenios, setSelectedConvenios] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Selecione o estabelecimento";
  const estabelecimentosService = new EstabelecimentosService();

  useEffect(() => {
    SetLoadingSpinner(true);
    estabelecimentosService
      .getAll()
      .then((data) => setConvenios(data.dados), SetLoadingSpinner(false));
  }, []);

  const IdBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.est_id}
      </>
    );
  };

  const tipoEstBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Tipo estabelecimento</span>
        {rowData.tco_descricao}
      </>
    );
  };

  const CnpjBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CNPJ</span>
        {rowData.est_cnpj}
      </>
    );
  };

  const razaoSocialBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Razão Social</span>
        {rowData.est_razao_social}
      </>
    );
  };

  const CidadeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cidade</span>
        {rowData.cid_nome}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let estId = {"id":rowData.est_id, "razaoSocial":rowData.est_razao_social};

    return (
      <div className="grid">
        <div className="col-6 md:col-6">
            <Button
              icon="pi pi-check"
              className="p-button-rounded p-button-success mr-6"
              onClick={() => onSelect(estId)}
            />
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <DataTable
            ref={dt}
            value={convenios}
            selection={selectedConvenios}
            onSelectionChange={(e) => setSelectedConvenios(e.value)}
            dataKey="id"
            paginator
            rows={4}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="est_id"
              header="Id"
              sortable
              body={IdBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="tco_descricao"
              header="Tipo estabelecimento"
              sortable
              body={tipoEstBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="est_cnpj"
              header="Cnpj"
              sortable
              body={CnpjBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="est_razao_social"
              header="Razão Social"
              sortable
              body={razaoSocialBodyTemplate}
              headerStyle={{ width: "50%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cid_nome"
              header="Cidade"
              sortable
              body={CidadeBodyTemplate}
              headerStyle={{ width: "40%", minWidth: "10rem" }}
            ></Column>

            <Column header="Selecionar" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};
