import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { TipoEstabelecimentoService } from "../../../service/TipoEstabelecimentoService";
import { EstabelecimentosService } from "../../../service/EstabelecimentosService";
import { StatusService } from "../../../service/StatusService";
import { EstabelecimentoTipoService } from "../../../service/EstabelecimentoTipoService";
import { Dropdown } from "primereact/dropdown";

const _Form = () => {
  const history = useHistory();
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  let { id, tipo } = useParams();
  const link_voltar = "/processadoras";
  const cabecalho_form = "Processadoras";
  
  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let retornoDados = {
    status: null,
    msg: "",
  };

  const [modelDado, setModelDado] = useState({});
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const estabelecimentosService = new EstabelecimentosService();

  const [tipoConvenios, setTipoConvenios] = useState(null);
  const tipoConvenioService = new TipoEstabelecimentoService();

  const [status, setStatus] = useState(null);
  const statusService = new StatusService();

  const [estabelecimentoTipo, setEstabelecimentoTipo] = useState(null);
  const estabelecimentoTipoService = new EstabelecimentoTipoService();

  const [dadosFormulario, setDadosFormulario] = useState({});

  useEffect(() => {
    SetLoadingSpinner(true);
    statusService.getAll().then((data) => setStatus(data.dados), SetLoadingSpinner(false));
    tipoConvenioService.getAll().then((data) => setTipoConvenios(data.dados), SetLoadingSpinner(false));
    estabelecimentoTipoService.getAll().then((data) => setEstabelecimentoTipo(data.dados), SetLoadingSpinner(false));
  }, []);

  useEffect(() => {
    if (tipo !== "I") {
      SetLoadingSpinner(true);
      estabelecimentosService.getById(id).then((data) => {
        setModelDado(data.dados);
        SetLoadingSpinner(false);
      }).catch(() => {
        history.push("/semToken");
      });
    }
  }, [tipo, id]);

  useEffect(() => {
    setDadosFormulario(modelDado);
  }, [modelDado]);

  useEffect(() => {
    formik.setValues(dadosFormulario);
  }, [dadosFormulario]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.msg,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    initialValues: {
      est_cnpj: modelDado.est_cnpj,
      est_razao_social: modelDado.est_razao_social,
      est_nome_fantasia: modelDado.est_nome_fantasia,
      est_sta_id: modelDado.est_sta_id,
      est_tco_id: 14, // Valor fixo ou selecionado
      est_resp_nome: modelDado.est_resp_nome,
      est_resp_email: modelDado.est_resp_email,
      est_resp_celular: modelDado.est_resp_celular,
    },
    validationSchema: yup.object({
      est_cnpj: yup.string().required("CNPJ é obrigatório").length(14, "CNPJ deve ter 14 dígitos"),
      est_razao_social: yup.string().required("Razão social é obrigatória"),
      est_nome_fantasia: yup.string().required("Nome fantasia é obrigatório"),
      est_sta_id: yup.number().required("Status é obrigatório"),
      est_resp_nome: yup.string().required("Nome do responsável é obrigatório"),
      est_resp_email: yup.string().email("E-mail inválido").required("E-mail do responsável é obrigatório"),
      est_resp_celular: yup.string().required("Celular do responsável é obrigatório"),
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        // Cadastro novo
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          }).then((token) => {
            estabelecimentosService
              .postCadastrar(
                values.est_cnpj,
                values.est_razao_social,
                values.est_nome_fantasia,
                values.est_sta_id,
                values.est_tco_id,
                values.est_resp_nome,
                values.est_resp_email,
                values.est_resp_celular,
                token
              )
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                console.log("Error: ", error);
                //history.push("/semToken");
              });
          });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          }).then((token) => {
            estabelecimentosService
              .postAtualizar(
                values.est_id, // Passa apenas o ID para atualização
                values.est_cnpj,
                values.est_razao_social,
                values.est_nome_fantasia,
                values.est_sta_id,
                values.est_tco_id,
                values.est_resp_nome,
                values.est_resp_email,
                values.est_resp_celular,
                token
              )
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                console.log("Error: ", error);
                history.push("/semToken");
              });
          });
        });
      }
    },
  });

  const submitForm = () => {
    console.log("Formulário enviado:", dadosFormulario);

    formik.handleSubmit();
    if (tipo === "I") {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            estabelecimentosService
              .postCadastrar(
                dadosFormulario.est_cnpj,
                dadosFormulario.est_razao_social,
                dadosFormulario.est_nome_fantasia,
                dadosFormulario.est_sta_id,
                14,
                dadosFormulario.est_resp_nome,
                dadosFormulario.est_resp_email,
                dadosFormulario.est_resp_celular,
                token
              )
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });
    } else {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            estabelecimentosService
              .postAtualizar(
                dadosFormulario.est_id,
                dadosFormulario.est_cnpj,
                dadosFormulario.est_razao_social,
                dadosFormulario.est_nome_fantasia,
                dadosFormulario.est_sta_id,
                dadosFormulario.est_tco_id,
                dadosFormulario.est_resp_nome,
                dadosFormulario.est_resp_email,
                dadosFormulario.est_resp_celular,
                token
              )
              .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });
    }
  };

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="button"
            label="Salvar"
            className="p-button-raised p-button-success"
            onClick={submitForm}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <h5>Dados Cadastrais</h5>
        <div className="grid">
          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="99.999.999/9999-99"
                  unmask={true}
                  disabled={campoHabilitado}
                  type="text"
                  id="est_cnpj"
                  name="est_cnpj"
                  onChange={(data) => {
                    const cnpj = data.value.replace(/\D/g, ""); // Remove formatação
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_cnpj: cnpj, // Atualiza sem formatação
                    }));
                  }}
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_cnpj}
                  className={classNames({
                    "p-invalid": formik.touched.est_cnpj && formik.errors.est_cnpj,
                  })}
                />
                {formik.touched.est_cnpj && formik.errors.est_cnpj ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_cnpj}
                  </div>
                ) : null}
                <label htmlFor="est_cnpj">CNPJ</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-8">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_nome_fantasia"
                  name="est_nome_fantasia"
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_nome_fantasia: data.target.value,
                    }))
                  }
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_nome_fantasia}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_nome_fantasia && formik.errors.est_nome_fantasia,
                  })}
                />
                {formik.touched.est_nome_fantasia && formik.errors.est_nome_fantasia ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_nome_fantasia}
                  </div>
                ) : null}
                <label htmlFor="est_nome_fantasia">Nome fantasia</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-10">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_razao_social"
                  name="est_razao_social"
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_razao_social: data.target.value,
                    }))
                  }
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_razao_social}
                  className={classNames({
                    "p-invalid":
                      formik.touched.est_razao_social && formik.errors.est_razao_social,
                  })}
                />
                {formik.touched.est_razao_social && formik.errors.est_razao_social ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_razao_social}
                  </div>
                ) : null}
                <label htmlFor="est_razao_social">Razão social</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-5">
                <Dropdown
                  id="est_sta_id"
                  value={dadosFormulario.est_sta_id}
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_sta_id: data.value,
                    }))
                  }
                  options={status}
                  optionLabel="sta_descricao"
                  optionValue="sta_id"
                  placeholder={dadosFormulario.est_sta_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid": formik.touched.est_sta_id && formik.errors.est_sta_id,
                  })}
                />
                {formik.touched.est_sta_id && formik.errors.est_sta_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_sta_id}
                  </div>
                ) : null}
                <label htmlFor="est_sta_id">Status</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h5>Dados do responsável</h5>
        <div className="grid">
          <div className="col-12 md:col-5">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_resp_nome"
                  name="est_resp_nome"
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_resp_nome: data.target.value,
                    }))
                  }
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_resp_nome}
                  className={classNames({
                    "p-invalid": formik.touched.est_resp_nome && formik.errors.est_resp_nome,
                  })}
                />
                {formik.touched.est_resp_nome && formik.errors.est_resp_nome ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_resp_nome}
                  </div>
                ) : null}
                <label htmlFor="est_resp_nome">Nome</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-4">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="est_resp_email"
                  name="est_resp_email"
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_resp_email: data.target.value,
                    }))
                  }
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_resp_email}
                  className={classNames({
                    "p-invalid": formik.touched.est_resp_email && formik.errors.est_resp_email,
                  })}
                />
                {formik.touched.est_resp_email && formik.errors.est_resp_email ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_resp_email}
                  </div>
                ) : null}
                <label htmlFor="est_resp_email">E-mail</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputMask
                  mask="(99) 99999-9999"
                  unmask={true}
                  disabled={campoHabilitado}
                  type="text"
                  id="est_resp_celular"
                  name="est_resp_celular"
                  onChange={(data) =>
                    setDadosFormulario((prevState) => ({
                      ...prevState,
                      est_resp_celular: data.value,
                    }))
                  }
                  onBlur={formik.handleBlur}
                  value={dadosFormulario.est_resp_celular}
                  className={classNames({
                    "p-invalid": formik.touched.est_resp_celular && formik.errors.est_resp_celular,
                  })}
                />
                {formik.touched.est_resp_celular && formik.errors.est_resp_celular ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.est_resp_celular}
                  </div>
                ) : null}
                <label htmlFor="est_resp_celular">Celular</label>
              </span>
            </div>
          </div>
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
