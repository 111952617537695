import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { ContratoService } from "../../service/ContratoService";

import formataData from "../../components/formataData";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  let retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Contratos";

  let _linkSistema_I = "/contratoForm_I/";
  let _linkSistema_U = "/contratoForm_U/";
  let _linkSistema_V = "/contratoForm_V/";
  const [contratos, setContratos] = useState(null);
  const [selectedContratos, setSelectedContratos] = useState(null);
  const contratoService = new ContratoService();

  useEffect(() => {
    if (retornoDado.status === false) {
      
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    contratoService
      .getAll()
      .then(
        (data) =>
          data.status === true
            ? setContratos(data.dados)
            : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {              
        history.push("/semToken");        
    });
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const leftToolbarTemplate = () => {
    let _link_I = _linkSistema_I + "0/I";
    return (
      <React.Fragment>
        <div className="my-2">
          <Link to={_link_I}>
            <Button
              label="Cadastrar"
              icon="pi pi-plus"
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const CnpjBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">CNPJ</span>
        {rowData.cnt_cnpj}
      </>
    );
  };

  const razaoSocialBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Razão Social</span>
        {rowData.cnt_razao_social}
      </>
    );
  };

  const nomeFantasiaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome Fantasia</span>
        {rowData.cnt_nome_fantasia}
      </>
    );
  };

  const numContratoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Número do Contrato</span>
        {rowData.cnt_numero}
      </>
    );
  };

  const dataContBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Assinatura</span>
        {formataData(rowData.cnt_data_contrato_ass)}
      </>
    );
  };

  const dataVencBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Vencimento</span>
        {formataData(rowData.cnt_data_contrato_venc)}
      </>
    );
  };

  const dataInicioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Vencimento</span>
        {formataData(rowData.cnt_data_contrato_ini)}
      </>
    );
  };

  const dataFimBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Vencimento</span>
        {formataData(rowData.cnt_data_contrato_fim)}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.cnt_id + "/U";
    let _link_V = _linkSistema_V + rowData.cnt_id + "/V";

    return (
      <div className="grid">
        <div className="col-6 md:col-6">
          <Link to={_link_U}>
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-success mr-6"
            />
          </Link>
        </div>
        <div className="col-6 md:col-6">
          <Link to={_link_V}>
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-warning mt-12"
            />
          </Link>
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={contratos}
            selection={selectedContratos}
            onSelectionChange={(e) => setSelectedContratos(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="cnt_cnpj"
              header="Cnpj"
              sortable
              body={CnpjBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cnt_razao_social"
              header="Razão Social"
              sortable
              body={razaoSocialBodyTemplate}
              headerStyle={{ width: "50%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="cnt_nome_fantasia"
              header="Nome Fantasia"
              sortable
              body={nomeFantasiaBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="cnt_numero"
              header="Número do contrato"
              sortable
              body={numContratoBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="cnt_data_contrato_ass"
              header="Data assinatura"
              sortable
              body={dataContBodyTemplate}
              headerStyle={{ width: "40%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cnt_data_contrato_venc"
              header="Data vencimento"
              sortable
              body={dataVencBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cnt_data_contrato_ini"
              header="Data Início"
              sortable
              body={dataInicioBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column
              field="cnt_data_contrato_fim"
              header="Data Fim"
              sortable
              body={dataFimBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>

            <Column header="Ações" body={actionBodyTemplate}></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
