import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import SelectEst from "../../components/SelecionarEstabelecimento/SelectEst";
import { OverlayPanel } from "primereact/overlaypanel";

import { TipoPlanoService } from "../../service/TipoPlanoService";

import Calendar from "react-calendar";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [estSelecionado, SetEstSelecinado] = useState(null);
  const [usuUst, setUsuUst] = useState(null);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/TipoPlano";
  const cabecalho_form = "Planos Disponíveis";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    ser_id: null,
    ser_descricao: "",
    ser_ativo: "",
    ser_est_id: "",
    est_razao_social: " ",
    ser_id_de_para: "",
    ser_qtd_meses: "",
    ser_valor: "",
  };

  let retornoDados = {
    status: null,
    msg: "",
  };

  const selectEst = (ser_id) => {
    SetEstSelecinado(ser_id);
    return true;
  };

  const toggle = (event) => {
    op.current.toggle(event);
  };

  const op = useRef(null);

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const tipoPlanoService = new TipoPlanoService();

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);
      const tipoPlanoService = new TipoPlanoService();
      tipoPlanoService
        .getById(id)
        .then((data) =>
          setModelDado(data.dados)
          , SetLoadingSpinner(false))
        .catch((error) => {
          history.push("/semToken");
        });
    } else if (tipo === "V") {
      SetCampoHabilitado(true);
    }
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
  }, [modelDado]);

  useEffect(() => {
    if (retornoDado.status !== null) {

      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
    }
  }, [retornoDado]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ser_id: modelDado.ser_id,
      est_razao_social: modelDado.est_razao_social,
      ser_descricao: modelDado.ser_descricao,
      ser_id_de_para: modelDado.ser_id_de_para,
      ser_ativo: modelDado.ser_ativo,
      ser_qtd_meses: modelDado.ser_qtd_meses,
      ser_valor: modelDado.ser_valor,
      ser_est_id:
        estSelecionado == null ? modelDado.ser_est_id : estSelecionado.id,
    },
    validationSchema: yup.object({
      ser_est_id: yup.string().required("O campo é obrigatório."),
      ser_descricao: yup.string().required("O campo é obrigatório."),
      ser_valor: yup
        .number()
        .max(1000000, "Valor deve ser menor")
        .typeError("Deve ser um número"),
      ser_qtd_meses: yup
        .number()
        .max(999, "Valor deve ser menor")
        .typeError("Deve ser um número"),
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              tipoPlanoService
                .postCadastrar(
                  values.ser_descricao,
                  values.ser_est_id,
                  values.ser_ativo,
                  values.ser_id_de_para,
                  values.ser_valor,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {
                  history.push("/semToken");
                });
            });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              tipoPlanoService
                .postAtualizar(
                  values.ser_id,
                  values.ser_ativo,
                  values.ser_descricao,
                  values.ser_est_id,
                  values.ser_id_de_para,
                  values.ser_valor,
                  values.ser_qtd_meses,
                  token
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {
                  history.push("/semToken");
                });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-inputgroup">
              <span className="p-float-label  mt-5">
                <Button
                  type="button"
                  label="Selecione"
                  onClick={toggle}
                  className="p-button-sm"
                />
                <OverlayPanel ref={op} appendTo={document.body} showCloseIcon>
                  <SelectEst onSelect={selectEst}></SelectEst>
                </OverlayPanel>
                <InputText
                  disabled={true}
                  type="text"
                  id="ser_est_id"
                  name="ser_est_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    estSelecionado == null
                      ? formik.values.est_razao_social
                      : estSelecionado.razaoSocial
                  }
                  className={classNames({
                    "p-invalid":
                      formik.touched.ser_est_id && formik.errors.ser_est_id,
                  })}
                />

                <label htmlFor="ser_est_id">Estabelecimento do Usuário</label>
              </span>
            </div>
            {formik.touched.ser_est_id && formik.errors.ser_est_id ? (
              <div
                style={{
                  fontSize: 10,
                  color: "red",
                  marginLeft: 100,
                }}
              >
                {formik.errors.ser_est_id}
              </div>
            ) : null}
          </div>

          <div className="xs:col-4 md:col-4 lg:col-6">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="ser_descricao"
                  name="ser_descricao"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ser_descricao}
                  className={classNames({
                    "p-invalid":
                      formik.touched.ser_descricao &&
                      formik.errors.ser_descricao,
                  })}
                />
                {formik.touched.ser_descricao && formik.errors.ser_descricao ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ser_descricao}
                  </div>
                ) : null}
                <label htmlFor="ser_id">Descrição</label>
              </span>
            </div>
          </div>

          <div className=" md:col-12 lg:col-6">
            <div className="field-checkbox">
              <Checkbox
                inputId="ser_ativo"
                name="ser_ativo"
                checked={formik.values.ser_ativo}
                onChange={formik.handleChange}
                disabled={campoHabilitado}
              />
              {formik.touched.ser_descricao && formik.errors.ser_ativo ? (
                <div style={{ fontSize: 10, color: "red" }}>
                  {formik.errors.ser_ativo}
                </div>
              ) : null}
              <label htmlFor="ser_id">Ativo</label>
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="xs:col-4 md:col-4 lg:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="ser_id_de_para"
                  name="ser_id_de_para"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ser_id_de_para}
                  className={classNames({
                    "p-invalid":
                      formik.touched.ser_id_de_para &&
                      formik.errors.ser_id_de_para,
                  })}
                />
                {formik.touched.ser_id_de_para &&
                  formik.errors.ser_id_de_para ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ser_id_de_para}
                  </div>
                ) : null}
                <label htmlFor="ser_id_de_para">De para</label>
              </span>
            </div>
          </div>

          <div className="xs:col-4 md:col-4 lg:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputNumber
                  disabled={campoHabilitado}
                  prefix="R$ "
                  value={formik.values.ser_valor}
                  onValueChange={formik.handleChange}
                  mode="decimal"
                  locale="de-DE"
                  id="ser_valor"
                  name="ser_valor"
                  currencyDisplay="code"
                  maxFractionDigits={2}
                  className={classNames({
                    "p-invalid":
                      formik.touched.ser_valor && formik.errors.ser_valor,
                  })}
                />
                {formik.touched.ser_valor && formik.errors.ser_valor ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ser_valor}
                  </div>
                ) : null}
                <label htmlFor="ser_id">Valor do Serviço R$</label>
              </span>
            </div>
          </div>

          <div className="xs:col-4 md:col-4 lg:col-4">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputNumber
                  disabled={campoHabilitado}
                  value={formik.values.ser_qtd_meses}
                  onValueChange={formik.handleChange}
                  id="ser_qtd_meses"
                  name="ser_qtd_meses"
                  maxFractionDigits={0}
                  className={classNames({
                    "p-invalid":
                      formik.touched.ser_qtd_meses &&
                      formik.errors.ser_qtd_meses,
                  })}
                />
                {formik.touched.ser_qtd_meses && formik.errors.ser_qtd_meses ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ser_qtd_meses}
                  </div>
                ) : null}
                <label htmlFor="ser_id">Quantidade de meses</label>
              </span>
            </div>
          </div>
        </div>
      </form>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
