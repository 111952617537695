const Validate = (values) => {
    const error = false;

    if (!values.userName) {
        error = true;
    }



    return error;
}
export default Validate;