import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbarFacaJus } from "./AppTopbarFacaJus";
import { AppFooterFacaJus } from "./AppFooterFacaJus";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import EmptyPage from "./pages/EmptyPage";
import TimelineDemo from "./pages/TimelineDemo";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

 
import SemAcesso from "./pages/SemAcesso/_Index";
import SemToken from "./pages/SemToken/_Index";
 
import Perfil from "./pages/Perfil/_Index";
import Perfil_Form from "./pages/Perfil/_Form";
 
 
import Usuarios from "./pages/Usuarios/_Index";
import Usuarios_Form from "./pages/Usuarios/_Form";
 
import Simular_Form from "./pages/Simular/_Form";



const AppMainPrisma = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE}`,
      function () { }
    );
  }, []);

  const permissaoAcesso = (num) => {
    const teste = sessionStorage
      .getItem("acessos")
      .replace('"', "")
      .replace('"', "");

    if (!teste) {
      return null;
    } else {
      const acessos = teste.trim().split(",");
      let achou = acessos.find((item) => item === num);
      return achou === undefined ? true : null;
    }
  };

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [   
    {
      items: [
        {
          label: "Administrativo",
          icon: "pi pi-fw pi-home",
          to: "/",
          items: [           
            {
              label: "Perfil",
              icon: "pi pi-lock-open",
              to: "/perfil",
              disabled: permissaoAcesso("adm/perfil/acessar"),
            },            
            {
              label: "Usuários",
              icon: "pi pi-lock-open",
              disabled: permissaoAcesso("adm/usuarios/acessar"),
              to: "/usuarios",
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: "Gerencial",
          icon: "pi pi-fw pi-home",
          to: "/aba-gerencial",
          items: [
            {
              label: "Relatórios",
              icon: "pi pi-fw pi-home",
              to: "/subaba-relatorios",
              items: [
                {
                  label: "Assinaturas",
                  icon: "pi pi-lock-open",
                  to: "/relAssinaturas",
                  disabled: permissaoAcesso("ger/relatorio/assinaturas/acessar"),
                },
                {
                  label: "Façajus",
                  icon: "pi pi-lock-open",
                  to: "/comprasfacajus",
                  disabled: permissaoAcesso(
                    "ger/relatorio/comprasFacajus/acessar"
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbarFacaJus
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />
      <Router>
        <div className="layout-sidebar" onClick={onSidebarClick}>
          <AppMenu
            model={menu}
            onMenuItemClick={onMenuItemClick}
            layoutColorMode={layoutColorMode}
          />
        </div>

        <div className="layout-main-container">
          <div className="layout-main">
            <Switch>
        

              


              <Route
                path="/perfil"
                component={
                  !permissaoAcesso("adm/perfil/acessar") ? Perfil : SemAcesso
                }
              />
              <Route
                path="/perfilForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/incluir")
                    ? Perfil_Form
                    : SemAcesso
                }
              />
              <Route
                path="/perfilForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/alterar")
                    ? Perfil_Form
                    : SemAcesso
                }
              />
              <Route
                path="/perfilForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/visualizar")
                    ? Perfil_Form
                    : SemAcesso
                }
              />
 




              <Route
                path="/usuarios"
                component={
                  !permissaoAcesso("adm/usuarios/acessar")
                    ? Usuarios
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/incluir")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/alterar")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/visualizar")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              

              <Route path="/semToken" exact component={SemToken} />
            </Switch>
          </div>

          <AppFooterFacaJus layoutColorMode={layoutColorMode} />
        </div>
      </Router>
      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default AppMainPrisma;
