import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Exportar from "../../components/Exportar";
import { RepasseService } from "../../service/RepasseService";
import { Funcoes } from '../../utils/funcoes.js'
import { ConvenioService } from "../../service/ConvenioService";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import classNames from "classnames";
import * as yup from "yup";
import { ResumoRepasse } from "../../components/Repasse/ResumoRepasse.js";

const _Index = () => {
  const funcoes = new Funcoes();
  const history = useHistory();
  let retornoDados = {
    status: null,
    mensagem: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [clearFile, setClearFile] = useState(false)
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Repasses - NEO CONSIG";
  const [ano, setAno] = useState([]);
  const [mes, setMes] = useState([]);
  const [repasses, setRepasses] = useState(null);
  const [selectedRepasses, setSelectedRepasses] = useState(null);
  const repasseService = new RepasseService();
  const convenioService = new ConvenioService();
  const [convenio, setConvenio] = useState();
  const resumoModelo = {
    compras: {
      enviados: [0, 0],
      descontados: [0, 0],
      inadimplentes: [0, 0]
    },
    saque: {
      enviados: [0, 0],
      descontados: [0, 0],
      inadimplentes: [0, 0]
    }
  }
  const [dadosResumidos, setDadosresumidos] = useState(resumoModelo);

  const formik = useFormik({
    initialValues: {
      cav_codigo_convenio: "",
      ano: funcoes.retornaAno(),
      mes: 0,
      arquivo: null
    },
    validationSchema: yup.object({
      cav_codigo_convenio: yup.string().required("O campo é obrigatório.").nullable()
    }),
    onSubmit: (values) => {
      setLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            setLoadingSpinner(true);
            repasseService
              .getByRepasse(1, formik.values.cav_codigo_convenio, formik.values.ano, formik.values.mes)
              .then(
                (data) =>
                  data.status === true ? setRepasses(data.dados) : setRetornoDado(data),)
              .catch((error) => {
                history.push("/semToken");
              });
            setLoadingSpinner(false);
          }).catch((error) => {
            history.push("/semToken");
          });
      });
    },
  });

  const formik_upload = useFormik({
    initialValues: {
      arquivo: null,
    },
    validationSchema: yup.object({
      arquivo: yup.mixed()
        .required('O campo é obrigatório')
        .test('fileType', 'Tipo de arquivo inválido', (value) => {
          return value && [
            'text/plain', 
            'application/vnd.ms-excel', 
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ].includes(value.type);
        }),
    }),
    onSubmit: (values) => {
      setLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: 'submit',
          })
          .then((token) => {
            repasseService.postFile(values.arquivo, values.arquivo.name.split('.').pop(), token)
              .then((data) => {
                setRetornoDado(data);
                setClearFile(true);
              })
              .catch((error) => {
                console.error('Erro ao enviar o arquivo:', error);
              })
              .finally(() => {
                values.arquivo = null
                setLoadingSpinner(false);
              });
          })
          .catch((error) => {
            console.error('Erro ao executar reCAPTCHA:', error);
            history.push("/semToken");
            setLoadingSpinner(false);
          });
      });
    },
  });

  useEffect(() => {
    setRepasses([])
    toast.current.show({
      severity: retornoDado.status ? 'success' : "error",
      summary: retornoDado.mensagem,
      life: 3000,
    });

  }, [retornoDado]);

  useEffect(() => {
    setAno(funcoes.geraArrayAno())
    setMes(funcoes.geraArrayMes())

  }, []);

  useEffect(() => {
    setLoadingSpinner(true);
    convenioService
      .getByOperadora(1)
      .then((data) =>
        setConvenio(data.dados),
      );
    setLoadingSpinner(false)

  }, [retornoDado]);

  useEffect(() => {
    let resumo = resumoModelo;

    if(repasses){
      repasses.forEach(repasse => {
        
        if(repasse.rep_num_parcelas == 0){
          
          resumo.compras.enviados[0] += parseFloat(repasse.rep_vlr_parcela_enviada);
          resumo.compras.enviados[1]++;
  
          if(repasse.rep_status_holerite === 'Quitado'){
            resumo.compras.descontados[0] += parseFloat(repasse.rep_vlr_desconto_holerite);
            resumo.compras.descontados[1]++;
          } else{
            resumo.compras.inadimplentes[0] += parseFloat(repasse.rep_vlr_parcela_enviada);
            resumo.compras.inadimplentes[1]++;
          }
  
        } else if(repasse.rep_num_parcelas != 0){
  
          resumo.saque.enviados[0] += parseFloat(repasse.rep_vlr_parcela_enviada);
          resumo.saque.enviados[1]++;
  
          if(repasse.rep_status_holerite === 'Quitado'){
            resumo.saque.descontados[0] += parseFloat(repasse.rep_vlr_desconto_holerite);
            resumo.saque.descontados[1]++;
          } else{
            resumo.saque.inadimplentes[0] += parseFloat(repasse.rep_vlr_parcela_enviada);
            resumo.saque.inadimplentes[1]++;
          }
  
        }
  
      });
    }

    setDadosresumidos(resumo);

  }, [repasses])

  const rightToolbarTemplate = (inadimplente) => {
    const cols = [
      { field: "est_nome_fantasia", header: "Empresa" },
      { field: "rep_produto", header: "Produto" },
      { field: "rep_data_referencia", header: "Convênio / Ano / Mês Referência" },
      { field: "cav_cpf", header: "Cpf" },
      { field: "cav_nome", header: "Servidor" },
      { field: "rzp_matricula", header: "Matricula" },
      { field: "cav_telefone", header: "Telefone" },
      { field: "cav_celular", header: "Celular" },
      { field: "cav_email", header: "Email" },
      { field: "rep_data_cadastro", header: "Data Cadastro" },
      { field: "rep_data_vencimento", header: "Data Vencimento" },
      { field: "rep_rubrica", header: "Rúbrica" },
      { field: "rep_operacao_id", header: "Operação ID" },
      { field: "rep_secretaria", header: "Secretaria" },
      { field: "rep_num_parcelas", header: "Nº da Parcelaa" },
      { field: "rep_total_parcelas", header: "Nº de Parcelas" },
      { field: "rep_vlr_parcela_enviada", header: "Parcela Enviada (R$)" },
      { field: "rep_vlr_desconto_holerite", header: "Desconto Holerite (R$)" },
      { field: "rep_vlr_parcela_averbada", header: "Parcela Averbada (R$)" },
      { field: "rep_status_parcela", header: "Status Parcela" },
      { field: "rep_status_holerite", header: "Status Holerite" },

    ];

    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={repasses} inadimplente={inadimplente} />
      </React.Fragment>
    );
  };

  const EstabelecimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">estabelecimento</span>
        {rowData.est_nome_fantasia}
      </>
    );
  };

  const DtCadastroBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Cadastro</span>
        {funcoes.formataDataParaDDMMYYYY(rowData.rep_data_cadastro)}
      </>
    );
  };
  
  const DtVencimentoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Vencimento</span>
        {funcoes.formataDataParaYYYY(rowData.rep_data_vencimento) + "/" + funcoes.formataDataParaMM(rowData.rep_data_vencimento)}
      </>
    );
  };
  const DtContratoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Data Referência</span>
        {funcoes.formataDataParaYYYY(rowData.rep_data_referencia) + "/" + funcoes.formataDataParaMM(rowData.rep_data_referencia)}
      </>
    );
  };

  const RubricaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Rúbrica</span>
        {rowData.rep_rubrica}
      </>
    );
  };

  const OperacaoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Operação ID</span>
        {rowData.rep_operacao_id}
      </>
    );
  };

  const CpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const NomeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Servidor</span>
        {rowData.cav_nome}
      </>
    );
  };

  const SecretariaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Secretaria</span>
        {rowData.rep_secretaria}
      </>
    );
  };

  const NumeroParcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Número Parcelas</span>
        {rowData.rep_num_parcelas}
      </>
    );
  };

  const TotalParcelasBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Total Parcelas</span>
        {rowData.rep_total_parcelas}
      </>
    );
  };

  const ParcelaEnviadaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Parcela Enviada (R$)</span>
        {rowData.rep_vlr_parcela_enviada}
      </>
    );
  };

  const DescontoHoleriteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Desconto Holerite (R$)</span>
        {rowData.rep_vlr_desconto_holerite}
      </>
    );
  };

  const ParcelaAverbadaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Parcela Averbada (R$)</span>
        {rowData.rep_vlr_parcela_averbada}
      </>
    );
  };

  const StatusParcelaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status Parcela</span>
        {rowData.rep_status_parcela}
      </>
    );
  };

  const StatusHoleriteBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status Holerite</span>
        {rowData.rep_status_holerite}
      </>
    );
  };

  const ProdutoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Produto</span>
        {rowData.rep_produto}
      </>
    );
  };

  const handleSearchInput = (content) => {
    const string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-4">{_header}</h5>
      <span className="block mt-6 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="card p-fluid"  >
      <form encType="multipart/form-data" >
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="ano"
                  value={formik.values.ano}
                  disabled={false}
                  onChange={formik.handleChange}
                  options={ano}
                  optionLabel="ano"
                  optionValue="id"
                  placeholder={formik.values.ano}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.ano &&
                      formik.errors.ano,
                  })}
                />
                {formik.touched.ano &&
                  formik.errors.ano ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.ano}
                  </div>
                ) : null}
                <label htmlFor="ano">Ano</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="mes"
                  value={formik.values.mes}
                  disabled={false}
                  onChange={formik.handleChange}
                  options={mes}
                  optionLabel="mes"
                  optionValue="id"
                  placeholder={formik.values.mes}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.mes &&
                      formik.errors.mes,
                  })}
                />
                {formik.touched.mes &&
                  formik.errors.mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.mes}
                  </div>
                ) : null}
                <label htmlFor="mes">Mês de Referência</label>
              </span>
            </div>
          </div>



          <div className="col-6 md:col-6">
            <div className="field">
              <span className="p-float-label  mt-2">
                <Dropdown
                  id="cav_codigo_convenio"
                  disabled={false}
                  value={formik.values.cav_codigo_convenio}
                  onChange={formik.handleChange}
                  options={convenio}
                  optionLabel="con_nome_fantasia"
                  optionValue="con_id"
                  placeholder={formik.values.cav_codigo_convenio}
                  emptyMessage={"Convênio deve ser selecionado"}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cav_codigo_convenio && formik.errors.cav_codigo_convenio,
                  })}
                />

                {formik.touched.cav_codigo_convenio && formik.errors.cav_codigo_convenio ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cav_codigo_convenio}
                  </div>
                ) : null}
                <label htmlFor="cav_codigo_convenio">Convênio *</label>
              </span>
            </div>
          </div>
          <div className="col-2 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-2">
                <Button
                  type="submit"
                  label="Buscar"
                  className="p-button-raised mr-2"
                  onClick={formik.handleSubmit}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-content-between my-5">
          <div className="flex flex-row gap-5 pt-auto">
            <div className="field">
              <label htmlFor="arquivo">Arquivo de repasse ( .txt | .xlsx ) *</label>
              <div className="field">
                <span className="p-float-label mt-2">
                <input
                  id="arquivo"
                  name="arquivo"
                  type="file"
                  value={clearFile ? '' : undefined}
                  onChange={(event) => {
                    formik_upload.setFieldValue('arquivo', event.target.files[0]);
                    setClearFile(false);
                  }}
                  />
                  {formik_upload.touched.arquivo && formik_upload.errors.arquivo ? (
                    <div style={{ color: 'red' }}>{formik_upload.errors.arquivo}</div>
                  ) : null}
                </span>
              </div>
            </div>
            <div className="field">
              <span className="p-float-label  mt-2">
                <Button
                  type="submit"
                  label="Processar arquivo"
                  className="p-button-raised mr-2"
                  onClick={formik_upload.handleSubmit}
                  disabled={loadingSpinner ? true : false}
                />
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
            <div className="pt-2">
              {
                rightToolbarTemplate(true)
              }
            </div>
            <div className="pt-2">
              {
                rightToolbarTemplate()
              }
            </div>
          </div>
        </div>
      </form>

      <div className="mb-5">
        <ResumoRepasse
          dados={dadosResumidos}
        />
      </div>

      <div className="grid">
        <div className="col-12">
          <div className="card">
            <Toast ref={toast} />
            
            <DataTable
              ref={dt}
              value={repasses}
              selection={selectedRepasses}
              onSelectionChange={(e) => setSelectedRepasses(e.value)}
              dataKey="id"
              paginator
              rows={50}
              rowsPerPageOptions={[50, 500, 5000, 50000]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
              globalFilter={globalFilter}
              emptyMessage="Não há dados."
              header={header}
              responsiveLayout="scroll"
            >
              <Column
                field="rep_data_referencia"
                header="Ano/Mês Referência"
                sortable
                body={DtContratoBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="est_nome_fantasia"
                header="Empresa"
                sortable
                body={EstabelecimentoBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_produto"
                header="Produto"
                sortable
                body={ProdutoBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="rep_data_vencimento"
                header="Ano/Mês Pagamento"
                sortable
                body={DtVencimentoBodyTemplate}
                headerStyle={{ width: "27%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_data_cadastro"
                header="Data Cadastro"
                sortable
                body={DtCadastroBodyTemplate}
                headerStyle={{ width: "27%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="cav_cpf"
                header="Cpf"
                sortable
                body={CpfBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="cav_nome"
                header="Servidor"
                sortable
                body={NomeBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "15rem" }}
              ></Column>



              <Column
                field="rep_rubrica"
                header="Rúbrica"
                sortable
                body={RubricaBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_operacao_id"
                header="Operação ID"
                sortable
                body={OperacaoBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>


              <Column
                field="rep_secretaria"
                header="Secretaria"
                sortable
                body={SecretariaBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_num_parcelas"
                header="Nº da Parcela"
                sortable
                body={NumeroParcelasBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_total_parcelas"
                header="Nº de Parcelas"
                sortable
                body={TotalParcelasBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_vlr_parcela_enviada"
                header="Parcela Enviada (R$)"
                sortable
                body={ParcelaEnviadaBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_vlr_desconto_holerite"
                header="Desconto Holerite (R$)"
                sortable
                body={DescontoHoleriteBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_vlr_parcela_averbada"
                header="Parcela Averbada (R$)"
                sortable
                body={ParcelaAverbadaBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_vlr_parcela_averbada"
                header="Status Parcela"
                sortable
                body={StatusParcelaBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

              <Column
                field="rep_status_holerite"
                header="Status Holerite"
                sortable
                body={StatusHoleriteBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>

            </DataTable>
            <LoadingSpinner visualiza={loadingSpinner} />
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
