import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Exportar from "../../../components/Exportar";
import { CartaoVirtualService } from "../../../service/Servidor/CartaoVirtualService";

const _Index = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== 'undefined'
  const retornoDados = {
    status: null,
    msg: "",
  };
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const _header = "Administrativo - Servidor";

  const _linkSistema_U = "/servidorForm_U/";
  const _linkSistema_V = "/servidorForm_V/";

  const [servidor, setServidor] = useState(null);
  const [selectedServidor, setSelectedServidor] = useState(null);
  const cartaoVirtualService = new CartaoVirtualService();

  useEffect(() => {
    if (retornoDado.status === false) {

      if (isset(retornoDado.code)) {
        history.push('semToken');
      }
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(() => {
    SetLoadingSpinner(true);
    cartaoVirtualService
      .getAll()
      .then(
        (data) => data.status === true ? setServidor(data.dados) : setRetornoDado(data),
        SetLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
      });
  }, []);

  const rightToolbarTemplate = () => {
    const cols = [
      { field: "cav_matricula", header: "Matrícula" },
      { field: "cav_nome", header: "Nome" },
      { field: "cav_cancelado", header: "Cancelado" },
      { field: "cav_bloqueado", header: "Bloqueado" },
    ];
    return (
      <React.Fragment>
        <Exportar titulo={_header} dt={dt} cols={cols} dadosService={servidor} />
      </React.Fragment>
    );
  };

  const operadoraIdBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Processadora</span>
        {rowData.operadora.ope_id}
      </>
    );
  };

  const operadoraBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Operadora</span>
        {rowData.operadora.ope_nome}
      </>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.cav_id}
      </>
    );
  };

  const cpfBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cpf</span>
        {rowData.cav_cpf}
      </>
    );
  };

  const matriculaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matricula</span>
        {rowData.cav_matricula}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nome</span>
        {rowData.cav_nome}
      </>
    );
  };

  const canceladoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cancelado</span>
        {rowData.cav_cancelado ? "SIM" : "NÃO"}
      </>
    );
  };

  const bloqueadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Bloqueado</span>
        {rowData.cav_bloqueado ? "SIM" : "NÃO"}
      </>
    );
  };

  const bloqueadoVolusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Bloqueado Cartão</span>
        {rowData.cav_compras_cartao_bloqueio ? "SIM" : "NÃO"}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  const actionBodyTemplate = (rowData) => {
    let _link_U = _linkSistema_U + rowData.cav_id + "/U";
    let _link_V = _linkSistema_V + rowData.cav_id + "/V";

    let _acesso_U = "adm/servidor/alterar";
    let _acesso_V = "adm/servidor/visualizar";

    return (
      <div className="grid">
        <div className="col-3 md:col-3">
          <Link to={permissaoAcesso(_acesso_U) && _link_U}>
            <Button
              icon={permissaoAcesso(_acesso_U) ? "pi pi-pencil" : "pi pi-lock"}
              className="p-button-rounded p-button-success "
              tooltip={"Editar"}
            />
          </Link>
        </div>
        <div className="col-3 md:col-3">
          <Link to={permissaoAcesso(_acesso_V) && _link_V}>
            <Button
              icon={permissaoAcesso(_acesso_V) ? "pi pi-eye" : "pi pi-lock"}
              className="p-button-rounded p-button-warning "
              tooltip={"Visualizar"}
            />
          </Link>
        </div>
      </div >
    );
  };

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-4"
            left={<></>}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={servidor}
            selection={selectedServidor}
            onSelectionChange={(e) => setSelectedServidor(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
            loadingIcon="pi pi-spin pi-spinner"
          >
            <Column
              field="operadora.ope_id"
              header="operadora.ope_id"
              sortable
              hidden={true}
              body={operadoraIdBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="operadora.ope_nome"
              header="Processadora"
              sortable
              body={operadoraBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_id"
              header="Id"
              hidden={true}
              sortable
              body={codeBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="Cpf"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nameBodyTemplate}
              headerStyle={{ width: "50%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_cancelado"
              header="Cancelado"
              sortable
              body={canceladoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="cav_bloqueado"
              header="Bloqueado"
              sortable
              body={bloqueadoBodyTemplate}
              headerStyle={{ width: "5%", minWidth: "5rem" }}
            ></Column>
            <Column
              field="cav_compras_cartao_bloqueio"
              header="Cartão Benefício Bloqueado"
              sortable
              body={bloqueadoVolusBodyTemplate}
              headerStyle={{ width: "10%", minWidth: "5rem" }}
            ></Column>
            <Column header="Ações"
              body={actionBodyTemplate}
              headerStyle={{ minWidth: "15rem" }}

            ></Column>

          </DataTable>

          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
