import api from "./Api";
 
export class ContratoService {
  async getAll() { 
    return await api.get("/contratos-listar", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async getById(id) {
    return await api.post("/contratos-id", { cnt_id: id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) =>  res.data);
  }

  async postAtualizar(id, cnt_numero, cnt_taxa, cnt_tx_antecipa_1, cnt_tx_antecipa_2, cnt_tx_antecipa_3, cnt_data_contrato_ass, cnt_data_contrato_venc, cnt_data_contrato_ini, cnt_data_contrato_fim, cnt_valor_reajuste, cnt_usu_id, cnt_antecipa_mes, cnt_antecipa_vlr_minimo, cnt_antecipa_deposito_dias, cnt_data_adesao_ini, cnt_data_adesao_fim, cnt_adesao_valor, cnt_razao_social, cnt_nome_fantasia, tokenGoogleo) {
    return await api
      .post("/contratos-atualizar", {
        cnt_id: id,
        cnt_numero: cnt_numero,
        cnt_taxa: cnt_taxa,
        cnt_tx_antecipa_1: cnt_tx_antecipa_1,
        cnt_tx_antecipa_2: cnt_tx_antecipa_2,
        cnt_tx_antecipa_3: cnt_tx_antecipa_3,
        cnt_data_contrato_ass: cnt_data_contrato_ass,
        cnt_data_contrato_venc: cnt_data_contrato_venc,
        cnt_data_contrato_ini: cnt_data_contrato_ini,
        cnt_data_contrato_fim: cnt_data_contrato_fim,
        cnt_valor_reajuste: cnt_valor_reajuste,
        cnt_usu_id: cnt_usu_id,
        cnt_antecipa_mes: cnt_antecipa_mes,
        cnt_antecipa_vlr_minimo: cnt_antecipa_vlr_minimo,
        cnt_antecipa_deposito_dias: cnt_antecipa_deposito_dias,
        cnt_data_adesao_ini: cnt_data_adesao_ini,
        cnt_data_adesao_fim: cnt_data_adesao_fim,
        cnt_adesao_valor: cnt_adesao_valor,
        cnt_razao_social: cnt_razao_social,
        cnt_nome_fantasia: cnt_nome_fantasia,
        tokenGoogleo: tokenGoogleo,
      }, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async postCadastrar(cnt_numero, cnt_taxa, cnt_tx_antecipa_1, cnt_tx_antecipa_2, cnt_tx_antecipa_3, cnt_data_contrato_ass, cnt_data_contrato_venc, cnt_data_contrato_ini, cnt_data_contrato_fim, cnt_valor_reajuste, cnt_usu_id, cnt_antecipa_mes, cnt_antecipa_vlr_minimo, cnt_antecipa_deposito_dias, cnt_data_adesao_ini, cnt_data_adesao_fim, cnt_adesao_valor, cnt_cnpj, cnt_razao_social, cnt_nome_fantasia, tokenGoogleo) {
    return await api.post("/contratos-cadastrar", {
      cnt_numero: cnt_numero,
      cnt_taxa: cnt_taxa,
      cnt_tx_antecipa_1: cnt_tx_antecipa_1,
      cnt_tx_antecipa_2: cnt_tx_antecipa_2,
      cnt_tx_antecipa_3: cnt_tx_antecipa_3,
      cnt_data_contrato_ass: cnt_data_contrato_ass,
      cnt_data_contrato_venc: cnt_data_contrato_venc,
      cnt_data_contrato_ini: cnt_data_contrato_ini,
      cnt_data_contrato_fim: cnt_data_contrato_fim,
      cnt_valor_reajuste: cnt_valor_reajuste,
      cnt_usu_id: cnt_usu_id,
      cnt_antecipa_mes: cnt_antecipa_mes,
      cnt_antecipa_vlr_minimo: cnt_antecipa_vlr_minimo,
      cnt_antecipa_deposito_dias: cnt_antecipa_deposito_dias,
      cnt_data_adesao_ini: cnt_data_adesao_ini,
      cnt_data_adesao_fim: cnt_data_adesao_fim,
      cnt_adesao_valor: cnt_adesao_valor,
      cnt_cnpj: cnt_cnpj,
      cnt_razao_social: cnt_razao_social,
      cnt_nome_fantasia: cnt_nome_fantasia,
      tokenGoogleo: tokenGoogleo,
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }

  async getDocsById(id) {
    return await api.post("/documentos-listar", { cnt_id: id }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async getContratoslogById(id) {
    return await api.post("/contratoslog-listar", { cnt_id: id }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async getLogById(id) {
    return await api.post("/contratoslog-id", { ctl_id: id }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async postPdf(pdf, id) {
    let formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("cta_cnt_id", id);

    return await api.post("/contratos-upload", formData, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },

    }).then((res) =>  res.data);
  }

  async baixarPdf(id) {
    return await api.post("/contratos-download", {
      cta_id: id
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }
}
