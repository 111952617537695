export default function formataCpf (cpf){
    const parte1 = (cpf).toString().slice(0,3)
    const parte2 = (cpf).toString().slice(3,6)
    const parte3 = (cpf).toString().slice(6,9)
    const parte4 = (cpf).toString().slice(9,11)


    const f = (parte1+'.'+parte2+'.'+parte3+"-"+parte4)

    return(f)
  }
